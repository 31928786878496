import { MediaCategory } from "./Prompt";
export interface PublicContentPageInfo {
  slug: string;
  title: string;
  url: string;
  previewMedia: ContentPagePreview[];
  media: ContentPageMedia[];
  owner?: PublicContentPageOwner;
  disclaimer?: string;
  cta?: PublicContentPageCTA;
}

export interface PublicContentPageCTA {
  label: string;
  link: string;
}

export interface ContentPagePreview {
  previewType: ContentPagePreviewType;
  uri: string;
  type: string;
  width: number;
  height: number;
}

export enum ContentPagePreviewType {
  STATIC = "STATIC",
  GIF = "GIF",
}

export interface ContentPageMedia {
  uri: string;
  category: MediaCategory;
  sortOrder: number;
  type: string;
  extension: string;
  width?: number;
  height?: number;
  duration?: number;
  sizeMB?: number;
}

export interface PublicContentPageOwner {
  name: string;
  profileImageUrl?: string;
}
