import * as React from "react";

import { useEffect, useMemo, useState } from "react";
import { VideoProjectServices } from "../../../../../services";
import { Media, VideoCatalystStatus } from "../../../../../interfaces";
import { ChoicesModal } from "../../../../../components/ChoicesModal";
import { useHistory } from "react-router-dom";
import { URLS } from "../../../../../utils";
import { RadioCardOption } from "../../../../../components/RadioCardGroup";
import { ProjectFileUploader } from "../../../../../utils/projectFileUploader";
import { ConfigContext } from "../../../../../contexts/appContexts";
import { Progress } from "semantic-ui-react";
import "./styles.scss";

interface OwnProps {
  playerId: number | undefined;
  open: boolean;
  defaultOption: string;

  hasSCPlus: boolean;
  hasMVP: boolean;
  hasScriptLibrary: boolean;
  onClose?: () => void;
  onMediaSelected: (media: Media, destination: "post" | "videoPage" | "videoProject") => void;
}
type Props = OwnProps;

const NewOptionsModalFC: React.FC<Props> = ({
  playerId,
  open,
  defaultOption,
  hasSCPlus,
  hasMVP,
  hasScriptLibrary,
  onClose,
  onMediaSelected,
}) => {
  const history = useHistory();
  const [highlightedOption, setHighlightedOption] = useState<string | undefined>(defaultOption);
  const [vcStatus, setVCStatus] = useState<VideoCatalystStatus | undefined>(undefined);
  const [chosenOption, setChosenOption] = useState<string>();
  const hiddenFileInput = React.useRef<HTMLInputElement | null>(null);
  const [uploading, setUploading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const { firebase } = React.useContext(ConfigContext);

  useEffect(() => {
    if (playerId) VideoProjectServices.getVideoCatalystStatus(playerId).then(setVCStatus);
  }, [playerId]);

  useEffect(() => {
    setHighlightedOption(defaultOption);
  }, [defaultOption]);

  const handleDone = () => {
    setChosenOption(undefined);
    if (onClose) onClose();
  };

  const canCreateNewProject = useMemo((): boolean => {
    return !!(
      vcStatus?.enabled &&
      (vcStatus.unlimitedCredits || (vcStatus.creditsRemaining && vcStatus.creditsRemaining > 0))
    );
  }, [vcStatus?.enabled, vcStatus?.unlimitedCredits, vcStatus?.creditsRemaining]);

  const primaryOptions: RadioCardOption[] = useMemo(() => {
    const list: RadioCardOption[] = [
      {
        key: "post",
        title: "New Post",
        subTitle: "Post now or schedule for later",
        description: "Share an image or video to your connected social accounts.",
      },
    ];

    if (hasSCPlus) {
      list.push({
        key: "videoProject",
        title: "New Video Project",
        subTitle: vcStatus?.unlimitedCredits
          ? "Unlimited Video Credits"
          : `${vcStatus?.creditsRemaining || 0} Credits Remaining`,
        description: "Submit a video for professional editing with captions, music, and branding.",
        disabled: !canCreateNewProject,
      });
    }

    if (hasScriptLibrary) {
      list.push({
        key: "script",
        title: "New Script",
        subTitle: "Record with a custom script",
        description: "Create a personalized script to use with the teleprompter.",
      });
    }

    if (hasMVP) {
      list.push({
        key: "videoPage",
        title: "New Video Page",
        subTitle: "Easily share videos outside of social",
        description: "Create a video link with a call-to-action button to share via text, email, or CRM.",
      });
    }

    return list;
  }, [
    hasSCPlus,
    hasMVP,
    vcStatus?.unlimitedCredits,
    vcStatus?.creditsRemaining,
    canCreateNewProject,
    hasScriptLibrary,
  ]);

  const uploadOption: RadioCardOption = {
    key: "upload",
    title: "Upload a file",
    subTitle: "Choose an image or video from your computer",
  };

  const uploadVideoOption: RadioCardOption = {
    key: "upload",
    title: "Upload a video",
    subTitle: "Choose a video from your computer",
  };

  const promptOption: RadioCardOption = {
    key: "prompt",
    title: "Choose a prompt",
    subTitle: "Find something from a library of pre-made content",
  };

  const recordOption: RadioCardOption = {
    key: "record",
    title: "Record yourself",
    subTitle: "Use your webcam to record a video",
  };

  const chooseScriptOption: RadioCardOption = {
    key: "chooseScript",
    title: "Choose a script",
    subTitle: "Browse the script library to use with the teleprompter.",
  };

  const postOptions = useMemo(() => {
    if (hasScriptLibrary) {
      return [recordOption, chooseScriptOption, uploadOption, promptOption];
    } else {
      return [uploadOption, promptOption];
    }
  }, [hasScriptLibrary, chooseScriptOption, recordOption, uploadOption, promptOption]);

  const videoPageOptions = useMemo(() => {
    if (hasScriptLibrary) {
      return [recordOption, chooseScriptOption, uploadVideoOption];
    } else {
      return [uploadVideoOption];
    }
  }, [hasScriptLibrary, chooseScriptOption, recordOption, uploadVideoOption]);

  const videoProjectOptions = useMemo(() => {
    if (hasScriptLibrary) {
      return [recordOption, chooseScriptOption, uploadOption];
    } else {
      return [uploadOption];
    }
  }, [hasScriptLibrary, chooseScriptOption, recordOption, uploadOption]);

  const displayedOptions = useMemo(() => {
    switch (chosenOption) {
      case "post":
        return postOptions;
      case "videoPage":
        return videoPageOptions;
      case "videoProject":
        return videoProjectOptions;
      default:
        return primaryOptions;
    }
  }, [primaryOptions, chosenOption, postOptions, videoPageOptions, videoProjectOptions]);

  const title = useMemo(() => {
    switch (chosenOption) {
      case "post":
        return "New Post - Choose a source";
      case "videoPage":
        return "New Video Page - Choose a source";
      case "videoProject":
        return "New Video Project - Choose a source";
      default:
        return "What would you like to create today?";
    }
  }, [chosenOption]);

  const cancelLabel = useMemo(() => {
    if (chosenOption) {
      return "Back";
    } else {
      return "Cancel";
    }
  }, [chosenOption]);

  const onCancel = () => {
    if (chosenOption) setChosenOption(undefined);
    else handleDone();
  };

  const selectOption = (key: string | undefined) => {
    switch (key) {
      case "post":
      case "videoPage":
      case "videoProject":
        if (hasScriptLibrary) setHighlightedOption("record");
        else setHighlightedOption("upload");
        setChosenOption(key);
        break;
      case "chooseScript":
        handleDone();
        history.push(URLS.player.libraryScripts);
        break;
      case "script":
        handleDone();
        history.push(URLS.player.newVideoScript);
        break;
      case "record":
        handleDone();
        history.push(URLS.player.recordVideo, { purpose: chosenOption });
        break;
      case "prompt":
        handleDone();
        history.push(URLS.player.libraryPrompts);
        break;
      case "upload":
        hiddenFileInput.current?.click();
        break;
    }
  };

  const handleFilesSelected = async (e: React.ChangeEvent<HTMLInputElement>): Promise<any> => {
    const inputFiles = e.target.files || new FileList();
    if (inputFiles.length == 0) return;

    setUploading(true);
    setUploadProgress(0);
    const file = inputFiles[0];
    const timestamp = new Date().getTime();
    const fileName = file.name.split(".")[0] + timestamp;
    const mediaCategory = file.type.includes("image/") ? "IMAGE" : "VIDEO";
    const media = {
      id: -1,
      sortOrder: 0,
      category: mediaCategory,
      uri: URL.createObjectURL(file),
      type: file.type,
      extension: file.type.split("/")[1],
      imageFile: file,
      fileName,
    } as Media;

    const uploader = new ProjectFileUploader(chosenOption, playerId!.toString(), firebase);
    const uploaded = await uploader.prepareMedia([media], (_, progress) => {
      setUploadProgress(Math.trunc(progress * 100));
    });
    setUploading(false);
    onMediaSelected(uploaded[0], chosenOption as "post" | "videoPage" | "videoProject");
    handleDone();
  };

  return (
    <>
      <ChoicesModal
        open={open}
        title={title}
        options={displayedOptions}
        selectedOption={highlightedOption}
        onChange={setHighlightedOption}
        onSelect={selectOption}
        onClose={handleDone}
        onCancel={onCancel}
        cancelLabel={cancelLabel}
        disabled={uploading}
        loadingComponent={
          uploading ? (
            <Progress progress percent={uploadProgress} label={"Uploading..."} className={"newOptionsProgress"} />
          ) : undefined
        }
      />
      <input
        type="file"
        accept={chosenOption == "videoPage" ? "video/*" : "image/*, video/*"}
        onChange={handleFilesSelected}
        ref={hiddenFileInput}
        style={{ display: "none" }}
      />
    </>
  );
};

export const NewOptionsModal = NewOptionsModalFC;
