import * as React from "react";

import { PublicContentPageInfo } from "./interfaces";
import { PublicContentPage } from "./containers";

interface Props {
  pageInfo?: PublicContentPageInfo;
  isPreview?: boolean;
}

const ContentPageApp: React.FC<Props> = ({ pageInfo, isPreview }) => {
  return <PublicContentPage pageInfo={pageInfo} isPreview={isPreview} />;
};

export default ContentPageApp;
