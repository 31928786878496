import styled from "styled-components";
import { Form, Grid } from "semantic-ui-react";

import { theme } from "./theme";
import Webcam from "react-webcam";

export const headerSize = (customurl?: string): string => {
  if (customurl) {
    return `url("${customurl}") no-repeat center top`;
  }

  return `transparent`;
};

export const WhiteContainer = styled.div`
  background-color: #fff;
  display: flex;
  min-height: 100vh;
`;

export const BasicWhiteContainer = styled.div`
  padding: 35px;
  background-color: #fff;
  text-align: center;
`;

export const TransparentContainer = styled.div`
  background-color: transparent;
  display: flex;
  overflow: hidden;
  min-height: 100vh;
`;

export const FloatedContainer = styled.div`
  padding-top: 50px;
  padding-bottom: 2.5rem;
`;

export const BasicContainer = styled.div`
  padding-bottom: 40px;
`;

export const InternalContentGridLimits = styled(Grid)`
  max-width: 1300px;
  background-color: #fff;
  @media (max-width: 1900px) {
    max-width: 100%;
  }
`;

export const ContentGridLimits = styled(Grid)`
  max-width: 1300px;
  background-color: #fff;
  @media (max-width: 1600px) {
    max-width: 83%;
  }

  @media (max-width: 1450px) {
    max-width: 90%;
  }
`;

export const ContentGrid = styled(ContentGridLimits)({
  display: "flex!important",
  marginTop: "0!important",
  marginLeft: "0!important",
  width: "100%",
  padding: "40px 73px 60px 41px !important",
  flexWrap: "wrap",
});

export const InternalContentGrid = styled(InternalContentGridLimits)({
  display: "flex!important",
  marginTop: "0!important",
  marginLeft: "0!important",
  width: "100%",
  padding: "0px !important",
  flexWrap: "wrap",
});
export const TabContentGrid = styled(Grid)({
  backgroundColor: "#fff",
  display: "flex !important",
  marginTop: "0px !important",
  marginLeft: "0px !important",
  width: "100%",
  padding: "0px !important",
  flexWrap: "wrap",
});

export const SegmentedGrid = styled(Grid)({
  backgroundColor: `${theme.backgroundColor}`,
  display: "flex!important",
  marginTop: "0!important",
  marginLeft: "0!important",
  width: "100%",
  flexWrap: "wrap",
});

export const ContentGridSection = styled(Grid)({
  paddingTop: "20px !important",
  paddingLeft: "40px !important",
  marginLeft: "0 !important",
  maxWidth: `${theme.maxWidthSizeContainer}px`,
  // overflowX: "scroll",

  "@media (max-width: 1600px)": {
    maxWidth: "83%",
  },

  "@media (max-width: 1550px)": {
    maxWidth: "90%",
  },

  "@media (max-width: 1450px)": {
    maxWidth: "90%",
  },
});

export const SocialGrid = styled(Grid)`
  padding-left: ${(props: any) => (props.leftpadding ? theme.size[props.leftpadding] + "px" : "inherit")};
  padding-right: ${(props: any) => (props.rightpadding ? props.rightpadding + "px" : "inherit")};
`;

export const SocialGridColumn = styled(Grid.Column)`
  padding-left: ${(props: any) => (props.leftpadding ? theme.size[props.leftpadding] + "px" : "inherit")};
  padding-right: ${(props: any) => (props.rightpadding ? props.rightpadding + "px !important" : "inherit")};
`;

export const SocialGridWithPadding = styled(Grid)`
  padding-bottom: ${(props: any) => (props.bottom ? props.bottom + "px" : "inherit")};
`;

export const InternalPaddingGrid = styled(Grid)({
  maxWidth: `${theme.maxWidthSizeContainer}px`,
  marginLeft: "37px !important",
});

export const InternalGrid = styled(Grid)({
  maxWidth: `${theme.maxWidthSizeContainer}px`,
  marginLeft: "0 !important",
  overflow: "hidden",
});

export const SmallGrid = styled(Grid)({
  paddingTop: "20px !important",
  minHeight: "90px",
});

export const SCSubtitle = styled.p`
  color: ${theme.lineColor};
  font-family: "Open Sans", sans-serif;
  font-size: 1.15em;
  letter-spacing: -0.24px;
  line-height: 21.79px;
  text-align: left;
  white-space: pre-line;
`;

export const ColumnBasicPadding = styled(Grid.Column)({
  padding: "15px 0 !important",
});

export const RowBasicPadding = styled(Grid.Row)({
  paddingBottom: "15px !important",
});

export const SocialBasicRow = styled(Grid.Row)({
  padding: "0px !important",
});

export const SocialRowGrid = styled(Grid.Row)({
  paddingBottom: "20px !important",
  paddingLeft: "15px",
});

export const RowMediumPadding = styled(Grid.Row)({
  paddingBottom: "20px !important",
});

export const GridNoMargin = styled(Grid)({
  marginTop: "0px !important",
});

export const RowNoPadding = styled(Grid.Row)({
  padding: "0px 0px 5px 0px !important",
});
export const BlueTag = styled.span`
  background: ${theme.buttonColor};
  color: white;
  font-family: "Open Sans", sans-serif;
  float: left;
  height: 25px;
  border-radius: 5px;
  padding: 1px 8px;
  margin: 3px 5px 0px 10px;
`;

export const TitleLabel = styled.label`
  font-weight: 700;
  color: rgba(0, 0, 0, 0.87);
`;

export const BrandHeaderImage = styled(Grid.Column)`
  border-bottom: 3px solid black;
  color: #fff;
  padding: 30px;
  height: 75px;
  background: ${props => headerSize(props.customurl)};
  background-size: ${props => (props.thumbnailurl ? "cover" : "contain")};
  background-color: white;
`;

const height = "36px";
const thumbHeight = 30;
const trackHeight = "6px";

const thumbColor = "rgba(58,171,239, .6)";
const thumbMiddleColor = "rgba(58,171,239, .7)";
const thumbBorderColor = "rgba(255,255,255, .1)";
const upperBackground = `linear-gradient(to bottom, ${theme.borderColor}, ${theme.borderColor}) 100% 50% / 100% ${trackHeight} no-repeat transparent`;
const lowerBackground = `linear-gradient(to bottom, ${theme.primaryColor}, ${theme.primaryColor}) 100% 50% / 100% ${trackHeight} no-repeat transparent`;

const makeLongShadow = (color: string, size: string) => {
  let i = 0;
  let shadow = `${i}px 0 0 ${size} ${color}`;

  for (; i < 706; i++) {
    shadow = `${shadow}, ${i}px 0 0 ${size} ${color}`;
  }
  return shadow;
};

export const SliderInput = styled.input`
  overflow: hidden;
  display: block;
  appearance: none;
  width: 100%;
  margin: 0;
  height: ${height};
  cursor: pointer;

  &:focus {
    outline: none;
  }

  /* Track left section */
  &::-webkit-slider-runnable-track {
    width: 100%;
    height: ${height};
    background: ${lowerBackground};
  }

  /* Track right section */
  &::-webkit-slider-thumb {
    position: relative;
    appearance: none;
    height: ${thumbHeight}px;
    width: ${thumbHeight}px;
    background-size: cover;
    background-image: radial-gradient(circle, ${thumbColor}, ${thumbMiddleColor}, ${thumbBorderColor});
    border-radius: 100%;
    border: 0;
    top: 50%;
    transform: translateY(-50%);
    z-index: 20;
    box-shadow: ${makeLongShadow(`${theme.borderColor}`, "-12px")};
    transition: background-color 150ms;
  }

  &::-moz-range-track,
  &::-moz-range-progress {
    width: 100%;
    height: ${height};
    background: ${upperBackground};
  }

  &::-moz-range-progress {
    background: ${lowerBackground};
  }

  &::-moz-range-thumb {
    appearance: none;
    margin: 0;
    height: ${thumbHeight};
    width: ${thumbHeight};
    background: ${thumbColor};
    border-radius: 100%;
    border: 0;
    transition: background-color 150ms;
    background-image: radial-gradient(circle, ${thumbColor}, ${thumbMiddleColor}, ${thumbBorderColor});
  }

  &::-ms-track {
    width: 100%;
    height: ${height};
    border: 0;
    /* color needed to hide track marks */
    color: transparent;
    background: transparent;
  }

  &::-ms-fill-lower {
    background: ${upperBackground};
  }

  &::-ms-fill-upper {
    background: ${upperBackground};
  }

  &::-ms-thumb {
    appearance: none;
    height: ${thumbHeight};
    width: ${thumbHeight};
    background-image: radial-gradient(circle, ${thumbColor}, ${thumbMiddleColor}, ${thumbBorderColor});
    border-radius: 100%;
    border: 0;
    transition: background-color 150ms;
    /* IE Edge thinks it can support -webkit prefixes */
    top: 0;
    margin: 0;
    box-shadow: none;
  }
`;

export const ScriptTextArea = styled.textarea<{ fontSize?: number }>`
  padding: 50px 10px 500px 10px;
  border-radius: 10px;
  border: 2px solid darkgrey;
  font-size: ${(props: any) => (props.fontSize ? props.fontSize + "px" : "12px")};
  width: 98%;
  height: 560px;
  resize: none;
`;

export const CustomColor = styled.div<{ fill?: string }>`
  padding: 10px;
  margin-right: 10px;
  margin-left: 10px;
  border-radius: 2px;
  width: 20px;
  height: 20px;
  border: 2px solid darkgrey;
  background: ${(props: any) => (props.fill ? props.fill : "white")};
`;

export const CustomBox = styled.p`
  padding-top: 2px;
  margin-bottom: 1px;
  display: flex;
`;

export const SocialCoachWebCam = styled(Webcam)<{ display?: boolean }>`
  display: ${(props: any) => (props.display ? "inherit" : "none")};
`;
