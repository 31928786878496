import * as React from "react";
import { Header, Button, Dropdown, ButtonGroup } from "semantic-ui-react";

import "./styles.scss";

export interface PageActionProps {
  text: string;
  icon?: string;
  disabled?: boolean;
  loading?: boolean;
  onClick: (event: any, data: any) => void;
}

export interface PageTitleProps {
  title: string;
  actions?: PageActionProps[];
  hideSeparator?: boolean;
}

const PageTitleFC: React.FC<PageTitleProps> = ({ title, actions, hideSeparator = false }) => {
  const actionButtons = (): JSX.Element | null => {
    if (!actions) return null;

    const options = actions.map((item, index) => ({ key: index, selected: false, active: false, ...item }));
    const primaryAction = options.shift();
    if (!primaryAction) return null;

    const primaryButton = (
      <Button
        disabled={primaryAction.disabled}
        onClick={primaryAction.onClick}
        className="mainActionButton"
        data-elm-id={`pageTitle${primaryAction.text.replace(" ", "")}Btn`}
        loading={primaryAction.loading}
      >
        {primaryAction.text}
      </Button>
    );
    return (
      <ButtonGroup primary>
        {primaryButton}
        {options.length > 0 && (
          <Dropdown className="secondaryActionButton button icon" options={options} trigger={<></>} />
        )}
      </ButtonGroup>
    );
  };

  return (
    <div className="pageTitleContainer">
      <div className="titleRow">
        <Header as="h2" className="titleWithBackLabel">
          {title}
        </Header>
        {actionButtons()}
      </div>

      {hideSeparator || <div className="line" />}
    </div>
  );
};

export const PageTitle = PageTitleFC;
