import * as React from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { Formik } from "formik";

import { AppContext } from "../../../providers";
import { LoginStepServices, PlayerSignUpStep, PlayersServices } from "../../../services";
import { FirstSocialNetworks } from "../../../components/Players/FirstLogin/SocialNetwork";
import { LandingResponse } from "../../../interfaces";
import { ConfirmationModal } from "../../../components";

interface OwnProps {
  coachInfo?: LandingResponse;
  successHandler: (data?: any) => void;
}

type Props = OwnProps & RouteComponentProps;

const FirstSocialNetworksFC: React.FC<Props> = ({ successHandler, coachInfo }) => {
  const { userContext, setUserContext } = React.useContext(AppContext);
  const [openConfirmationModal, setOpenConfirmationModal] = React.useState<boolean>(false);

  const confirmationModal = (): JSX.Element => {
    return (
      <ConfirmationModal
        title={"Thank you for setting up your account. Let’s get posting!"}
        okLabel={"OK"}
        customActionClassName={"centerContainer"}
        openConfirmationModal={openConfirmationModal}
        okHandler={() => {
          setUserContext({
            ...userContext!,
            signUpStep: PlayerSignUpStep[PlayerSignUpStep.PLAYER_BUSINESS_DATA],
          });
          successHandler();
        }}
      />
    );
  };
  return (
    <div>
      <Formik
        validateOnBlur={false}
        validateOnChange={true}
        initialValues={{}}
        onSubmit={async ({}, { setSubmitting, setErrors }) => {
          try {
            LoginStepServices.sendPlayerStep(PlayerSignUpStep.PLAYER_BUSINESS_DATA).then(() => {
              PlayersServices.updatePlayerQuestions(userContext?.player?.id!, userContext?.player).then(async () => {
                if (userContext!.profile!.socialNetworks && userContext!.profile!.socialNetworks?.length > 0) {
                  setOpenConfirmationModal(true);
                } else {
                  PlayersServices.sendFollowUpEmail(coachInfo!!.coachId);
                  setOpenConfirmationModal(true);
                }
              });
            });
          } catch (e) {
            setErrors({
              socialNetworks: `${e}`,
            });
          }
          setSubmitting(false);
        }}
      >
        {({ errors, handleSubmit, isSubmitting }) => (
          <FirstSocialNetworks
            messages={{
              error: Object.values(errors) as string[],
            }}
            coachInfo={coachInfo?.coach}
            handleSubmit={handleSubmit}
            isSubmitting={isSubmitting}
          />
        )}
      </Formik>
      {confirmationModal()}
    </div>
  );
};

export const FirstSocialNetworksForm = withRouter(FirstSocialNetworksFC);
