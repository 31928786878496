import * as Yup from "yup";
import { phoneRegExp } from "../../../utils";

export const validationProfileSchema = () => {
  return Yup.object().shape({
    firstName: Yup.string().trim().required("First Name is required"),
    lastName: Yup.string().trim().required("Last Name is required"),
    phoneNumber: Yup.string().matches(phoneRegExp, "Phone number is not valid").required("Phone number is required"),
  });
  // );
};
