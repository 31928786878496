import moment from "moment-timezone";
import { DayOfWeek } from "../interfaces";

export const dateMask = [/[0-1]/, /\d/, "/", /[0-3]/, /[0-9]/, "/", /[1-2]/, /\d/, /\d/, /\d/];
export const PSTimeZone = "US/Pacific";

const chatFullFormat: Intl.DateTimeFormatOptions = {
  year: "2-digit",
  month: "2-digit",
  day: "2-digit",
  hour: "numeric",
  minute: "numeric",
  hour12: true,
};
const chatTodayFormat: Intl.DateTimeFormatOptions = { hour: "numeric", minute: "numeric", hour12: true };

export const validAge = (date: Date, years: number): Date => {
  const result = new Date(date);
  result.setFullYear(result.getFullYear() + years);
  return result;
};

export const addDays = (date: Date, days: number): Date => {
  if (date === undefined || date === null) {
    return new Date();
  }
  const result = new Date(date);
  result.setDate(result.getDate() + days);

  if (days > 0) {
    result.setMonth(result.getDate() < date.getDate() ? date.getMonth() + 1 : date.getMonth());
    result.setFullYear(result.getMonth() < date.getMonth() ? date.getFullYear() + 1 : date.getFullYear());
  } else {
    result.setMonth(result.getDate() > date.getDate() ? date.getMonth() - 1 : date.getMonth());
    result.setFullYear(result.getMonth() > date.getMonth() ? date.getFullYear() - 1 : date.getFullYear());
  }

  return result;
};

export const getDateAfter = (date: Date, days: number): Date => {
  const result = new Date(date);
  result.setDate(result.getDate() + days);
  return result;
};

export const nextWeekDay = (date: Date = new Date()): Date => {
  const day = date.getDay();
  let add = 1;
  if (day === 6) {
    add = 2;
  } else if (day === 5) {
    add = 3;
  }
  date.setDate(date.getDate() + add); // will correctly handle 31+1 > 32 > 1st next month
  return date;
};

export function promptSpecificDate(deliveryDateUtc: string | null, deliveryTimeUtc: string | null): string | null {
  const deliveryTimeMoment = moment(deliveryTimeUtc).tz(PSTimeZone);
  const deliveryDateMoment = moment(deliveryDateUtc).tz(PSTimeZone);

  const mergedDateM = moment({
    year: deliveryDateMoment.year(),
    month: deliveryDateMoment.month(),
    date: deliveryDateMoment.date(),
    hours: deliveryTimeMoment.hours(),
    minutes: deliveryTimeMoment.minutes(),
  });

  return moment.utc(mergedDateM).format();
}

export function serverDateUTCToDate(dateUtc: string | null): Date {
  const dateUtcT = moment.utc(dateUtc, "YYYY-MM-DDTHH:mm:ssZ").add(9, "hours");
  return new Date(dateUtcT.format());
}

export function datePSTString(date?: string | null): string | undefined {
  if (date === "") {
    return undefined;
  }

  let dateStringPST = moment().tz(PSTimeZone).format();

  if (date) {
    dateStringPST = moment(date).tz(PSTimeZone).format();
  }

  return dateStringPST;
}

export function datePST(date?: string | null): moment.Moment | undefined {
  if (date === "") {
    return undefined;
  }

  let inPST = moment().tz(PSTimeZone);

  if (date) {
    inPST = moment(date).tz(PSTimeZone);
  }

  return inPST;
}

export function future(date?: string): boolean {
  if (date === undefined || date === "") {
    return true;
  }
  const dateUtc = moment.utc(date, "YYYY-MM-DDTHH:mm:ssZ");

  return moment().diff(dateUtc, "minutes") <= 0;
}

export function isToday(date?: string): boolean {
  if (date === undefined || date === "") {
    return true;
  }
  const dateUtc = moment.utc(date, "YYYY-MM-DDTHH:mm:ssZ");
  return moment().diff(dateUtc, "days") === 0;
}

export function isPassDay(date?: string): boolean {
  if (date === undefined || date === "") {
    return true;
  }

  const today = new Date();
  const dateToCompare = new Date(date);

  today.setHours(0, 0, 0, 0);
  dateToCompare.setHours(0, 0, 0, 0);

  return !(
    today.getDate() === dateToCompare.getDate() &&
    today.getMonth() === dateToCompare.getMonth() &&
    today.getFullYear() === dateToCompare.getFullYear()
  );
}

export function futureDate(date?: string): boolean {
  if (date === undefined || date === "") {
    return true;
  }
  const dateUtc = moment.utc(date, "YYYY-MM-DDTHH:mm:ssZ");
  return moment().diff(dateUtc, "days") <= 0;
}

export function printDatePST(date?: string): string {
  if (date === "" || date === undefined || date === null) {
    return "-";
  }

  const dateStringPST = moment(date).tz(PSTimeZone).format("MM/DD/YYYY hh:mm a");

  return dateStringPST;
}

export function printDateTime(date: string | undefined): string {
  if (date === "" || date === undefined) {
    return "-";
  }

  const dateString = moment(date).format("MMM D [at] h:mm a");

  return dateString;
}

export function printOnlyDate(date?: string): string {
  if (!date) {
    return "-";
  }
  return moment(date).format("MMM DD, YYYY");
}

export function printBasicDate(date?: string): string {
  if (!date) {
    return "-";
  }
  return moment(date).format("MM/DD/YYYY");
}

export function printDate(date?: string): string {
  if (date === undefined) {
    return "-";
  }
  return new Date(date).toLocaleString("en-US");
}

export function printDateToChat(date?: string): string {
  if (date === undefined) {
    return "-";
  }
  return isPassDay(date)
    ? new Date(date).toLocaleString("en-US", chatFullFormat!!)
    : "Today " + new Date(date).toLocaleString("en-US", chatTodayFormat);
}

// Create a moment object in PST no matter LTZ
export function changeLocalDateToPST(date: string): string {
  const dateOBJ = new Date(date);
  const pacificDate = moment.tz(date, PSTimeZone);
  pacificDate.date(dateOBJ.getDate());
  pacificDate.month(dateOBJ.getMonth());
  pacificDate.year(dateOBJ.getFullYear());
  pacificDate.hours(dateOBJ.getHours());
  pacificDate.minutes(dateOBJ.getMinutes());
  return pacificDate.format();
}

export function changeLocalDatePlusOneToPST(date: string): string {
  const dateOBJ = new Date(date);
  const pacificDate = moment.tz(date, PSTimeZone);
  pacificDate.date(dateOBJ.getDate() + 1);
  pacificDate.month(pacificDate.date() < dateOBJ.getDate() ? dateOBJ.getMonth() + 1 : dateOBJ.getMonth());
  pacificDate.year(pacificDate.month() < dateOBJ.getMonth() ? dateOBJ.getFullYear() + 1 : dateOBJ.getFullYear());
  pacificDate.hours(dateOBJ.getHours());
  pacificDate.minutes(dateOBJ.getMinutes());
  return pacificDate.format();
}

export function filterPassedTime(time: string | number | Date, date: string, hourRange: number = 0): any {
  const pstDate = datePST(date);

  if (pstDate === undefined) {
    return true;
  }

  const selectedDate = new Date(time);
  const now = moment().tz(PSTimeZone).format("HH:mm"); // Now in PST

  const currentTime = moment(now, "HH:mm");
  currentTime.hour(currentTime.hours() + hourRange);
  const selectedMoment = moment(selectedDate.getHours() + ":" + selectedDate.getMinutes(), "HH:mm");

  const tomorrow =
    moment().tz(PSTimeZone).diff(pstDate, "days") === 0 && moment().tz(PSTimeZone).date() < pstDate.date();
  return moment().tz(PSTimeZone).diff(pstDate, "days") < 0 || tomorrow ? true : selectedMoment.isAfter(currentTime);
}

export const getDayOfWeekState = (selected: DayOfWeek[]) => {
  const defaultSelected = false; // selected.length === 0;
  const daysStatus = {
    [DayOfWeek.Monday]: defaultSelected,
    [DayOfWeek.Tuesday]: false,
    [DayOfWeek.Wednesday]: defaultSelected,
    [DayOfWeek.Thursday]: false,
    [DayOfWeek.Friday]: defaultSelected,
    [DayOfWeek.Saturday]: false,
    [DayOfWeek.Sunday]: false,
  };

  selected.forEach((dayOfWeek: DayOfWeek) => {
    daysStatus[dayOfWeek] = true;
  });

  return daysStatus;
};
export const stringTimeToDate = (currentTime?: string): Date | undefined => {
  if (currentTime) {
    const timesArray = currentTime.split(":");
    const timeDate = new Date();
    timeDate.setHours(Number(timesArray[0]), Number(timesArray[1]), Number(timesArray[2]));

    return timeDate;
  }

  return undefined;
};

export const dateToTimeString = (dateString?: string): string | undefined => {
  if (dateString === "" || !dateString) {
    return undefined;
  }
  const dateObject = new Date(dateString);
  const minutes = dateObject.getMinutes() === 0 ? "00" : dateObject.getMinutes();
  const hours = dateObject.getHours() < 10 ? `0${dateObject.getHours()}` : dateObject.getHours();

  return `${hours}:${minutes}:00`;
};

export const getDateSearchFormat = (date?: any): string | undefined => {
  const dateInLocalDateToPST = date ? changeLocalDateToPST(date!) : undefined;
  return date ? dateInLocalDateToPST?.split("T")[0].concat("T00:00:00Z") : undefined;
};

export const getUntilDateSearchFormat = (date?: any): string | undefined => {
  const dateInLocalDateToPST = date ? changeLocalDatePlusOneToPST(date!) : undefined;
  return date ? dateInLocalDateToPST?.split("T")[0].concat("T00:00:00Z") : undefined; // "2022-05-11T20:57:48Z" //
};
