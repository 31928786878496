import * as React from "react";
import { Checkbox, Dropdown, DropdownItemProps, Form, Header } from "semantic-ui-react";
import states from "states-us";
import { map, union } from "lodash";

import { BasicFormProps } from "../../../../interfaces";
import { CoachService } from "../../../../services";

import "./styles.scss";

interface OwnProps extends BasicFormProps {
  /**
   * Coach Id to get branch, states, regions, cost center, and industry roles
   */
  coachId: string;
  /**
   * Input placeholder
   */
  placeholder: string;
  /**
   * Field identifier
   */
  id: string;
  /**
   * Field label (Title)
   */
  label: string;
  /**
   * Field value
   */
  fieldValue: any;
  /**
   * It's disabled
   */
  disabled: boolean;
  /**
   * Classname (optional)
   */
  className?: string;
  /**
   * To indicate if the field is multi selection
   */
  multiple: boolean;
  /**
   * To indicate if the field allows custom input
   */
  allowAdditions: boolean;
  /**
   * Handle dropdowns changes
   */
  handleChange: (data: any) => void;
}

type Props = OwnProps;

/**
 * Add enterprise player internal form
 */

const SegmentationDropDownFC: React.FC<Props> = ({
  coachId,
  id,
  placeholder,
  fieldValue,
  label,
  disabled,
  className = "segmentationDropDown",
  multiple,
  allowAdditions,
  handleChange,
}) => {
  const [options, setOptions] = React.useState<DropdownItemProps[]>([]);

  React.useEffect(() => {
    if (id === "branch") {
      CoachService.branches(coachId).then(response => {
        const filters: DropdownItemProps[] = map(response, branch => ({
          key: branch.name,
          text: branch.name,
          value: branch.name,
        }));
        setOptions(filters);
      });
    } else if (id === "region") {
      CoachService.regions(coachId).then(response => {
        const filters: DropdownItemProps[] = map(response, region => ({
          key: region.name,
          text: region.name,
          value: region.name,
        }));
        setOptions(filters);
      });
    } else if (id === "costCenter") {
      CoachService.costCenters(coachId).then(response => {
        const filters: DropdownItemProps[] = map(response, region => ({
          key: region.name,
          text: region.name,
          value: region.name,
        }));
        setOptions(filters);
      });
    } else if (id === "industryRole") {
      CoachService.industryRoles(coachId).then(response => {
        const filters: DropdownItemProps[] = map(response, region => ({
          key: region.name,
          text: region.name,
          value: region.name,
        }));
        setOptions(filters);
      });
    } else if (id === "state") {
      const filters = map(states, state => ({
        key: state.abbreviation,
        text: state.abbreviation,
        value: state.abbreviation.toUpperCase(),
        dropdownText: " " + state.abbreviation + " - " + state.name,
      }));
      setOptions(filters);
    }
  }, [coachId, id]);

  const handleAdditionField = (e: any, data: any) => {
    const { value } = data;
    const newCat = value.trim();
    if (newCat !== "") {
      setOptions(olds => [{ text: value, value }, ...olds]);
    }
  };
  const props = {
    id,
    className,
    search: !multiple,
    selection: true,
    fluid: true,
    multiple,
    disabled,
    labeled: !multiple,
    placeholder: placeholder,
    value: fieldValue,
    options: options,
    allowAdditions: allowAdditions,
    clearable: !multiple,
    icon: !multiple && fieldValue ? undefined : "",
    onAddItem: handleAdditionField,
    onChange: (e: any, data: any) => {
      handleChange(data);
    },
    "data-elm-id": `segmentation_dropdown_${id}`,
  };

  const simpleDropDown: JSX.Element = <Dropdown {...props} />;

  const selectableDropdown: JSX.Element = (
    <Dropdown {...props}>
      <Dropdown.Menu onClick={(e: any) => e.stopPropagation()}>
        {options.map((filter, index) => {
          return (
            <Dropdown.Item key={`dropdown_player_${id}_${index}`}>
              <Checkbox
                id={filter.key}
                checked={fieldValue.includes(`${filter!.value!}`)}
                onChange={(a, data) => {
                  // Dropdown's onChange handler above should be called if a user clicks on the "X" icon next to
                  // a selected item, but not if a user clicks on a checkbox. Hence the `stopPropagation()` here:
                  a.stopPropagation();

                  const selectedState = `${filter!.value!}`.toUpperCase();
                  let selected: string[] = fieldValue;
                  if (data.checked) {
                    selected = union(selected, [selectedState]);
                  } else {
                    selected = selected?.filter(r => r !== selectedState);
                  }
                  handleChange({ value: selected });
                }}
                label={filter.dropdownText ? filter.dropdownText : filter.text}
              />
            </Dropdown.Item>
          );
        })}
      </Dropdown.Menu>
    </Dropdown>
  );

  return (
    <Form.Group widths="equal" className={"groupInputsContainer"} key={`segmentation_dropdown_${id}`}>
      <Header as={"h3"} className={"permissionLabel"}>
        {label}
      </Header>
      {multiple ? selectableDropdown : simpleDropDown}
    </Form.Group>
  );
};

export const SegmentationDropDown = React.memo(SegmentationDropDownFC);
