import { useState, useEffect } from "react";
import { HashTagsServices, KeywordServices } from "../../../services";
import { KeywordsType, PlayerPost, TagElement } from "../../../interfaces";
import { tagsByPolicy } from "../../../utils";
import { AppUserContext } from "../../../providers";

export const useBlockedWords = (playerId?: number) => {
  const [blockedList, setBlockedList] = useState<TagElement[]>([]);
  const [flaggedList, setFlaggedList] = useState<TagElement[]>([]);
  const [blockedNamesList, setBlockedNamesList] = useState<string[]>([]);
  const [flaggedNamesList, setFlaggedNamesList] = useState<string[]>([]);

  useEffect(() => {
    const getKeywords = async () => {
      if (playerId) {
        const action = KeywordServices.getAsPlayer(playerId!!);

        const result = await action;
        setBlockedList(() => tagsByPolicy(result, KeywordsType.Blocked));
        setFlaggedList(() => tagsByPolicy(result, KeywordsType.Flagged));
      }
    };

    getKeywords();
  }, [playerId]);

  useEffect(() => {
    setBlockedNamesList(blockedList.map(a => a.displayValue.toLowerCase()));
  }, [blockedList]);

  useEffect(() => {
    setFlaggedNamesList(flaggedList.map(a => a.displayValue.toLowerCase()));
  }, [flaggedList]);

  return { blockedList, blockedNamesList, flaggedList, flaggedNamesList };
};

// assemble a list of default hashtags to add to the caption. Only add hashtags that are not already included in the caption passed to the component - so that hashtags aren’t added twice automatically.
// add the coach’s default hashtags
// if the coach allows player hashtags, add the player’s hashtags
// when the coach has the setting enabled to add the compliance disclaimer by default,
// or if the promptId’s prompt has the compliance disclaimer flag enabled, add the player’s compliance disclaimer (unless it’s already been added in the caption passed to the component)
export const useMessageToRepost = (
  post: PlayerPost,
  playerId: number,
  allowHashtags: boolean,
  allowComplianceDisclaimer: boolean,
  showComplianceDisclaimerSection: boolean,
  user: AppUserContext
) => {
  const [messageToPost, setMessageToPost] = useState<string>(post.message);

  const [hashtags, setHashTags] = useState<string[] | undefined>(undefined);

  // Get player hashtags
  useEffect(() => {
    if (playerId) {
      HashTagsServices.get(playerId).then(response => {
        setHashTags(response.map(hashtag => hashtag.content));
      });
    }
  }, [playerId]);

  useEffect(() => {
    let message = post.message || "";

    const hashtagsToInclude = hashtags?.filter(hashtag => !message.includes(hashtag));

    if (
      hashtagsToInclude &&
      hashtagsToInclude.length > 0 &&
      user?.player!!.addHashTagAutomatically &&
      (allowHashtags !== false || !post.promptId)
    ) {
      message = message + `\n#${hashtagsToInclude.join(" #")}`;
    }
    if (
      showComplianceDisclaimerSection &&
      user?.profile?.complianceDisclaimer &&
      (allowComplianceDisclaimer !== false || !post.promptId)
    ) {
      message = message + `\n${user?.profile?.complianceDisclaimer}`;
    }

    setMessageToPost(message);
  }, [post, showComplianceDisclaimerSection, allowHashtags, allowComplianceDisclaimer, hashtags]);

  return { messageToPost };
};
