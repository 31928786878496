import * as React from "react";
import { RouteComponentProps, withRouter } from "react-router";
import { useIntl } from "react-intl";
import { Menu, MenuItem } from "semantic-ui-react";
import { get } from "lodash";

import { SideBar, TitleSection } from "../../../components";
import { AppContext } from "../../../providers";
import { CoachSettings, LibraryTags, Media, PricePlanTier } from "../../../interfaces";
import { URLS } from "../../../utils";

import { WhiteContainer, ContentGrid, SocialGrid, SocialGridColumn } from "../../../styling/baseStyle";

import { PlayerVideoCatalyst } from "./components/PlayerVideoCatalyst";

import { LibraryType, descriptors } from "./descriptors";
import { RowContainer } from "./styled";
import "./styles.scss";
import useAdminCoachSettings from "../../../components/AppSettingsTab/adminSettingsHook";
import { useContext, useEffect, useMemo, useState } from "react";
import usePlayerSettings from "../../../utils/usePlayerSettings";
import { PlayerVideoScripts } from "./components/PlayerVideoScripts";
import { PlayerPrompts } from "./components/PlayerPrompts";
import { PlayerVideoPages } from "./components/PlayerVideoPages";
import { NewOptionsModal } from "./components/NewOptionsModal";
import { useParams } from "react-router-dom";
import { PostModal } from "../RecordVideo/components/PostModal";
import { VideoPageModal } from "../VideoPageModal";

interface SettingMenu {
  title: string;
  activeSection: LibraryTags;
}

type Props = RouteComponentProps;

const LibraryFC: React.FC<Props> = ({ history }) => {
  const { tab } = useParams<{ tab: LibraryTags }>();
  const { formatMessage } = useIntl();
  const { userContext } = useContext(AppContext);

  const myCoachId = get(userContext, "subscriptions[0].pricePlan.coachId", undefined);
  const playerId = get(userContext, "player.id", undefined);
  const subscriptionType: string = get(userContext, "subscriptions[0].pricePlan.tier", "");
  const { hasSetting } = usePlayerSettings(playerId);
  const hasSCPlus = subscriptionType === PricePlanTier.PLAYER_PLUS;
  const { displayAdminSettings } = useAdminCoachSettings(myCoachId);
  const hasScriptLibrary = useMemo(() => {
    return hasSCPlus || displayAdminSettings.accessScriptLibrary;
  }, [displayAdminSettings.accessScriptLibrary]);
  const hasMVP = useMemo(() => {
    return hasSetting(CoachSettings.PLAYER_MVP_ACCESS);
  }, [hasSetting]);

  const [activeSection, setActiveSection] = useState<LibraryTags>(tab || "prompts");
  const [showNewModal, setShowNewModal] = useState(false);
  const [showNewPostModal, setShowNewPostModal] = useState(false);
  const [showNewPageModal, setShowNewPageModal] = useState(false);
  const [chosenMedia, setChosenMedia] = useState<Media>();

  useEffect(() => {
    if (tab) setActiveSection(tab);
  }, [tab]);

  useEffect(() => {
    history.replace(URLS.player.library.replace(":tab?", activeSection));
  }, [activeSection]);

  const onNewMedia = (media: Media, type: "post" | "videoPage" | "videoProject") => {
    switch (type) {
      case "post":
        setChosenMedia(media);
        setShowNewPostModal(true);
        break;
      case "videoPage":
        setChosenMedia(media);
        setShowNewPageModal(true);
        break;
      case "videoProject":
        history.push(URLS.player.newVideoProject, { preloadMedia: media });
    }
  };

  const handleDoneWithMedia = () => {
    setChosenMedia(undefined);
    setShowNewPostModal(false);
    setShowNewPageModal(false);
  };

  const defaultNewOption = useMemo(() => {
    switch (activeSection) {
      case "prompts":
        return "post";
      case "video-catalyst":
        return "videoProject";
      case "scripts":
        return "script";
      case "video-pages":
        return "videoPage";
    }
  }, [activeSection]);

  const menuList = useMemo(() => {
    let menu: SettingMenu[] = [{ title: "Prompts", activeSection: "prompts" }];
    if (hasSCPlus) {
      menu.push({ title: "Video Catalyst", activeSection: "video-catalyst" });
    }
    if (hasScriptLibrary) {
      menu.push({ title: "Scripts", activeSection: "scripts" });
    }
    if (hasMVP) {
      menu.push({ title: "Video Pages", activeSection: "video-pages" });
    }

    return menu;
  }, [hasScriptLibrary, hasMVP]);

  const selectedContent = useMemo(() => {
    switch (activeSection) {
      case "prompts":
        return <PlayerPrompts coachId={myCoachId!!} playerId={playerId!!} />;
      case "scripts":
        return <PlayerVideoScripts />;
      case "video-catalyst":
        return <PlayerVideoCatalyst myCoachId={myCoachId!!} playerId={playerId!!} />;
      case "video-pages":
        return <PlayerVideoPages coachId={myCoachId!!} playerId={playerId!!} />;
    }
  }, [activeSection, myCoachId, playerId]);

  return (
    <WhiteContainer>
      <SideBar history={history} />
      <ContentGrid className={"listManagerContent"} columns={1}>
        <SocialGrid columns={1}>
          <TitleSection
            title={formatMessage({ ...descriptors[LibraryType.title] })}
            ready={true}
            showSeparator={false}
            titleSize={8}
            buttonsSize={8}
            customNextLabel={"New..."}
            handleNextAction={() => {
              setShowNewModal(true);
            }}
          />
          <Menu pointing secondary className="internalTapMenu">
            {menuList.map(element => {
              return (
                <MenuItem
                  key={`menu-item-${element.activeSection}`}
                  name={element.title}
                  active={activeSection === element.activeSection}
                  onClick={() => setActiveSection(element.activeSection)}
                />
              );
            })}
          </Menu>

          <RowContainer className="playerListRowContainer">
            <SocialGridColumn rightpadding={"0"}>{selectedContent}</SocialGridColumn>
          </RowContainer>
        </SocialGrid>
      </ContentGrid>

      <NewOptionsModal
        playerId={playerId}
        open={showNewModal}
        defaultOption={defaultNewOption}
        onClose={() => setShowNewModal(false)}
        hasSCPlus={hasSCPlus}
        hasScriptLibrary={hasScriptLibrary}
        hasMVP={!!hasMVP}
        onMediaSelected={onNewMedia}
      />

      {showNewPostModal && (
        <PostModal
          opened={showNewPostModal}
          closeHandler={handleDoneWithMedia}
          media={chosenMedia}
          defaultPostType={chosenMedia?.category}
        />
      )}
      <VideoPageModal opened={showNewPageModal} closeHandler={handleDoneWithMedia} media={chosenMedia} />
    </WhiteContainer>
  );
};

export const PlayerLibrary = withRouter(LibraryFC);
