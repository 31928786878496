import * as React from "react";
import { RouteComponentProps, withRouter } from "react-router";
import { useIntl } from "react-intl";
import { Grid, Header, Loader, Button, Popup } from "semantic-ui-react";
import { get } from "lodash";

import { LocalInfinitySearch, SideBar, TitleSection } from "../../../components";
import { PlayersListWithStats } from "../../../components/PlayersListWithStats";
import { ConfigContext } from "../../../contexts/appContexts";
import * as IT from "../../../interfaces";
import { AppContext } from "../../../providers";
import { CoachSignUpStep, PlayersServices } from "../../../services";
import {
  finishStep,
  URLS,
  exportPlayerStatsList,
  exportPlayerToCsv,
  coachWithReadPermission,
  LEADERBOARD_FIELDS,
  LEADERBOARD_FIELDS_BASE,
} from "../../../utils";

import { Statistics } from "./components/Statistics";
import { descriptors, DashboardType } from "./descriptors";
import "./styles.scss";
import { useSocialNetworks } from "../../../reducers";
import useAdminCoachSettings from "../../../components/AppSettingsTab/adminSettingsHook";
import { useMemo } from "react";

type Props = RouteComponentProps;

const DashboardFC: React.FC<Props> = ({ history }) => {
  const { formatMessage } = useIntl();
  const { userContext, setUserContext } = React.useContext(AppContext);
  const config: IT.ConfigProperties = React.useContext(ConfigContext);
  const { displayAdminSettings } = useAdminCoachSettings(`${userContext?.coach?.id}`);

  const [loadingPlayers, setLoadingPlayers] = React.useState<boolean>(false);
  const [loadingLeaderboard, setLoadingLeaderboard] = React.useState<boolean>(false);
  const [leaderboardProgress, setLeaderboardProgress] = React.useState<number>();
  const [playersProcessList, setPlayersProcessList] = React.useState<IT.PlayerWithStats[]>([]);

  const { sns } = useSocialNetworks(undefined, false);
  const [socialNetworksSelected, setSocialNetworksSelected] = React.useState<any | undefined>(sns);

  const currentStep = userContext ? userContext!.signUpStep : undefined;
  const withReadPermission = coachWithReadPermission(IT.WebComponents.PLAYERS, userContext);

  React.useEffect(() => {
    setSocialNetworksSelected(sns);
  }, [sns]);

  const preparePlayerList = (playersList: IT.SubscribedPlayerWithSplittedStatsSearchResponse[]): void => {
    let playerStatsBySocialNetwork = {};
    playersList.forEach(player => {
      let statsBySocialNetwork = {};

      if (player.statsBySocialNetwork) {
        Object.keys(player.statsBySocialNetwork).forEach(snId => {
          const statBySocialNetwork = player.statsBySocialNetwork[snId];
          statsBySocialNetwork = {
            ...statsBySocialNetwork,
            [snId]: {
              countPromptPosted:
                player.player.promptActivityBySocialNetwork.find(sn => sn.socialNetwork === snId)?.countPromptPosted ||
                0,
              likes: statBySocialNetwork && statBySocialNetwork.LIKES ? statBySocialNetwork.LIKES.value : 0,
              followers: statBySocialNetwork && statBySocialNetwork.FOLLOWERS ? statBySocialNetwork.FOLLOWERS.value : 0,
              click: statBySocialNetwork && statBySocialNetwork.CLICKS ? statBySocialNetwork.CLICKS.value : 0,
              views: statBySocialNetwork && statBySocialNetwork.VIEWS ? statBySocialNetwork.VIEWS.value : 0,
              shares: statBySocialNetwork && statBySocialNetwork.SHARES ? statBySocialNetwork.SHARES.value : 0,
              comments: statBySocialNetwork && statBySocialNetwork.COMMENTS ? statBySocialNetwork.COMMENTS.value : 0,
            },
          } as IT.TotalStats;
        });
      }
      playerStatsBySocialNetwork = {
        ...playerStatsBySocialNetwork,
        [player.player.playerAccount.playerId!!]: statsBySocialNetwork,
      };
    });

    const playerWithStatsResponse: IT.PlayerWithStats[] = playersList.map(player => {
      return {
        info: player.player.playerAccount,
        photoUrl: player.player.playerAccount.photoUrl,
        email: player.player.playerAccount.email,
        countPromptReceived: player.player.promptActivity?.countPromptReceived,
        countPromptPosted: player.player.promptActivity?.countPromptPosted,
        likes: player.statistics && player.statistics.LIKES ? player.statistics.LIKES.value : 0,
        followers: player.statistics && player.statistics.FOLLOWERS ? player.statistics.FOLLOWERS.value : 0,
        click: player.statistics && player.statistics.CLICKS ? player.statistics.CLICKS.value : 0,
        views: player.statistics && player.statistics.VIEWS ? player.statistics.VIEWS.value : 0,
        shares: player.statistics && player.statistics.SHARES ? player.statistics.SHARES.value : 0,
        comments: player.statistics && player.statistics.COMMENTS ? player.statistics.COMMENTS.value : 0,
        statsBySocialNetwork: playerStatsBySocialNetwork[player.player.playerAccount.playerId!!],
        branchNames: player.player.branchNames,
        regionNames: player.player.regionNames,
        costCenterNames: player.player.costCenterNames,
      } as IT.PlayerWithStats;
    });

    setLoadingPlayers(false);
    setPlayersProcessList(() => playerWithStatsResponse);
  };

  const onClickNext = async () => {
    if (CoachSignUpStep[userContext!.signUpStep!] < CoachSignUpStep.COACH_DASHBOARD) {
      setUserContext({
        ...userContext!,
        signUpStep: CoachSignUpStep[CoachSignUpStep.COACH_DASHBOARD],
      });
    }
    if (!finishStep(userContext?.signUpStep)) {
      history.push(URLS.coach.profile);
    }
  };

  const exportPlayers = () => {
    let removeHeaders: string[] | undefined;

    if (!displayAdminSettings.costCenter) {
      removeHeaders = ["cost_center_names"];
    }
    const data: IT.ExportPlayerObjectDashboard[] = exportPlayerStatsList(
      playersProcessList,
      socialNetworksSelected,
      removeHeaders
    );
    if (data.length > 0) {
      exportPlayerToCsv(
        "Leaderboard",
        data,
        displayAdminSettings.costCenter ? LEADERBOARD_FIELDS : LEADERBOARD_FIELDS_BASE
      );
    }
  };

  const exportButtonLabel = useMemo(() => {
    if (loadingLeaderboard) {
      if (leaderboardProgress) {
        return `Loading (${leaderboardProgress} %)...`;
      } else {
        return "Loading...";
      }
    } else {
      return "Export";
    }
  }, [loadingLeaderboard, leaderboardProgress]);

  const leaderBoardSection = (): JSX.Element => {
    return (
      <Grid className="coachBaseContainer">
        <Grid.Row columns={4} className={"leaderBoardHeader"}>
          <Grid.Column>
            <Header as="h2">{formatMessage({ ...descriptors[DashboardType.leaderBoard] })}</Header>
          </Grid.Column>
          <Grid.Column floated="right" className={"exportSection"}>
            <Popup
              content={"Export your leaderboard"}
              trigger={
                <Button
                  data-elm-id={`dashboardExportPlayersBtn`}
                  className={"primary exportIcon rounded"}
                  icon
                  onClick={exportPlayers}
                  disabled={loadingLeaderboard}
                >
                  {exportButtonLabel}
                </Button>
              }
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row columns={1} className={"leaderBoardContainer"}>
          <Grid.Column className={"leftPadding"}>
            <LocalInfinitySearch
              refreshData={preparePlayerList}
              refreshSocialNetworks={setSocialNetworksSelected}
              content={
                <PlayersListWithStats
                  playerResult={playersProcessList}
                  availableSocialNetworks={socialNetworksSelected}
                />
              }
              searchFunction={PlayersServices.searchSubscribedPlayersWithStatsDashboard}
              onLoadingChange={setLoadingLeaderboard}
              onProgressChange={setLeaderboardProgress}
              searchAttribute={"player.playerAccount.email"}
              showSortBy={false}
              filterCondition={(element, searchTerm) => {
                const lowerTerm = searchTerm.toLowerCase().trim();
                const fullName =
                  get(element, "player.playerAccount.firstName", "").toLowerCase() +
                  " " +
                  get(element, "player.playerAccount.lastName", "").toLowerCase();
                return (
                  get(element, "player.playerAccount.email", "").includes(searchTerm) || fullName.includes(lowerTerm)
                );
              }}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  };

  const showVideo = (): JSX.Element => {
    return (
      <Grid.Column>
        <Header as="h6">{formatMessage({ ...descriptors[DashboardType.watchMessage] })}</Header>
        {config && (
          <object data={`https://www.youtube.com/embed/${config.dashboardYoutubeId}`} width="100%" height="550" />
        )}
      </Grid.Column>
    );
  };

  return (
    <div className={"whiteContainer"}>
      <SideBar history={history} readyToContinue={true} />
      <Grid className={"dashboardContent"} columns={1}>
        <Grid columns={1}>
          <TitleSection
            title={formatMessage(
              { ...descriptors[DashboardType.welcome] },
              { firstname: userContext ? userContext!.profile.firstName : "" }
            )}
            ready={currentStep ? currentStep > CoachSignUpStep[CoachSignUpStep.COACH_FINISH_SIGN_UP] : false}
            showNextOption={currentStep ? currentStep === CoachSignUpStep[CoachSignUpStep.COACH_FINISH_SIGN_UP] : true}
            currentStep={CoachSignUpStep.COACH_DASHBOARD}
            nextButtonSize={10}
            handleNextAction={onClickNext}
          />
          {!finishStep(userContext?.signUpStep) ? (
            showVideo()
          ) : (
            <Statistics
              coachId={userContext!!.coach!!.id}
              withReadPermission={withReadPermission}
              currentStep={currentStep}
            />
          )}
          {loadingPlayers ? <Loader active size="large" /> : leaderBoardSection()}
        </Grid>
      </Grid>
    </div>
  );
};

export const Dashboard = withRouter(DashboardFC);
