import { useIntl } from "react-intl";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { Button, Dimmer, DropdownItemProps, Grid, Header, Loader } from "semantic-ui-react";
import { get } from "lodash";

import { URLS } from "../../../utils";

import { useInterval } from "./hooks";
import { ControlSection } from "./components/ControlsSection";
import { RecordActionSection } from "./components/RecordActionsSection";

import { descriptors, RecordPageType } from "./descriptors";
import { ScriptTextArea } from "../../../styling/baseStyle";
import "./styles.scss";
import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import ReactPlayer from "react-player";
import { Media, PricePlanTier } from "../../../interfaces";
import Webcam from "react-webcam";
import { ProjectFileUploader } from "../../../utils/projectFileUploader";
import { ConfigContext } from "../../../contexts/appContexts";
import { AppContext } from "../../../providers";
import { ConfirmationModal } from "../../../components";
import { PostModal } from "./components/PostModal";
import { DestinationChoicesModal } from "./components/DestinationChoicesModal";
import { MediaServices } from "../../../services/MediaServices";
import fixWebmDuration from "webm-duration-fix";
import { VideoPageModal } from "../VideoPageModal";

interface RecordProps {
  title: string;
}

type Props = RouteComponentProps;

const dataElmId = "recordVideoContainer";

const RecordVideoFC: React.FC<Props> = ({ history, location }) => {
  const { formatMessage } = useIntl();
  const { firebase } = useContext(ConfigContext);
  const { userContext } = useContext(AppContext);
  const playerId = useMemo(() => {
    return userContext?.player?.id;
  }, [userContext?.player?.id]);

  const [recordingState, setRecordingState] = useState<"toStart" | "Paused" | "Recording" | "Done">("toStart");
  const [speed, setSpeed] = useState<number>(0.4);
  const [textSize, setTextSize] = useState<number>(24);
  const [camera, setCamera] = useState<string | undefined>(undefined);
  const [confirmingCamera, setConfirmingCamera] = useState<string | undefined>(undefined);
  const [microphone, setMicrophone] = useState<string | undefined>(undefined);
  const [confirmingMicrophone, setConfirmingMicrophone] = useState<string | undefined>(undefined);
  const [cameraOptions, setCameraOptions] = useState<DropdownItemProps[]>([]);
  const [microphoneOptions, setMicrophoneOptions] = useState<DropdownItemProps[]>([]);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [showPostModal, setShowPostModal] = useState(false);
  const [showVideoPageModal, setShowVideoPageModal] = useState(false);
  const [showChoicesModal, setShowChoicesModal] = useState(false);
  const [selectedDestination, setSelectedDestination] = useState<"videoProject" | "post" | "videoPage">();
  const [recordedMedia, setRecordedMedia] = useState<Media>();

  const subscriptionType: string = get(userContext, "subscriptions[0].pricePlan.tier", "");
  const isSocialCoachPlus = subscriptionType === PricePlanTier.PLAYER_PLUS;

  // Camera
  const webcamRef = useRef<Webcam>(null);
  const [previewUrl, setPreviewUrl] = useState<string | undefined>(undefined);
  const [recorder, setRecorder] = useState<MediaRecorder | undefined>(undefined);
  const [mountingNewWebcam, setMountingNewWebcam] = useState(false);
  const [chunks, setChunks] = useState<Blob[]>([]);
  const [recordedBlob, setRecordedBlob] = useState<Blob | undefined>(undefined);
  const [dataReady, setDataReady] = useState(true);
  const [counter, setCounter] = useState<number>(0);
  const [devices, setDevices] = useState<MediaDeviceInfo[]>([]);

  // Scroll
  const scrollToRef = useRef<HTMLTextAreaElement>(null);

  // Settings
  const [withPermissions, setWithPermissions] = useState<boolean | undefined>(undefined);
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingLabel, setLoadingLabel] = useState<string>("Uploading");
  const [loadingProgress, setLoadingProgress] = useState<number | undefined>(undefined);
  const [aspectRatio, setAspectRatio] = useState(9 / 16);

  const purpose: string = get(location, "state.purpose", "");
  const initialScript: string = get(location, "state.script", "");
  const [scriptMessage, setScriptMessage] = useState(initialScript);
  const [showScript, setShowScript] = useState(initialScript.length > 0);
  const scriptId = get(location, "state.id", "");

  const isVertical = useMemo(() => {
    return aspectRatio == 9 / 16;
  }, [aspectRatio]);

  const isRecording = useMemo(() => {
    return recordingState === "Recording";
  }, [recordingState]);

  const showPreview = useMemo(() => {
    return previewUrl && previewUrl.length > 0 && !isRecording && dataReady && recordingState !== "toStart";
  }, [previewUrl, isRecording, dataReady, recordingState]);

  const hideWebcam = useMemo(() => {
    return !withPermissions || !camera || !microphone || showPreview;
  }, [camera, microphone, withPermissions, showPreview]);

  const showVideoLoader = useMemo(() => {
    return mountingNewWebcam || (hideWebcam && !showPreview && withPermissions !== false);
  }, [hideWebcam, showPreview, withPermissions, mountingNewWebcam]);

  useInterval(() => {
    if (isRecording) {
      setCounter(prevCount => prevCount + 1);
    }
  }, 1000);

  useInterval(() => {
    const currentRef = scrollToRef?.current;
    if (isRecording && currentRef) {
      currentRef.scrollTop = currentRef.scrollTop + 1;
    }
  }, speed);

  useEffect(() => {
    const videoDevices = devices
      .filter(({ kind }) => kind === "videoinput")
      .map(element => {
        return {
          key: element.deviceId,
          text: element.label,
          value: element.deviceId,
        } as DropdownItemProps;
      });

    if (videoDevices.length > 0 && !camera) {
      setCamera(`${videoDevices[0].value}`);
    }

    setCameraOptions(videoDevices);

    const audios = devices
      .filter(({ kind }) => kind === "audioinput")
      .map(element => {
        return {
          key: element.deviceId,
          text: element.label,
          value: element.deviceId,
        } as DropdownItemProps;
      });

    setMicrophoneOptions(audios);
    if (audios.length > 0 && !microphone) {
      setMicrophone(`${audios[0].value}`);
    }
  }, [devices, setCamera, setMicrophone]);

  const refreshDevices = useCallback(() => {
    navigator.mediaDevices.enumerateDevices().then(setDevices);
  }, [setDevices]);

  const confirmStartOver = () => {
    setConfirmingMicrophone(undefined);
    setConfirmingCamera(undefined);
    setShowConfirmationModal(true);
  };

  const onStartOver = () => {
    window.location.reload();
    // setRecordingState("toStart");
    // setRecordedMedia(undefined);
    // setCounter(0);
    // setChunks([]);
    // setRecordedBlob(undefined);
    // setPreviewUrl(undefined);
    // const currentRef = scrollToRef?.current;
    // if (currentRef) {
    //   currentRef.scrollTop = 0;
    // }
  };

  const subscription = useCallback(
    (response: BlobEvent) => {
      const { data } = response;
      if (data.size > 0) {
        setDataReady(false);
        setPreviewUrl(undefined);
        setChunks(chunks => chunks.concat(data));
      }
    },
    [setDataReady, setPreviewUrl, setChunks]
  );

  const newRecorder = () => {
    const newRecorder = new MediaRecorder(webcamRef?.current?.stream!!, {
      mimeType,
      audioBitsPerSecond: 128000,
      videoBitsPerSecond: 3500000,
    });
    setRecorder(newRecorder);
    newRecorder.addEventListener("dataavailable", subscription);
    return newRecorder;
  };

  useEffect(() => {
    if (chunks.length > 0) {
      const blob = new Blob(chunks, { type: defaultVideoType });
      const url = URL.createObjectURL(blob);
      setPreviewUrl(url);
      setRecordedBlob(blob);
      setDataReady(true);
    } else {
      setRecordedBlob(undefined);
      setPreviewUrl(undefined);
    }
  }, [chunks]);

  const onStartRecording = () => {
    setCounter(0);
    setRecordedMedia(undefined);
    newRecorder().start();
    setRecordingState("Recording");
  };

  const onPauseRecording = () => {
    setDataReady(false);
    recorder?.pause();
    setRecordingState("Paused");
    recorder?.requestData();
  };

  const onResumeRecording = () => {
    setRecordedMedia(undefined);
    if (recorder && recorder.state == "paused") {
      recorder.resume();
    } else {
      newRecorder().start();
    }
    setRecordingState("Recording");
  };

  function RecordTitleSection({ title }: RecordProps) {
    return (
      <Grid.Row className="titleSection">
        <Header as="h2">{title}</Header>
      </Grid.Row>
    );
  }

  function VideoTitleSection({ title }: RecordProps) {
    return (
      <Grid.Row className="titleSection videoTitleHeader">
        <Header as="h2">{title}</Header>
      </Grid.Row>
    );
  }

  const goToVideoProject = () => {
    history.push(URLS.player.newVideoProject, { preloadMedia: recordedMedia, scriptId });
  };

  const goToScriptLibrary = () => {
    history.push(URLS.player.libraryScripts);
  };

  const goToPost = () => {
    setShowPostModal(true);
  };

  const goToVideoPage = () => {
    setShowVideoPageModal(true);
  };

  const backAction = (): void => {
    if (isRecording) {
      if (window.confirm(formatMessage({ ...descriptors[RecordPageType.confirmationMessage] }))) {
        history.goBack();
      }
    } else {
      history.goBack();
    }
  };

  const remountWebcam = () => {
    setMountingNewWebcam(true);
    setTimeout(() => {
      setMountingNewWebcam(false);
    }, 100);
  };

  const confirmCamera = (deviceId: string) => {
    if (deviceId) {
      if (recordingState !== "toStart") {
        setConfirmingCamera(deviceId);
        setShowConfirmationModal(true);
      } else {
        updateCamera(deviceId);
      }
    }
  };

  const updateCamera = (deviceId: string) => {
    setConfirmingCamera(undefined);
    if (deviceId) {
      setRecorder(undefined);
      setCamera(deviceId);
      remountWebcam();
      onStartOver();
    }
  };

  const confirmMic = (deviceId: string) => {
    if (deviceId) {
      if (recordingState !== "toStart") {
        setConfirmingMicrophone(deviceId);
        setShowConfirmationModal(true);
      } else {
        updateMic(deviceId);
      }
    }
  };

  const updateMic = (deviceId: string) => {
    setConfirmingMicrophone(undefined);
    if (deviceId) {
      setRecorder(undefined);
      setMicrophone(deviceId);
      remountWebcam();
      onStartOver();
    }
  };

  const heights = [2160, 1920, 1080, 1024, 960, 720, 560];

  const videoConstraints = useMemo(() => {
    return {
      facingMode: "user",
      deviceId: camera,
      advanced: heights.map(height => {
        return {
          width: { exact: height * aspectRatio },
          height: { exact: height },
        };
      }),
    };
  }, [camera, aspectRatio]);

  const audioConstraints = useMemo(() => {
    return { deviceId: microphone };
  }, [microphone]);

  const webcamLoading = () => {
    return <Loader active={true} />;
  };

  const defaultVideoType = !!window["safari"] ? "video/mp4" : "video/webm";
  const codecs = !!window["safari"] ? "codecs=avc1" : "codecs=h264";
  const mimeType = `${defaultVideoType};${codecs}`;

  useEffect(() => {
    if (!loading && selectedDestination) {
      switch (selectedDestination) {
        case "videoProject":
          goToVideoProject();
          break;
        case "post":
          goToPost();
          break;
        case "videoPage":
          goToVideoPage();
          break;
      }
    }
  }, [loading, selectedDestination]);

  const onDoneRecording = async () => {
    setRecordingState("Done");

    if (!recordedMedia) {
      const uploader = new ProjectFileUploader("teleprompter", playerId!.toString(), firebase);
      let videoType = defaultVideoType;
      let ext = defaultVideoType.split("/")[1];
      setLoading(true);
      setLoadingProgress(0);
      setLoadingLabel("Uploading");

      const blobToUpload = ext == "webm" ? await fixWebmDuration(recordedBlob!) : recordedBlob;
      const media: Media = {
        id: 0,
        sortOrder: 0,
        category: "VIDEO",
        fileName: `teleprompter_${new Date().getTime()}`,
        uri: previewUrl || "",
        type: videoType,
        extension: ext,
        imageFile: blobToUpload,
      };

      const uploaded = await uploader.prepareMedia([media], (_, progress) => {
        setLoadingProgress(Math.trunc(progress * 100));
      });

      if (ext === "webm") {
        // do in background while they make a choice
        transcode(uploaded[0]).then(media => {
          setRecordedMedia(media);
          setLoading(false);
        });
      } else {
        setRecordedMedia(uploaded[0]);
        setLoading(false);
      }
    }

    if (purpose == "post" || purpose == "videoProject" || purpose == "videoPage") setSelectedDestination(purpose);
    else setShowChoicesModal(true);
  };

  const transcode = async (media: Media) => {
    try {
      setLoadingProgress(undefined);
      setLoadingLabel("Processing media. This may take a minute...");
      let job = await MediaServices.transcode({ mediaUrl: media.uri });
      const jobId = job.jobName;
      while (job.jobStatus !== "SUCCEEDED") {
        await new Promise(r => setTimeout(r, 2000));
        job = await MediaServices.getJobStatus(jobId);
      }

      return {
        ...media,
        uri: job.downloadLink,
        extension: "mp4",
        type: "video/mp4",
      } as Media;
    } catch (e) {
      console.error("Error transcoding:", e);
      throw e;
    }
  };

  const webCam = () => {
    return (
      <Webcam
        className={hideWebcam ? "hiddenWebcam" : undefined}
        audio={true}
        muted={true}
        ref={webcamRef}
        disablePictureInPicture={true}
        mirrored={true}
        imageSmoothing={true}
        forceScreenshotSourceSize={true}
        screenshotFormat={"image/webp"}
        screenshotQuality={1}
        videoConstraints={videoConstraints}
        audioConstraints={audioConstraints}
        onUserMediaError={() => {
          setWithPermissions(false);
          setMountingNewWebcam(false);
        }}
        onUserMedia={() => {
          setWithPermissions(true);
          setMountingNewWebcam(false);
          refreshDevices();
        }}
      />
    );
  };

  const previewPlayer = useMemo(() => {
    return (
      <ReactPlayer
        url={previewUrl}
        width={"auto"}
        height={isVertical ? "560px" : "200px"}
        className={"previewVideo"}
        controls
        playsinline
      />
    );
  }, [previewUrl, isVertical]);

  return (
    <div className={"teleprompterPage"}>
      <Grid>
        {/* Headers */}
        <Grid.Row centered>
          <Grid.Column width={4}>
            <Button secondary data-elm-id={`${dataElmId}BackBtn`} onClick={backAction}>
              {formatMessage({ ...descriptors[RecordPageType.backBtn] })}
            </Button>
          </Grid.Column>

          <RecordActionSection
            recordingState={recordingState}
            disabled={!withPermissions || (!!hideWebcam && !showPreview)}
            doneDisabled={!dataReady}
            startHandler={onStartRecording}
            pauseHandler={onPauseRecording}
            resumeHandler={onResumeRecording}
            doneHandler={onDoneRecording}
            startOverHandler={confirmStartOver}
            secondsRecorded={counter}
          />
        </Grid.Row>

        {/* Controls, script and video */}
        <Grid.Row centered>
          {/* Controls */}
          <Grid.Column width={4}>
            <Grid className="recordSectionGrid">
              <RecordTitleSection title={formatMessage({ ...descriptors[RecordPageType.controlsTitle] })} />
              <ControlSection
                onChangeSpeed={setSpeed}
                onChangeTextSize={setTextSize}
                onChangeCamera={confirmCamera}
                onChangeMicrophone={confirmMic}
                aspectRatio={aspectRatio}
                cameraOptions={cameraOptions}
                microphoneOptions={microphoneOptions}
                selectedCamera={camera}
                selectedMicrophone={microphone}
                disableCameraChanges={isRecording}
                canChangeAspectRatio={isRecording || counter > 0}
                onAspectRatioChange={setAspectRatio}
              />
            </Grid>
          </Grid.Column>

          {/* Script */}
          <Grid.Column width={6}>
            <Grid className="recordSectionGrid">
              <RecordTitleSection title={formatMessage({ ...descriptors[RecordPageType.scriptTitle] })} />
              <Grid.Row stretched>
                {showScript && (
                  <ScriptTextArea
                    ref={scrollToRef}
                    fontSize={textSize}
                    key={"scriptText"}
                    data-elm-id={`${dataElmId}scriptTeleprompterText`}
                    id={"scriptText"}
                    value={scriptMessage}
                    onChange={e => setScriptMessage(e.target.value)}
                  />
                )}
                {!showScript && (
                  <div className={"scriptPlaceholder"}>
                    <Button secondary onClick={goToScriptLibrary}>
                      Choose a script
                    </Button>
                    <Button secondary onClick={() => setShowScript(true)}>
                      Write your own script
                    </Button>
                  </div>
                )}
              </Grid.Row>
            </Grid>
          </Grid.Column>

          {/* Video */}
          <Grid.Column width={4}>
            <Grid className="recordSectionGrid">
              <VideoTitleSection title={showPreview ? "Preview Video" : "Live Video"} />
              <Grid.Row>
                <div className={"webcamContainer " + (isVertical ? "vertical" : "horizontal")}>
                  {showPreview && previewPlayer}
                  {showVideoLoader && webcamLoading()}
                  {mountingNewWebcam ? undefined : webCam()}
                </div>
                {withPermissions === false && (
                  <Header as="h2" className="permissionError">
                    {formatMessage({ ...descriptors[RecordPageType.permissionsError] })}
                  </Header>
                )}
              </Grid.Row>
            </Grid>
          </Grid.Column>
        </Grid.Row>
      </Grid>
      {loading && (
        <Dimmer active>
          <Loader indeterminate>
            {loadingLabel} {loadingProgress ? `${loadingProgress}%...` : ""}
          </Loader>
        </Dimmer>
      )}
      {showPostModal && (
        <PostModal
          opened={showPostModal}
          closeHandler={() => setShowPostModal(false)}
          media={recordedMedia}
          secondsRecorded={counter}
        />
      )}
      <VideoPageModal
        opened={showVideoPageModal}
        closeHandler={() => setShowVideoPageModal(false)}
        media={recordedMedia}
      />
      <DestinationChoicesModal
        open={showChoicesModal}
        hasSCPlus={isSocialCoachPlus}
        okHandler={choice => {
          setShowChoicesModal(false);
          setSelectedDestination(choice);
        }}
        onClose={() => setShowChoicesModal(false)}
      />
      <ConfirmationModal
        title={"Warning! Are you sure?"}
        message={"This will restart the recording session, and lose all recorded progress."}
        openConfirmationModal={showConfirmationModal}
        onClose={() => setShowConfirmationModal(false)}
        okHandler={() => {
          setShowConfirmationModal(false);
          if (confirmingCamera) {
            updateCamera(confirmingCamera);
          } else if (confirmingMicrophone) {
            updateMic(confirmingMicrophone);
          } else {
            onStartOver();
          }
        }}
        rejectHandler={() => {
          setShowConfirmationModal(false);
        }}
      />
    </div>
  );
};

export const RecordVideoPage = withRouter(RecordVideoFC);
