import * as React from "react";
import { Grid, Header, Image } from "semantic-ui-react";

import { Svg } from "../../assets";
import { PlayerAccount, VideoCatalystStatus } from "../../interfaces";
import { fullNameFrom } from "../../utils";

import "./styles.scss";

interface Props {
  playerInfo?: PlayerAccount;
  showPhone?: boolean;
  videoCatalystStatus?: VideoCatalystStatus;
  industryRole?: string;
}

const PlayerInfoSectionFC: React.FC<Props> = ({ playerInfo, showPhone, videoCatalystStatus, industryRole }) => {
  const creditsUsedLabel = () => {
    if (!videoCatalystStatus || !playerInfo) return null;

    let { creditsUsed, creditsRemaining, unlimitedCredits } = videoCatalystStatus;
    creditsUsed = creditsUsed || 0;
    creditsRemaining = creditsRemaining || 0;
    let totalCredits = unlimitedCredits ? "∞" : creditsUsed + creditsRemaining;

    return `${creditsUsed}/${totalCredits} credits used`;
  };
  return (
    <Grid.Row className={"containerPlayerInfoSection"}>
      <Image circular wrapped size="tiny" src={playerInfo?.photoUrl || Svg.noImageuser} />
      <div className="smallPaddingLeft">
        <Header as="p" textAlign="left" className="noMargin noPadding title">
          {playerInfo ? fullNameFrom(playerInfo!) : ""}
        </Header>
        {industryRole && (
          <Header as="p" textAlign="left" className="noMargin noPadding">
            {industryRole}
          </Header>
        )}
        <Header as="p" textAlign="left" className="noMargin noPadding">
          {playerInfo ? playerInfo.email : ""}
        </Header>
        {showPhone && (
          <Header as="p" textAlign="left" className="noMargin noPadding">
            {playerInfo?.phone || ""}
          </Header>
        )}
        {videoCatalystStatus && (
          <Header as="p" textAlign="left" className="noMargin noPadding">
            {creditsUsedLabel()}
          </Header>
        )}
      </div>
    </Grid.Row>
  );
};

export const PlayerInfoSection = PlayerInfoSectionFC;
