import * as React from "react";
import { RouteComponentProps, withRouter } from "react-router";
import { FormattedMessage, useIntl } from "react-intl";
import { Dropdown, DropdownItemProps, DropdownProps, Grid, Message } from "semantic-ui-react";

import { ConfirmationModal, PageActionProps, SocialNetworksSection } from "../../../components";
import {
  ClientNotes,
  Editor,
  Media,
  Post,
  UploadProgressMap,
  VideoPreferences,
  VideoProject,
  VideoProjectDetail,
  VideoProjectMessage,
  VideoProjectStatus,
  VideoProjectUpdateRequest,
  VideoScript,
} from "../../../interfaces";
import { AppContext } from "../../../providers";

import { URLS, capitalizeFormatter, validatePendingAck, urlWithFormat } from "../../../utils";
import { UsersService, VideoProjectServices } from "../../../services";

import { VideoProjectInQueueForm } from "./components/VideoProjectForm";
import { DuplicateVideoProjectModal } from "./components/DuplicateVideoProjectModal";
import { VideoProjectPageType, descriptors } from "./descriptors";
import { validateMediasRequiremenst } from "./validations";

import { CustomBox, CustomColor } from "../../../styling/baseStyle";
import "./styles.scss";
import { VideoProjectBubbleDetails } from "./components/BubbleDetails";
import { useSocialNetworks } from "../../../reducers";
import { useMemo } from "react";
import { ProjectFileUploader } from "../../../utils/projectFileUploader";
import { ConfigContext } from "../../../contexts/appContexts";
import { UploadProgressModal } from "../../../components/UploadProgressModal";
import { buildPreferenceOptions, StylePreferencesOptions } from "../../../utils/projectHelpers";
import moment from "moment";
import { VideoScriptModal } from "./components/VideoScriptModal";
import { Svg } from "../../../assets";
import { ClientNotesComponent } from "../../../components/Forms/EditorVideoProject/components/ClientNotesComponent";
import { Page } from "../../../components/Page";

type Props = RouteComponentProps;

const VideoProjectFC: React.FC<Props> = ({ history, match: { params } }) => {
  const { userContext } = React.useContext(AppContext);
  const { firebase } = React.useContext(ConfigContext);
  const { formatMessage } = useIntl();
  const [openArchiveModal, setOpenArchiveModal] = React.useState<boolean>(false);
  const [openDuplicateModal, setOpenDuplicateModal] = React.useState<boolean>(false);

  const [loading, setLoading] = React.useState<boolean>(false);
  const [uploadingFiles, setUploadingFiles] = React.useState(false);
  const [uploadProgressMap, setUploadProgressMap] = React.useState<UploadProgressMap>();
  const [formErrorMessages, setFormErrorMessages] = React.useState<string[] | undefined>(undefined);

  const [videoProject, setVideoProject] = React.useState<VideoProjectDetail | undefined>(undefined);
  const [editable, setEditable] = React.useState<boolean>(false);
  const [post, setPost] = React.useState<Post | undefined>(undefined);
  const [videoProjectMessages, setVideoProjectMessages] = React.useState<VideoProjectMessage[] | undefined>(undefined);
  const [needsAck, setNeedsAck] = React.useState<boolean>(false);
  const [playerNotes, setPlayerNotes] = React.useState<ClientNotes | undefined>(videoProject?.clientNotes);
  const [coachNotes, setCoachNotes] = React.useState<ClientNotes | undefined>(videoProject?.coachClientNotes);

  const [videoPreferences, setVideoPreferences] = React.useState<VideoPreferences | undefined>(undefined);
  const [preferencesInfo, setPreferencesInfo] = React.useState<StylePreferencesOptions[]>([]);
  const [videoScript, setVideoScript] = React.useState<VideoScript | undefined>(undefined);
  const [openVideoScriptModal, setOpenVideoScriptModal] = React.useState<boolean>(false);
  const [loadingEditors, setLoadingEditors] = React.useState<boolean>(true);
  const [editors, setEditors] = React.useState<Editor[]>([]);

  const readyToEdit = videoProject?.status === VideoProjectStatus.SUBMITTED_FOR_EDITING;
  // Messages
  const titleMessage: string = formatMessage({
    ...descriptors[VideoProjectPageType.title],
  });

  const postSnIds = useMemo(() => {
    return post?.socialNetworks?.filter(sn => sn.status === "POSTED").map(sn => sn.socialNetworkId);
  }, [post?.socialNetworks]);

  const postSnUrls = useMemo(() => {
    let snUrls = {};

    post?.socialNetworks?.map(sn => {
      if (sn.externalPostUrl && sn.socialNetworkId !== "FB") {
        snUrls = {
          ...snUrls,
          [sn.socialNetworkId]: urlWithFormat(sn.externalPostUrl!.replace("[", "").replace("]", "")),
        };
      } else {
        snUrls = {
          ...snUrls,
          [sn.socialNetworkId]: sn.externalPostUrl!,
        };
      }
    });
    return snUrls;
  }, [post?.socialNetworks]);

  const { sns } = useSocialNetworks();

  React.useEffect(() => {
    if (params["id"]) {
      setLoading(true);
      VideoProjectServices.get(params["id"])
        .then(vp => {
          setVideoProject(vp);
          setPost(vp.post);
          setPlayerNotes(vp.clientNotes);
          setCoachNotes(vp.coachClientNotes);
          setEditable(!vp.archived && vp.status !== VideoProjectStatus.POSTED && !!vp.post);
          setVideoProjectMessages(vp.messages);
          setNeedsAck(validatePendingAck(vp.ackList, "EDITOR"));
          setVideoPreferences(vp.preferences);
          setVideoScript(vp.videoScript);
          getEditors(vp);
        })
        .catch(() => {
          alert("Error getting video project info");
          history.goBack();
        })
        .finally(() => {
          setLoading(false);
        });
      VideoProjectServices.acknowledgeMessage(params["id"]).catch(e => {
        console.error("acknowledgeMessage error", e);
      });
    }
  }, [params["id"]]);

  const getErrors = (): string[] | undefined => {
    const errors: string[] = [];
    const validMedias = validateMediasRequiremenst(videoProject?.editedMedia);

    if (!validMedias) {
      errors.push(
        formatMessage({
          ...descriptors[VideoProjectPageType.wrongSpecificationsMessage],
        })
      );
    }

    if (
      videoProject?.editedMedia !== undefined &&
      videoProject?.editedMedia.media !== undefined &&
      videoProject?.editedMedia.media.length > 0
    ) {
      videoProject?.editedMedia?.media.forEach(media => {
        if (!media.validAspectRatio && media.validAspectRatio !== undefined) {
          errors.push(
            formatMessage(
              {
                ...descriptors[VideoProjectPageType.wrongAspectRatioMessage],
              },
              { type: media.type.split("/")[0], typeAfterDot: capitalizeFormatter(media.type.split("/")[0]) }
            )
          );
        }

        if (media.errors?.wrongBitrate) {
          errors.push(
            formatMessage({
              ...descriptors[VideoProjectPageType.wrongBitrateMessage],
            })
          );
        }
        if (media.errors?.wrongLength) {
          errors.push(
            formatMessage({
              ...descriptors[VideoProjectPageType.wrongDurationoMessage],
            })
          );
        }
        if (media.errors?.wrongSize) {
          errors.push(
            formatMessage(
              {
                ...descriptors[VideoProjectPageType.wrongSizeMessage],
              },
              { type: media.type.split("/")[0], typeAfterDot: capitalizeFormatter(media.type.split("/")[0]) }
            )
          );
        }
      });
    }

    return errors.length > 0 ? errors : undefined;
  };

  React.useEffect(() => {
    setFormErrorMessages(() => getErrors());
  }, [videoProject]);

  const updateVideoProject = async (): Promise<any> => {
    if (
      videoProject &&
      videoProject.editedMedia &&
      videoProject.editedMedia?.media &&
      videoProject.editedMedia?.media.length > 0
    ) {
      const { editedMedia, playerId, coachId, preferredPostDate } = videoProject;
      const updated: any = videoProject;

      const socialNetworkMessages = videoProject!!.socialNetworks
        ? videoProject!!.socialNetworks!!.map((key: string) => {
            const sn = {
              code: key,
            };
            return sn;
          })
        : [];

      const uploader = new ProjectFileUploader("videoProject", playerId.toString(), firebase);
      const uploadMap: UploadProgressMap = {};
      editedMedia.media.forEach((mediaObj: Media) => {
        uploadMap[mediaObj.id] = { progress: 0, task: undefined };
      });
      setUploadProgressMap(uploadMap);
      setUploadingFiles(true);
      editedMedia.media = await uploader.prepareMedia(editedMedia.media, (fileId, progress) => {
        setUploadProgressMap(map => {
          return { ...map, [fileId]: { progress, task: undefined } };
        });
      });
      setUploadingFiles(false);
      setLoading(true);

      const request: VideoProjectUpdateRequest = {
        playerId,
        coachId,
        socialNetworkMessages,
        hashtags: updated.hashtags ? updated.hashtags : undefined,
        messageToPost: videoProject!.message,
        status: videoProject.status,
        isAutoPost: videoProject!.isAutoPost,
        preferredPostDate,
        editedMediaGroup: editedMedia,
        allowShortLink: videoProject!.allowShortLink,
        allowComplianceDisclaimer: videoProject!.allowComplianceDisclaimer,
        allowPlayerHashtags: videoProject["allowHashtags"],
      };

      VideoProjectServices.update(request, videoProject!!.videoProjectId!!)
        .then(() => {
          setLoading(false);
          history.replace(URLS.editor.editQueueList);
        })
        .catch(e => {
          setLoading(false);
          setFormErrorMessages([e.substring(0, 100)]);
        });
    } else {
      setFormErrorMessages(() => [
        formatMessage({
          ...descriptors[VideoProjectPageType.attributeRequired],
        }),
      ]);
    }
  };

  const getPageActions = (): PageActionProps[] => {
    if (!videoProject) return [];

    const isLoading = uploadingFiles || loading;
    const actions: PageActionProps[] = [];

    if (editable) {
      const saveButtonTitle = formatMessage({
        ...descriptors[readyToEdit ? VideoProjectPageType.saveBtn : VideoProjectPageType.resubmitBtn],
      });
      actions.push({ text: saveButtonTitle, onClick: updateVideoProject, loading: isLoading });
    }

    actions.push({ text: "Duplicate", icon: "copy", onClick: () => setOpenDuplicateModal(true), disabled: isLoading });

    if (!videoProject.archived) {
      actions.push({ text: "Archive", icon: "archive", onClick: () => setOpenArchiveModal(true), disabled: isLoading });
    }

    return actions;
  };

  const archiveModal = (): JSX.Element => {
    let title = formatMessage({ ...descriptors[VideoProjectPageType.archiveAlertTitle] });
    let okHandler = () => {
      VideoProjectServices.archive(videoProject!.videoProjectId!!)
        .then(() => {
          history.push(URLS.editor.editQueueList);
        })
        .catch((e: any) => {
          alert(e);
        });
    };

    return (
      <ConfirmationModal
        openConfirmationModal={openArchiveModal}
        title={title}
        onClose={() => setOpenArchiveModal(false)}
        rejectHandler={() => setOpenArchiveModal(false)}
        okHandler={okHandler}
      />
    );
  };

  const duplicateModal = (): JSX.Element | undefined => {
    if (!videoProject || !videoProject.videoProjectId) return undefined;

    const onDuplicateHandler = (videoProject: VideoProject) => {
      setOpenDuplicateModal(false);
      history.push(URLS.editor.editVideo.replace(":id", `${videoProject.videoProjectId}`));
    };

    return (
      <DuplicateVideoProjectModal
        open={openDuplicateModal}
        videoProjectId={videoProject.videoProjectId}
        isVideoProjectArchived={!!videoProject.archived}
        onClose={() => setOpenDuplicateModal(false)}
        onDuplicate={onDuplicateHandler}
      />
    );
  };

  const videoScriptModal = (videoScript: VideoScript): JSX.Element => {
    return (
      <VideoScriptModal
        open={openVideoScriptModal}
        videoScript={videoScript}
        onClose={() => setOpenVideoScriptModal(false)}
      />
    );
  };

  const refreshMessages = (): void => {
    VideoProjectServices.get(`${videoProject?.videoProjectId!!}`).then((r: VideoProjectDetail) => {
      setVideoProjectMessages(r.messages);
    });
  };

  React.useEffect(() => {
    setPreferencesInfo(buildPreferenceOptions(videoPreferences));
  }, [videoPreferences]);

  const getEditors = (videoProject: VideoProjectDetail) => {
    const includeUserIds = [];
    const assigneeUserId = videoProject.assigneeUserId;
    const currentUserId = userContext?.account?.userId;
    if (assigneeUserId) includeUserIds.push(assigneeUserId);
    if (currentUserId) includeUserIds.push(currentUserId);

    setLoadingEditors(true);

    UsersService.getEditors(includeUserIds)
      .then(({ content }) => {
        content = content.sort((a: Editor, b: Editor) => {
          if (a.userId === assigneeUserId) return -1;
          if (b.userId === assigneeUserId) return 1;
          if (a.userId === currentUserId) return -1;
          if (b.userId === currentUserId) return 1;
          if ((a.firstName || "") < (b.firstName || "")) return -1;
          if ((a.firstName || "") > (b.firstName || "")) return 1;
          return 0;
        });
        setEditors(content);
      })
      .catch(console.log)
      .finally(() => setLoadingEditors(false));
  };

  const editorOptions = (): DropdownItemProps[] => {
    if (loadingEditors) return [];

    const options: DropdownItemProps[] = [
      {
        key: "unassigned",
        text: "Unassigned",
        value: "unassigned",
      },
    ];
    options.push(
      ...editors.map(editor => {
        let text = [editor.firstName, editor.lastName].filter(Boolean).join(" ");
        if (text.length === 0) text = editor.username || editor.email || `User ${editor.userId}`;
        return {
          key: editor.userId,
          text: text,
          value: editor.userId,
          image: { avatar: true, src: editor.photoUrl || Svg.noImageuser },
        };
      })
    );
    return options;
  };

  const handleAssigneeChange = (_event: any, data: DropdownProps) => {
    let assigneeUserId = data.value === "unassigned" ? null : Number(data.value);

    VideoProjectServices.setAssignee(videoProject!.videoProjectId!, assigneeUserId).catch((e: any) => {
      console.log("Error updating assignee", e);
      alert("Failed to update assignee");
    });
  };

  return (
    <Page title={titleMessage} actions={getPageActions()} loading={loading}>
      {archiveModal()}
      {duplicateModal()}

      <Grid className={"videoProjectFormSection leftPadding"}>
        <Grid.Row>
          <Grid.Column width={9} className={"formContainer"}>
            {videoProject && (
              <VideoProjectInQueueForm
                videoProject={videoProject}
                updatingHandler={setVideoProject}
                pageErrors={formErrorMessages}
              />
            )}
          </Grid.Column>
          <Grid.Column width={6}>
            <Grid className={"videoProjectContainer topContainer"}>
              <label className={"sectionHeader"}>
                <FormattedMessage {...descriptors[VideoProjectPageType.projectInfoLabel]} />
              </label>
              <div className={"projectInfo"}>
                <Grid.Row>
                  Status:
                  <span>{`${videoProject?.statusLabel} ${moment(videoProject?.statusDate).format(
                    "MM/DD/YYYY h:mm a"
                  )}`}</span>
                </Grid.Row>
                <Grid.Row>
                  Created:
                  <span>{moment(videoProject?.createdDate).format("MM/DD/YYYY h:mm a")}</span>
                </Grid.Row>
                <Grid.Row>
                  Assignee:
                  <Dropdown
                    value={videoProject?.assigneeUserId}
                    loading={loadingEditors}
                    options={editorOptions()}
                    selectOnNavigation={false}
                    onChange={handleAssigneeChange}
                  />
                </Grid.Row>
                {videoScript && (
                  <Grid.Row>
                    Script:
                    <span className={"link"} onClick={() => setOpenVideoScriptModal(true)}>
                      {videoScript.title}
                    </span>
                    {videoScriptModal(videoScript)}
                  </Grid.Row>
                )}
                {postSnIds && postSnIds.length > 0 && (
                  <Grid.Row>
                    <SocialNetworksSection actives={postSnIds} links={postSnUrls} allSocialNetworks={sns} />
                  </Grid.Row>
                )}
              </div>
            </Grid>

            <Grid.Row>
              <ClientNotesComponent
                playerId={videoProject?.playerId}
                coachId={videoProject?.coachId}
                playerNotes={playerNotes}
                coachNotes={coachNotes}
                onUpdate={(pNotes, cNotes) => {
                  setPlayerNotes(pNotes);
                  setCoachNotes(cNotes);
                }}
              />
            </Grid.Row>

            <Grid.Row>
              <Grid className={"videoProjectContainer"}>
                <label className={"sectionHeader"}>
                  <FormattedMessage {...descriptors[VideoProjectPageType.preferencesLabel]} />
                </label>
                <Grid.Row className="stylePreferencesRow">
                  {preferencesInfo.map(preference => {
                    return (
                      <p className="noPadding">
                        {`\u2022 ${preference.key}`}
                        {preference.colors?.map(color => {
                          return (
                            <CustomBox>
                              <CustomColor fill={color} />
                              {color.toUpperCase()}
                            </CustomBox>
                          );
                        })}
                      </p>
                    );
                  })}
                </Grid.Row>
              </Grid>
            </Grid.Row>

            {videoProjectMessages && (
              <VideoProjectBubbleDetails
                onSuccess={refreshMessages}
                videoProjectId={videoProject?.videoProjectId!!}
                submitBtnLabel={"Add Message"}
                messages={videoProjectMessages}
                needsAck={needsAck}
              />
            )}
          </Grid.Column>
        </Grid.Row>
      </Grid>
      <UploadProgressModal open={uploadingFiles} progressMap={uploadProgressMap} />
    </Page>
  );
};

export const EditorVideoProjectPage = withRouter(VideoProjectFC);
