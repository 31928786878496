import { Media, MediaGroup } from "../interfaces";
import { MediaServices } from "../services/MediaServices";

export async function createMediaFromUrl(url: string, filenamePrefix: string): Promise<MediaGroup> {
  const uploadedFile = await MediaServices.upload({
    mediaUrl: url,
    destPath: filenamePrefix,
  });
  const mediaCategory = uploadedFile?.type?.includes("video") ? "VIDEO" : "IMAGE";
  const media: Media = {
    id: -1,
    sortOrder: 0,
    category: mediaCategory,
    fileName: uploadedFile.fileName,
    uri: uploadedFile.url,
    type: uploadedFile.type,
    extension: uploadedFile.ext,
  };

  return {
    type: mediaCategory,
    media: [media],
  };
}

/**
 * A helper function to integrate react-beautiful-dnd
 *
 * @param list the list being reordered
 * @param startIndex the 'source' parameter from react-beautiful-dnd
 * @param endIndex the 'destination' parameter from react-beautiful-dnd
 */
export function reorder(list: any[], startIndex: number, endIndex: number): any[] {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
}
