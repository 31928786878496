import * as React from "react";
import { get } from "lodash";
import { Formik } from "formik";

import { VideoScriptDetail } from "../../../../../components";
import { VideoScript } from "../../../../../interfaces";

import { validationSchema } from "./validations";
import { AppContext } from "../../../../../providers";

export interface OwnProps {
  videoScript?: VideoScript;
  pageErrors?: string[];

  disabled?: boolean;
  updatingHandler: (values: any) => void;
  validationHandler: (isValid: boolean) => void;
}

type Props = OwnProps;

const VideoScriptFormFC: React.FC<Props> = ({ videoScript, disabled = false, updatingHandler, validationHandler }) => {
  const { userContext } = React.useContext(AppContext);

  return (
    <Formik
      validateOnBlur={false}
      validateOnChange={true}
      validateOnMount={true}
      validationSchema={validationSchema}
      initialValues={{
        videoScriptId: get(videoScript, "videoScriptId", null),
        title: get(videoScript, "title", ""),
        content: get(videoScript, "content", ""),
        categories: videoScript?.categories?.map(c => c.name) || [],
        industryRoles: videoScript?.industryRoles?.map(ir => ir.name) || [],
        coachId: userContext?.coach?.id || get(videoScript, "coachId", -1),
      }}
      onSubmit={async () => {
        // Action removed because the client wants
        // to use TitleSection action to save the prompt
      }}
    >
      {({ values, errors, isSubmitting, setFieldValue }) => {
        const errorMessages = Object.values(errors) as string[];
        updatingHandler(values);
        validationHandler(errorMessages.length === 0);
        return (
          <VideoScriptDetail
            messages={{
              error: errorMessages,
            }}
            isSubmitting={isSubmitting}
            handleSetFieldValue={setFieldValue}
            disabled={disabled}
            values={values}
          />
        );
      }}
    </Formik>
  );
};

export const VideoScriptForm = VideoScriptFormFC;
