import * as React from "react";
import { Grid } from "semantic-ui-react";

import { MenuTitleSection } from "../../../../../components";
import { SocialGrid, WhiteContainer } from "../../../../../styling/baseStyle";

import "./styles.scss";
import { URLS } from "../../../../../utils";
import { VideoPagesList } from "../../../../../components/Lists/VideoPagesList";
import { CatalystType, descriptors } from "../PlayerVideoCatalyst/descriptors";

interface Props {
  playerId: number;
  coachId: string;
}

const PlayerVideoPagesFC: React.FC<Props> = ({ playerId, coachId }) => {
  return (
    <WhiteContainer>
      <div className={"playerVideoPages"}>
        <Grid.Row className={"menuBaseContainer"}>
          <Grid.Column>
            <VideoPagesList redirectUrl={URLS.player.videoScript} playerId={playerId} />
          </Grid.Column>
        </Grid.Row>
      </div>
    </WhiteContainer>
  );
};

export const PlayerVideoPages = PlayerVideoPagesFC;
