import * as React from "react";
import { Icon } from "semantic-ui-react";

import { useMemo, useState } from "react";
import { AppContext } from "../../../../../providers";
import { PlayersServices } from "../../../../../services";
import usePlayerSettings from "../../../../../utils/usePlayerSettings";
import { CoachSettings } from "../../../../../interfaces";
import { ChoicesModal } from "../../../../../components/ChoicesModal";
import { RadioCardOption } from "../../../../../components/RadioCardGroup";

interface OwnProps {
  open: boolean;

  hasSCPlus: boolean;
  onClose?: () => void;
  okHandler: (option: "videoProject" | "post" | "videoPage") => void;
}
type Props = OwnProps;

const DestinationChoicesModalFC: React.FC<Props> = ({ open, hasSCPlus, onClose, okHandler }) => {
  const [selectedOption, setSelectedOption] = useState<string | undefined>(hasSCPlus ? "videoProject" : "post");
  const { userContext } = React.useContext(AppContext);
  const playerId = userContext?.player?.id;
  const { hasSetting } = usePlayerSettings(playerId);
  const hasMVP = useMemo(() => {
    return hasSetting(CoachSettings.PLAYER_MVP_ACCESS);
  }, [hasSetting]);

  const options: RadioCardOption[] = useMemo(() => {
    const list = [
      {
        key: "post",
        title: "Ready to post",
        subTitle: "No edits needed",
        description: "Share your video directly to social media. Hit 'Next' to customize and schedule your post.",
      },
      {
        key: "videoProject",
        title: "Submit for editing",
        subTitle: "Professionally enhance your video",
        description:
          "We'll refine your video with captions, music, and more. Click 'Next' to customize your posting preferences.",
        disabled: !hasSCPlus,
      },
    ];

    if (hasMVP) {
      list.push({
        key: "videoPage",
        title: "Create video page",
        subTitle: "Share everywhere with one link",
        description:
          "Generate a dedicated page for your video for sharing effortlessly via message, email, or direct link.",
      });
    }

    return list;
  }, [hasSCPlus, hasMVP]);

  const addUpgradeLead = () => {
    if (playerId) PlayersServices.addPlayerUpgradeLead(playerId);
  };

  const warningComponent = () => {
    return (
      <>
        <Icon name={"warning sign"} color={"orange"} className={"warningIcon"} />
        You are not currently subscribed to SocialCoach+
        <div>
          <a href="https://www.socialcoach.io/features/video-catalyst" target="_blank" onClick={() => addUpgradeLead()}>
            Click here
          </a>{" "}
          to learn more about Video Catalyst.
        </div>
      </>
    );
  };

  return (
    <ChoicesModal
      open={open}
      title={"Post to Social or Edit First?"}
      options={options}
      selectedOption={selectedOption}
      onChange={setSelectedOption}
      onSelect={key => {
        if (key == "videoProject" || key == "post" || key == "videoPage") okHandler(key);
      }}
      disabled={!hasSCPlus && selectedOption === "videoProject"}
      onClose={onClose}
      warningComponent={selectedOption === "videoProject" && !hasSCPlus && warningComponent()}
    />
  );
};

export const DestinationChoicesModal = DestinationChoicesModalFC;
