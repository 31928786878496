import styled from "styled-components";
import { Grid, Card } from "semantic-ui-react";
import { Svg } from "../../../assets";

const defaultImage = Svg.PromptPlaceholder;

const thumbnailSize = (thumbnail?: string): string => {
  if (thumbnail) {
    return `url("${thumbnail}") no-repeat center top`;
  }

  return `url(${defaultImage}) no-repeat center center`;
};
export const VideoProjectEditorCardStyle = styled(Card)({
  background: "#fafafa !important",
  borderRadius: "6.3px !important",
  border: "1px solid #d4d4d4 !important",
  boxShadow: `none !important`,
  width: "150px !important",
  display: "inline-block",
});
export const VideoProjectEditorCardContentStyle = styled(Card.Content)({
  padding: "10px !important",
});
export const VideoProjectHeader = styled(VideoProjectEditorCardStyle.Header)({
  overflow: "hidden",
  whiteSpace: "nowrap",
  textOverflow: "ellipsis",
});
export const ThumbnailPreview = styled(Grid.Column)`
  width: 100%;
  height: 185px;
  border-radius: 6px 6px 0 0;
  background: ${(props: any) => thumbnailSize(props.thumbnailurl)};
  background-size: ${(props: any) => (props.thumbnailurl ? "cover" : "inherit")};
  background-color: #eaeaf0;
`;
