import { useCallback, useEffect, useState } from "react";
import { SettingsServices } from "../services";
import { CoachSettings, PlayerAppSetting } from "../interfaces";

const usePlayerSettings = (playerId: number | undefined) => {
  const [playerAppSettings, setPlayerAppSettings] = useState<PlayerAppSetting | undefined>(undefined);

  const hasSetting = useCallback(
    (setting: CoachSettings): boolean | undefined => {
      return playerAppSettings?.settings?.find(s => s.appSettingId == setting)?.status == "ACTIVE";
    },
    [playerAppSettings]
  );

  useEffect(() => {
    if (playerId) {
      SettingsServices.getAppSettingsByPlayerId(playerId).then(setPlayerAppSettings);
    }
  }, [playerId]);

  return { hasSetting };
};

export default usePlayerSettings;
