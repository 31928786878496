import * as React from "react";
import * as DTO from "../../../interfaces/dtos";
import { RouteComponentProps } from "react-router-dom";
import { Button, Grid } from "semantic-ui-react";

import { TitleSection, AdminSideBar, SideBar } from "../../../components";

import { WhiteContainer } from "../../../styling/baseStyle";

import "./styles.scss";

import { VideoScriptsServices } from "../../../services";
import { useState } from "react";
import { VideoScriptForm } from "./components/VideoScriptForm";
import { VideoScriptRequest } from "../../../interfaces/dtos";
import { coachWithWritePermission, URLS } from "../../../utils";
import { ScriptDetails } from "./components/VideoScriptDetails";
import { AppContext } from "../../../providers";
import { NotificationModal } from "./components/NotificationModal";

type Props = RouteComponentProps;

const VideoScriptFC: React.FC<Props> = ({ history, match: { params } }) => {
  const { userContext } = React.useContext(AppContext);
  const [videoScript, setVideoScript] = useState<DTO.VideoScript>();
  const [loading, setLoading] = useState(false);
  const [isValid, setIsValid] = useState(true);
  const [showNotificationModal, setShowNotificationModal] = useState(false);
  const [values, setValues] = useState<any>();
  const [savingStatus, setSavingStatus] = useState<string | undefined>();

  const isAdmin = userContext?.account.groups.some(g => g === DTO.UserGroups.ADMIN);
  const withWritePermission = coachWithWritePermission(DTO.WebComponents.MANAGE_SCRIPT_LIBRARY, userContext);
  const isCoach = userContext?.coach !== null;

  React.useEffect(() => {
    const getScript = async (scriptId: number) => {
      const result = await VideoScriptsServices.get(scriptId);
      setVideoScript(result);
    };

    if (params["id"]) {
      getScript(params["id"]).catch(console.error);
    } else {
      setVideoScript({
        coachId: isAdmin ? -1 : userContext?.coach?.id!!,
        title: "",
        content: "",
        categories: [],
        industryRoles: [],
      });
    }
  }, []);

  const deleteScript = async () => {
    if (window.confirm("Are you sure you want to delete this script?")) {
      VideoScriptsServices.delete(videoScript?.videoScriptId!!).then(() => {
        history.push(URLS.videoScripts);
      });
    }
  };

  const saveScript = async () => {
    setLoading(true);
    const request: VideoScriptRequest = {
      coachId: values.coachId,
      title: values.title,
      content: values.content,
      categories: values.categories,
      industryRoles: values.industryRoles,
    };
    if (values?.videoScriptId) {
      await VideoScriptsServices.update(values.videoScriptId, request)
        .catch(e => {
          console.error(e);
          alert("Error saving script");
        })
        .then(updatedScript => {
          if (updatedScript) {
            setVideoScript(updatedScript);
            history.replace(URLS.videoScript.replace(":id", `${updatedScript.videoScriptId}`));
          }
        });
    } else {
      await VideoScriptsServices.create(request)
        .catch(e => {
          console.error(e);
          alert("Error saving script");
        })
        .then(newScript => {
          if (newScript) {
            setVideoScript(newScript);
            history.replace(URLS.videoScript.replace(":id", `${newScript.videoScriptId}`));
          }
        });
    }
    setLoading(false);
    setSavingStatus("Saved!");
    setTimeout(() => {
      setSavingStatus(undefined);
    }, 5000);
  };

  return (
    <WhiteContainer>
      {isAdmin ? <AdminSideBar history={history} /> : <SideBar history={history} />}
      <div className={"hugePadding videoScriptPage"}>
        <TitleSection
          title={"Video Script"}
          subtitle={savingStatus}
          ready={true}
          showNextOption={isAdmin || withWritePermission}
          showLinkOption={isAdmin || withWritePermission}
          customNextLabel={"Save"}
          handleNextAction={saveScript}
          disableButtons={!isValid}
          titleSize={6}
          buttonsSize={9}
          nextButtonSize={5}
          customLinkSection={
            <Grid.Column width={isAdmin || isCoach ? 10 : 8}>
              <Grid columns={isAdmin || isCoach ? 2 : 1}>
                <Grid.Column>
                  {videoScript?.videoScriptId && (
                    <Button
                      type="submit"
                      data-elm-id={"deleteVideoScript"}
                      loading={loading}
                      fluid
                      className={"secondary rounded buttonRight"}
                      onClick={deleteScript}
                    >
                      Delete
                    </Button>
                  )}
                </Grid.Column>
                <Grid.Column>
                  {videoScript?.videoScriptId && isCoach && !isAdmin && (
                    <Button
                      type="submit"
                      data-elm-id={"scriptNotification"}
                      loading={loading}
                      fluid
                      className={"secondary rounded buttonRight noPaddingHorizontal"}
                      onClick={() => setShowNotificationModal(true)}
                    >
                      Send Script Notification
                    </Button>
                  )}
                </Grid.Column>
              </Grid>
            </Grid.Column>
          }
        />
        <Grid columns={2}>
          <Grid.Column>
            <Grid.Row className={"videoScriptFormContainer"}>
              <Grid.Column>
                {videoScript && (
                  <VideoScriptForm
                    videoScript={videoScript}
                    updatingHandler={setValues}
                    validationHandler={setIsValid}
                    disabled={!isAdmin && !withWritePermission}
                  />
                )}
              </Grid.Column>
            </Grid.Row>
          </Grid.Column>

          <Grid.Column>
            <Grid.Row className={"videoScriptFormContainer"}>
              <Grid.Column>
                {videoScript?.videoScriptId && <ScriptDetails videoScriptId={videoScript?.videoScriptId} />}
              </Grid.Column>
            </Grid.Row>
          </Grid.Column>
        </Grid>

        {showNotificationModal && videoScript && (
          <NotificationModal
            opened={showNotificationModal}
            script={videoScript}
            acceptHandler={() => setShowNotificationModal(false)}
            rejectHandler={() => setShowNotificationModal(false)}
          />
        )}
      </div>
    </WhiteContainer>
  );
};

export const VideoScriptPage = VideoScriptFC;
