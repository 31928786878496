import { AddPlayerType } from "./descriptors";
import * as MODEL from "../../../interfaces/models";

export const segmentationFields: MODEL.FormField[] = [
  {
    id: "branch",
    name: "branch",
    type: "text",
    label: "Branch",
    placeholder: AddPlayerType.branch,
    disabled: false,
  },
  {
    id: "state",
    name: "state",
    type: "text",
    label: "State",
    placeholder: AddPlayerType.state,
    disabled: false,
  },
  {
    id: "region",
    name: "region",
    type: "text",
    label: "Region",
    placeholder: AddPlayerType.region,
    disabled: false,
  },
  {
    id: "costCenter",
    name: "costCenter",
    type: "text",
    label: "Cost Center",
    placeholder: AddPlayerType.costCenter,
    disabled: false,
  },
  {
    id: "industryRole",
    name: "industryRole",
    type: "text",
    label: "Industry Role",
    placeholder: AddPlayerType.industryRole,
    disabled: false,
  },
];
