import * as React from "react";
import { Formik } from "formik";
import { get } from "lodash";
import { AddEnterprisePlayer } from "../../../../../components";
import { EnterprisePlayerRequest } from "../../../../../interfaces";

import { validationSchema } from "./validations";
import { InternalPaddingGrid } from "../../../../../styling/baseStyle";

export interface OwnProps {
  planId: number;
  coachId: string;
  disabled: boolean;
  preloadValues?: EnterprisePlayerRequest;
  successNextHandler: (enterprisePlayerRequest: EnterprisePlayerRequest[]) => void;
  formSubmitRef?: any;
}

type Props = OwnProps;

const AddPlayerFormFC: React.FC<Props> = ({
  coachId,
  planId,
  disabled = false,
  preloadValues,
  formSubmitRef,
  successNextHandler,
}) => {
  return (
    <InternalPaddingGrid>
      <Formik
        validateOnBlur={false}
        validateOnChange={false}
        initialValues={{
          firstName: get(preloadValues, "firstName", ""),
          lastName: get(preloadValues, "lastName", ""),
          email: get(preloadValues, "email", ""),
          branch: get(preloadValues, "branchNames[0]", ""),
          state: get(preloadValues, "stateCodes", []),
          region: get(preloadValues, "regionNames[0]", ""),
          complianceDisclaimer: get(preloadValues, "complianceDisclaimer", ""),
          costCenter: get(preloadValues, "costCenterNames[0]", ""),
          industryRole: get(preloadValues, "industryRoleNames[0]", ""),
        }}
        validationSchema={validationSchema}
        onSubmit={async (
          { firstName, lastName, email, branch, region, state, complianceDisclaimer, costCenter, industryRole },
          { setErrors, validateForm }
        ) => {
          try {
            const errors = await validateForm({
              firstName,
              lastName,
              email,
              branch,
              region,
              state,
              complianceDisclaimer,
            });

            if (errors && Object.values(errors).length > 0) {
              setErrors(errors);
            } else {
              const players: EnterprisePlayerRequest[] = [
                {
                  email,
                  firstName,
                  lastName,
                  pricePlanId: planId!,
                  branchNames: branch ? [branch] : undefined,
                  stateCodes: state ? state.filter((s: string) => s !== "SELECT ALL") : undefined,
                  regionNames: region ? [region] : undefined,
                  complianceDisclaimer,
                  costCenterNames: costCenter ? [costCenter] : undefined,
                  industryRoleNames: industryRole ? [industryRole] : undefined,
                },
              ];
              successNextHandler(players);
            }
          } catch (e) {
            setErrors({
              email: `${e}`,
              firstName: undefined,
              lastName: undefined,
              branch: undefined,
              state: undefined,
              region: undefined,
              costCenter: undefined,
              industryRole: undefined,
              complianceDisclaimer: undefined,
            });
          }
        }}
      >
        {({ values, errors, handleChange, handleSubmit, setFieldValue, isSubmitting }) => (
          <AddEnterprisePlayer
            formSubmitRef={formSubmitRef}
            disabled={disabled}
            messages={{
              error: Object.values(errors) as string[],
              info: disabled ? ["You don't have permission for this action"] : [],
            }}
            handleChange={handleChange}
            handleSubmit={handleSubmit}
            handleSetFieldValue={(field, value) => {
              setFieldValue(field, value);
            }}
            coachId={coachId}
            isSubmitting={isSubmitting}
            values={values}
          />
        )}
      </Formik>
    </InternalPaddingGrid>
  );
};

export const AddEnterprisePlayerViewForm = AddPlayerFormFC;
