import * as React from "react";
import { useIntl } from "react-intl";
import { Input, Form, Grid, Message, Header } from "semantic-ui-react";
import { get } from "lodash";

import { descriptors } from "./descriptors";
import { BasicFormProps } from "../../../interfaces";

import "./styles.scss";
import { noSpaces, removeSpecialCharacters } from "../../../utils";
import useAdminCoachSettings from "../../AppSettingsTab/adminSettingsHook";
import { segmentationFields } from "./formFields";
import { SegmentationDropDown } from "./SegmentationDropDown";

interface OwnProps extends BasicFormProps {
  /**
   * Coach Id to get branch, states and regions
   */
  coachId: string;
  /**
   * Form ref to submit form another component
   */
  formRef: any;

  shortLinkBase?: string;
  /**
   * Handle dropdowns changes
   */
  handleSetFieldValue: (field: string, value: any) => void;
}

type Props = OwnProps;

/**
 * Add enterprise player internal form
 */

const EditPlayerFC: React.FC<Props> = ({
  handleSubmit,
  handleSetFieldValue,
  shortLinkBase = "https://",
  formRef,
  coachId,
  messages,
  values,
  isSubmitting = true,
}) => {
  const { displayAdminSettings } = useAdminCoachSettings(coachId);
  const { formatMessage } = useIntl();
  const shortLinkSection: JSX.Element = (
    <Grid.Column className={isSubmitting ? "disabledForm" : ""} floated="right">
      <Header as="h4" className={"permissionTitle"}>
        Short Link Information
      </Header>
      <Form.Group widths="equal">
        <Form.Field>
          <Header as={"h3"}>Web Address</Header>
          <Input
            id={"webAddress"}
            placeholder="Web Address"
            value={values.webAddress}
            onChange={e => {
              handleSetFieldValue(e.currentTarget.id, noSpaces(e.currentTarget.value));
            }}
          />
        </Form.Field>
      </Form.Group>
      <Form.Group widths="equal">
        <Form.Field>
          <Header as={"h3"}>Short Link</Header>
          <Input
            className={"inputWithLabel"}
            label={`${shortLinkBase}`}
            fluid
            id={"slug"}
            data-elm-id="shortLinkInput"
            type={"text"}
            placeholder={"Short Link"}
            onChange={e => {
              handleSetFieldValue(e.currentTarget.id, removeSpecialCharacters(e.currentTarget.value));
            }}
            value={values.slug}
          />
        </Form.Field>
      </Form.Group>
    </Grid.Column>
  );

  const segmentationSection: JSX.Element = (
    <Grid.Column className={isSubmitting ? "disabledForm noPaddingLeft" : "noPaddingLeft"} floated="left">
      <Header as="h4" className={"permissionTitle"}>
        Segmentation
      </Header>
      {segmentationFields
        .filter(e => {
          return (
            displayAdminSettings.costCenter || (displayAdminSettings.costCenter === false && e.id !== "costCenter")
          );
        })
        .map(field => {
          return (
            <SegmentationDropDown
              coachId={coachId}
              id={field.id}
              key={`edit_player_group_${field.id}`}
              label={field.label}
              placeholder={formatMessage({ ...descriptors[field.placeholder] })}
              fieldValue={values[field.id]}
              multiple={field.id === "state"}
              disabled={isSubmitting}
              allowAdditions={!["industryRole", "state"].includes(field.id)}
              handleChange={data => {
                handleSetFieldValue(field.id, data.value);
              }}
            />
          );
        })}
    </Grid.Column>
  );

  const errors = messages && messages.error && messages.error.length > 0;
  const warnings = messages && messages.warn && messages.warn.length > 0;
  const infoMessages = get(messages, "info", []);
  const warningMessages = get(messages, "warn", []);
  const errorMessages = get(messages, "error", []);

  return (
    <Grid columns={16} verticalAlign="middle" stretched container className={"floatedGrid completed"}>
      <Grid.Column width={16}>
        <Form
          ref={formRef}
          onSubmit={handleSubmit}
          size="large"
          error={errors}
          warning={warnings}
          className={"floatedFormBox editPlayerForm"}
        >
          {errorMessages.length > 0 && <Message error header={"Errors:"} list={errorMessages} />}
          {warningMessages.length > 0 && <Message warning header={"Warnings: "} list={warningMessages} />}
          {infoMessages.length > 0 && <Message header={"Info: "} list={infoMessages} />}

          <Grid columns={2}>
            {segmentationSection}
            {(values.slug || values.webAddress || values.shortLinkUpdated) && shortLinkSection}
          </Grid>
        </Form>
      </Grid.Column>
    </Grid>
  );
};

export const EditPlayer = EditPlayerFC;
