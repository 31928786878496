import * as React from "react";
import { RouteComponentProps, withRouter } from "react-router";

import { BrandHeader } from "../../components";
import { BrandResponse, PostWithBrandRequest } from "../../interfaces";
import { AppContext } from "../../providers";
import { BrandServices } from "../../services";
import { cleanBrandInfoInLocalStorage, createMediaFromUrl, setBrandInfoInLocalStorage, URLS } from "../../utils";

import { BrandPostForm, SuccessPage } from "./components";
import "./styles.scss";
import { ConfigContext } from "../../contexts/appContexts";

// NICE TO HAVE: Save steps in local storage to save flow if the user refresh - NTH
// http://localhost:3000/publish?asset=https%3A%2F%2Ffirebasestorage.googleapis.com%2Fv0%2Fb%2Fsocialcoach-prod-d87a5.appspot.com%2Fo%2Fdevelopment%252Fdevelopment%252Fusers%252F1395%252Fprofiles%252Fmain.jpg%3Falt%3Dmedia%26token%3D290069ea-e7f4-421a-b976-8336fd62fe34&brandId=DANI_TEST&returnUrl=http://test.com

interface OwnProps {}

type Props = OwnProps & RouteComponentProps;

const BrandPostPageFC: React.FC<Props> = ({ history, location }) => {
  const { userContext, loading } = React.useContext(AppContext);
  const { firebase: firebaseConfig } = React.useContext(ConfigContext);

  // Properties
  const [brand, setBrand] = React.useState<BrandResponse | undefined>(undefined);
  const [postWithBrandRequest, setPostWithBrandRequest] = React.useState<PostWithBrandRequest | undefined>(undefined);
  const [showSuccessPage, setShowSuccessPage] = React.useState<boolean>(false);

  // Location params
  const searchParams = new URLSearchParams(location.search);
  const assetUrl = searchParams.get("asset");
  const brandId = searchParams.get("brandId")!!;
  const returnUrl = searchParams.get("returnUrl");

  React.useEffect(() => {
    if (userContext === undefined && !loading) {
      setBrandInfoInLocalStorage(assetUrl!!, returnUrl!!, brandId);
      history.push(URLS.login);
    } else {
      cleanBrandInfoInLocalStorage();
    }
  }, [userContext, loading]);

  const loadBrand = async () => {
    try {
      if (brandId !== null) {
        const brand = await BrandServices.get(brandId);
        setBrand(brand);
      }
    } catch (e: any) {
      alert(e.message);
      history.goBack();
    }
  };

  const loadAsset = async () => {
    if (assetUrl) {
      const path = `${firebaseConfig.baseImagesFolder}/users/${userContext?.account.userId}/posts`;
      const mediaGroup = await createMediaFromUrl(assetUrl, path);
      setPostWithBrandRequest({
        mediaGroup,
        messageToPost: "",
        socialNetworkMessages: [],
      });
    }
  };

  React.useEffect(() => {
    if (brandId !== null) {
      loadBrand().catch(console.error);
    }
  }, [brandId]);

  React.useEffect(() => {
    if (brandId !== null) {
      loadAsset().catch(console.error);
    }
  }, [assetUrl]);

  const onSuccess = (isAutoPost: boolean, sendMode: string): void => {
    setShowSuccessPage(true);
    setPostWithBrandRequest(current => {
      return { ...current!!, isAutoPost, sendMode };
    });
  };

  return (
    <>
      {brand && <BrandHeader customUrl={brand.logoUrl} />}
      {postWithBrandRequest && !showSuccessPage && (
        <BrandPostForm
          brandPostRequest={postWithBrandRequest}
          coachId={userContext?.coach?.id}
          playerId={userContext?.player?.id}
          myCoachId={userContext?.subscriptions ? userContext?.subscriptions[0].pricePlan.coachId : undefined}
          brandId={brandId}
          onSuccess={onSuccess}
        />
      )}

      {showSuccessPage && (
        <SuccessPage
          brandName={brand?.name!}
          returnUrl={returnUrl!!}
          isAutoPost={postWithBrandRequest?.isAutoPost}
          sendMode={postWithBrandRequest?.sendMode!!}
        />
      )}
    </>
  );
};

export const BrandPostPage = withRouter(BrandPostPageFC);
