import { defineMessages } from "react-intl";

export enum ProfileInformationType {
  titleSection = "ProfileInformation.titleSection",
  uploadPicture = "ProfileInformation.uploadPicture",
  changePicture = "ProfileInformation.changePicture",
  firstName = "ProfileInformation.firstName",
  lastName = "ProfileInformation.lastName",
  title = "ProfileInformation.title",
  company = "ProfileInformation.company",
  phoneNumber = "ProfileInformation.phoneNumber",
  industryRole = "ProfileInformation.industryRole",
  changePassword = "ProfileInformation.changePassword",
  income = "ProfileContactInformationType.income",
  leads = "ProfileInformation.leads",
  transactions = "ProfileInformation.transactions",
}

export const en = {
  [ProfileInformationType.uploadPicture]: "Upload your photo",
  [ProfileInformationType.changePicture]: "Change your photo",
  [ProfileInformationType.firstName]: "First Name",
  [ProfileInformationType.lastName]: "Last Name",
  [ProfileInformationType.title]: "Title",
  [ProfileInformationType.company]: "Company",
  [ProfileInformationType.phoneNumber]: "Phone Number",
  [ProfileInformationType.industryRole]: "Industry Role",
  [ProfileInformationType.changePassword]: "Change Password",
  [ProfileInformationType.titleSection]: "My Profile",

  [ProfileInformationType.transactions]: "Number of transactions you closed last year",
  [ProfileInformationType.income]: "Amount you make on each transaction",
  [ProfileInformationType.leads]: "Number of leads you need in order to close a transaction",
};

export const descriptors = defineMessages({
  [ProfileInformationType.titleSection]: {
    id: ProfileInformationType.titleSection,
    defaultMessage: en[ProfileInformationType.titleSection],
    description: "",
  },
  [ProfileInformationType.uploadPicture]: {
    id: ProfileInformationType.uploadPicture,
    defaultMessage: en[ProfileInformationType.uploadPicture],
    description: "",
  },
  [ProfileInformationType.changePicture]: {
    id: ProfileInformationType.changePicture,
    defaultMessage: en[ProfileInformationType.changePicture],
    description: "",
  },
  [ProfileInformationType.firstName]: {
    id: ProfileInformationType.firstName,
    defaultMessage: en[ProfileInformationType.firstName],
    description: "firstName text",
  },
  [ProfileInformationType.lastName]: {
    id: ProfileInformationType.lastName,
    defaultMessage: en[ProfileInformationType.lastName],
    description: "lastName text",
  },
  [ProfileInformationType.title]: {
    id: ProfileInformationType.title,
    defaultMessage: en[ProfileInformationType.title],
    description: "title text",
  },
  [ProfileInformationType.company]: {
    id: ProfileInformationType.company,
    defaultMessage: en[ProfileInformationType.company],
    description: "company text",
  },
  [ProfileInformationType.phoneNumber]: {
    id: ProfileInformationType.phoneNumber,
    defaultMessage: en[ProfileInformationType.phoneNumber],
    description: "phoneNumber text",
  },
  [ProfileInformationType.industryRole]: {
    id: ProfileInformationType.industryRole,
    defaultMessage: en[ProfileInformationType.industryRole],
    description: "industryRole text",
  },
  [ProfileInformationType.changePassword]: {
    id: ProfileInformationType.changePassword,
    defaultMessage: en[ProfileInformationType.changePassword],
    description: "changePassword text",
  },
  [ProfileInformationType.income]: {
    id: ProfileInformationType.income,
    defaultMessage: en[ProfileInformationType.income],
    description: "text",
  },
  [ProfileInformationType.leads]: {
    id: ProfileInformationType.leads,
    defaultMessage: en[ProfileInformationType.leads],
    description: "",
  },
  [ProfileInformationType.transactions]: {
    id: ProfileInformationType.transactions,
    defaultMessage: en[ProfileInformationType.transactions],
    description: "",
  },
});
