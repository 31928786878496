import { Popup } from "semantic-ui-react";
import * as React from "react";
import { ContentPageInfo, ContentPageViewStat } from "../../../interfaces";
import "./styles.scss";
import { useMemo } from "react";

interface OwnProps {
  page?: ContentPageInfo;
  trigger: React.ReactNode;
}

type Props = OwnProps;

const VideoPageStatsPopupFC: React.FC<Props> = ({ page, trigger }) => {
  const statCutoff = 10;

  const showCities = useMemo(() => {
    return page?.contentPageViewSummary && page.contentPageViewSummary.cities.length < statCutoff;
  }, [page?.contentPageViewSummary]);

  const showStates = useMemo(() => {
    return (
      page?.contentPageViewSummary &&
      page.contentPageViewSummary.cities.length >= statCutoff &&
      page.contentPageViewSummary.states.length < statCutoff
    );
  }, [page?.contentPageViewSummary]);

  const statSection = (label: string, stats?: ContentPageViewStat[]) => {
    return (
      stats &&
      stats.length > 0 && (
        <>
          <div>
            <b>{label}:</b>
          </div>
          <ul>
            {stats?.map(stat => (
              <li key={`stat-${stat.label}`}>
                {stat.label} - {stat.percentage}%
              </li>
            ))}
          </ul>
        </>
      )
    );
  };

  return (
    <Popup trigger={trigger} className={"videoPageStatsPopup"}>
      <Popup.Header>View Stats</Popup.Header>
      <Popup.Content>
        {showCities && statSection("Cities", page?.contentPageViewSummary?.cities)}
        {showStates && statSection("States", page?.contentPageViewSummary?.states)}
        {statSection("Devices", page?.contentPageViewSummary?.deviceClasses)}
        {statSection("Watch Percentage", page?.contentPageViewSummary?.watchPercentages)}
      </Popup.Content>
    </Popup>
  );
};

export const VideoPageStatsPopup = VideoPageStatsPopupFC;
