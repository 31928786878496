import * as React from "react";
import { Title, Meta } from "react-head";

interface Props {
  title?: string;
  pageUrl?: string;
  type?: string;
  description?: string;
  imageUrl?: string;
  imageType?: string;
  imageWidth?: number;
  imageHeight?: number;
  videoUrl?: string;
  videoType?: string;
  videoWidth?: number;
  videoHeight?: number;
  videoDuration?: number;
  metaAppId?: string;
}

const MetaTags: React.FC<Props> = ({
  title,
  description,
  type = "website",
  pageUrl,
  imageUrl,
  imageType,
  imageWidth,
  imageHeight,
  videoUrl,
  videoType,
  videoWidth,
  videoHeight,
  videoDuration,
  metaAppId,
}) => {
  return (
    <>
      <Title>{title}</Title>
      <Meta name="title" content={title} />
      <Meta property="og:title" content={title} />
      <Meta property="twitter:title" content={title} />
      <Meta property="og:site_name" content="SocialCoach" />
      <Meta property="twitter:site" content="SocialCoach" />
      <Meta property="og:type" content={type} />
      {pageUrl && (
        <>
          <Meta property="og:url" content={pageUrl} />
          <Meta property="twitter:url" content={pageUrl} />
        </>
      )}
      {description && (
        <>
          <Meta name="description" content={description} />
          <Meta property="og:description" content={description} />
          <Meta property="twitter:description" content={description} />
        </>
      )}
      {imageUrl && (
        <>
          <Meta property="og:image" content={imageUrl} />
          <Meta property="twitter:image" content={imageUrl} />
          <Meta property="og:image:alt" content={title} />
        </>
      )}
      {imageType && (
        <>
          <Meta property="og:image:type" content={`${imageType}`} />
        </>
      )}
      {imageWidth && (
        <>
          <Meta property="og:image:width" content={`${imageWidth}`} />
        </>
      )}
      {imageHeight && (
        <>
          <Meta property="og:image:height" content={`${imageHeight}`} />
        </>
      )}
      {videoUrl && (
        <>
          <Meta property="og:video" content={videoUrl} />
          <Meta property="og:video:secure_url" content={videoUrl} />
          <Meta property="og:video:alt" content={title} />
        </>
      )}
      {videoType && (
        <>
          <Meta property="og:video:type" content={videoType} />
        </>
      )}
      {videoWidth && <Meta property="og:video:width" content={`${videoWidth}`} />}
      {videoHeight && <Meta property="og:video:height" content={`${videoHeight}`} />}
      {videoDuration && <Meta property="og:video:duration" content={`${videoDuration}`} />}
      {metaAppId && <Meta property="fb:app_id" content={metaAppId} />}
    </>
  );
};

export default MetaTags;
