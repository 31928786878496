import * as Yup from "yup";
import { phoneRegExp, validAge } from "../../../utils";
import { passwordValidations } from "../../../validations";

export const validationSchema = Yup.object().shape({
  firstName: Yup.string().trim().required("First Name is required"),
  lastName: Yup.string().trim().required("Last Name is required"),
  email: Yup.string().email("Email is invalid").required("Email is required"),
  phoneNumber: Yup.string().matches(phoneRegExp, "Phone number is not valid").required("Phone number is required"),
  ...passwordValidations,
});

export const validationUpdateSchema = Yup.object().shape({
  firstName: Yup.string().required("First Name is required"),
  lastName: Yup.string().required("Last Name is required"),
  email: Yup.string().email("Email is invalid").required("Email is required"),
  phoneNumber: Yup.string().matches(phoneRegExp, "Phone number is not valid").required("Phone number is required"),
});
