import * as React from "react";
import { Formik } from "formik";
import { RouteComponentProps, withRouter } from "react-router";
import { useCookies } from "react-cookie";
import { get } from "lodash";

import { SignUp } from "../../../components";
import { SocialCoachSessionService } from "../../../services";
import { validationSchema, validationUpdateSchema } from "./validations";
import { UserRegistered } from "../../../interfaces/dtos";
import { URLS } from "../../../utils";
import { AppContext } from "../../../providers";

export interface OwnProps {
  firstName?: string;
  lastName?: string;
  email?: string;
  phoneNumber?: string;
  password?: string;
  confirmPassword?: string;

  userRegistered?: UserRegistered;

  successHandler: (user: UserRegistered) => void;
}

type Props = OwnProps & RouteComponentProps;

const IntegratedRegister: React.FC<Props> = ({ userRegistered, successHandler, history }) => {
  const { setUserContext, userContext } = React.useContext(AppContext);
  const [cookies, setCookie, removeCookie] = useCookies(["user-sc-register"]);

  const toSignIn = (): void => {
    setUserContext();
    history.push(URLS.login);
  };

  React.useEffect(() => {
    if (!userRegistered?.profile && !userContext) {
      if (cookies) {
        removeCookie("user-sc-register", { path: "/" });
      }
    }
  }, []);

  return (
    <div>
      <Formik
        validateOnBlur={false}
        validateOnChange={true}
        initialValues={{
          firstName: get(userRegistered, "profile.firstName", ""),
          lastName: get(userRegistered, "profile.lastName", ""),
          email: get(userRegistered, "account.email", "") as string,
          phoneNumber: get(userRegistered, "profile.phone", ""),
          password: get(userRegistered, "account.username", ""),
          confirmPassword: get(userRegistered, "account.username", ""),
        }}
        validationSchema={userRegistered?.profile ? validationUpdateSchema : validationSchema}
        onSubmit={async (
          { firstName, lastName, email, password, confirmPassword, phoneNumber },
          { setSubmitting, setErrors, validateForm }
        ) => {
          try {
            const errors = await validateForm({
              firstName,
              lastName,
              email,
              password,
              confirmPassword,
              phoneNumber,
            });

            if (errors && Object.values(errors).length > 0) {
              setErrors(errors);
            } else {
              let response;
              if (userRegistered?.account) {
                response = await SocialCoachSessionService.updateDuringRegister(
                  {
                    phone: phoneNumber!,
                    firstName: firstName!,
                    lastName: lastName!,
                    email: email!,
                    password: password!,
                    userRole: "COACH",
                  },
                  userRegistered.account.userId
                );
              } else {
                response = await SocialCoachSessionService.createUser({
                  password: password!,
                  phone: phoneNumber!,
                  firstName: firstName!,
                  lastName: lastName!,
                  email: email!,
                  userRole: "COACH",
                });
              }
              response = { ...response, account: response.user };

              successHandler(response);
              history.push(URLS.register + "/1");
            }
          } catch (e) {
            setErrors({
              email: String(e),
              password: undefined,
              firstName: undefined,
              lastName: undefined,
              phoneNumber: undefined,
            });
          }
          setSubmitting(false);
        }}
      >
        {({ values, errors, handleChange, handleSubmit, isSubmitting }) => (
          <SignUp
            messages={{ error: Object.values(errors) as string[] }}
            userRegistered={userRegistered}
            handleChange={handleChange}
            handleSubmit={handleSubmit}
            firstName={values.firstName}
            lastName={values.lastName}
            email={values.email}
            phoneNumber={values.phoneNumber}
            password={values.password}
            confirmPassword={values.confirmPassword}
            isSubmitting={isSubmitting}
            toSignIn={toSignIn}
            values={values}
          />
        )}
      </Formik>
    </div>
  );
};

const RegisterForm = withRouter(IntegratedRegister);

export { RegisterForm };
