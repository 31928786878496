import { CoachSettings } from "../../interfaces";
import * as MODEL from "../../interfaces/models";

export const appSettings: MODEL.PermissionField[] = [
  {
    id: "stickers",
    name: CoachSettings.CUSTOM_STICKERS,
    label: "Allow Players to edit the images in assigned Prompts",
  },
  {
    id: "customCampaigns",
    name: CoachSettings.CUSTOMIZABLE_RANDOM_CAMPAIGN,
    label: "Allow Players to customize the date/time of Random Campaigns",
  },
  {
    id: "postCustomImages",
    name: CoachSettings.POST_CUSTOM_IMAGES,
    label: "Allow Players to use custom images",
  },
  {
    id: "postCustomMessage",
    name: CoachSettings.POST_CUSTOM_MESSAGE,
    label: "Allow Players to customize the text in Prompts",
  },
  {
    id: "playerStickers",
    name: CoachSettings.PLAYER_STICKERS,
    label: "Allow Players to use Stickers",
  },
  {
    id: "postInFB",
    name: CoachSettings.POST_IN_FB,
    label: "Allow Players to post to Facebook",
  },
  {
    id: "postInIG",
    name: CoachSettings.POST_IN_IG,
    label: "Allow Players to post to Instagram",
  },
  {
    id: "postInTW",
    name: CoachSettings.POST_IN_TW,
    label: "Allow Players to post to X",
  },
  {
    id: "postInLI",
    name: CoachSettings.POST_IN_LI,
    label: "Allow Players to post to LinkedIn",
  },
  {
    id: "postInTT",
    name: CoachSettings.POST_IN_TT,
    label: "Allow Players to post to TikTok",
  },
  {
    id: "postInIGStory",
    name: CoachSettings.POST_IN_IG_STORY,
    label: "Allow Players to post to Instagram Stories",
  },
  {
    id: "campaignDefaultSn",
    name: CoachSettings.CAMPAIGN_DEFAULT_SN,
    label: "Allow Players to edit social networks of Campaigns",
  },
  {
    id: "playerHashtags",
    name: CoachSettings.PLAYER_HASHTAG,
    label: "Allow Players to use Custom Hashtags",
  },
  {
    id: "playerCustomSticker",
    name: CoachSettings.PLAYER_CUSTOM_STICKERS,
    label: "Allow Players to upload their own Custom Stickers",
  },
  {
    id: "playerNotifyFailedPost",
    name: CoachSettings.PLAYER_NOTIFY_FAILED_POST,
    label: "Send notifications to Players for failed posts and social network token expirations",
  },
  {
    id: "summaryReportPlayers",
    name: CoachSettings.SUMMARY_REPORT_PLAYER,
    label: "Send Players monthly performance summary reports",
  },
  {
    id: "recurringOnboardingNotifications",
    name: CoachSettings.RECURRING_ONBOARDING_NOTIFICATIONS,
    label: "Automatically send follow-up welcome emails to pending Players",
  },
  {
    id: "recurringSocialConnectNotifications",
    name: CoachSettings.RECURRING_SOCIAL_CONNECT_NOTIFICATIONS,
    label: "Automatically send reminders for Players to connect social accounts",
  },
  {
    id: "complianceDisclaimer",
    name: CoachSettings.COMPLIANCE_DISCLAIMER,
    label: "Automatically insert Compliance Disclaimer in all Player Posts",
  },
  {
    id: "notifyPlayerVideoProjectEdited",
    name: CoachSettings.NOTIFY_PLAYER_VIDEO_PROJECT_EDITED,
    label: "Get notifications when a video project is edited",
  },
  {
    id: "playerAccessMVP",
    name: CoachSettings.PLAYER_MVP_ACCESS,
    label: "Allow all players to create Video Pages",
  },
  {
    id: "complianceDisclaimerContentPages",
    name: CoachSettings.COMPLIANCE_DISCLAIMER_CONTENT_PAGES,
    label: "Add Players' Compliance Disclaimers to Video Pages",
  },
];
