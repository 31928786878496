import * as React from "react";
import { Route, Switch, RouteComponentProps } from "react-router-dom";
import { URLS } from "./utils";
import { useAnalyticsPageView } from "./utils/gtag";
import { RouteType, Types } from "@socialcoach/services";
import {
  MarketingSite,
  ChangePasswordForm,
  Dashboard,
  Home,
  LoginPage,
  LandingPreview,
  NotFoundPage,
  PlayerSignUpPage,
  PricePlan,
  PromptPage,
  Players,
  Questionnaire,
  ResendPage,
  SignUpProfile,
  SignUpPage,
  TermsPage,
  VerificationPage,
  AdminDashboard,
  PlayerLoginPage,
  AdminProspectList,
  CompliancePage,
  Library,
  CampaignPage,
  Campaigns,
  EditCampaignPage,
  AdminSubCoaches,
  CreateSubCoach,
  EditSubCoach,
  EditPlayer,
  FirstLoginPage,
  SetPlayerPicture,
  FirstQuestionsPage,
  FirstWebAddressPage,
  FirstSocialNetworksPage,
  AlertsSection,
  DigestEmailPage,
  SettingsSection,
  AddPlayer,
  PaymentInfo,
  FirstCongratsPage,
  PlayerSettings,
  AdminAllPlayersList,
  PostPage,
  VideoCatalyst,
  EditorVideoProjectPage,
  EditorEditingQueueList,
  VideoProjectPage,
  VideoProjectDetailsPage,
  EmbeddedPlayerSocialAccounts,
  BrandPostPage,
  VideoScriptsPage,
  VideoScriptPage,
  PlayerVideoProjectPage,
  PlayerVideoScriptPage,
  RecordVideoPage,
  ContentPageEditor,
} from "./containers";

import { SocialHeader } from "./components";
import { AppContextProvider, AppContext, AppUserContext } from "./providers";
import { CoachPermission, ConfigProperties, PricePlanTier, UserGroups, WebComponents } from "./interfaces";
import MetaTags from "./components/MetaTags";
import { availablePermission, redirectToPlayerStep } from "./utils";

import { SCSessionRoute } from "./routers/SessionRoute";
import { PrivacyPage } from "./containers/PrivacyPolicy";
import useAdminCoachSettings from "./components/AppSettingsTab/adminSettingsHook";

import Footer from "./components/Footer";
import { PlayerDashboard } from "./containers/Players/Dashboard";
import { PlayerLibrary } from "./containers/Players/Library";
import { get } from "lodash";
import { useMemo } from "react";

interface PageProps extends RouteComponentProps {
  title?: string;
  includeHeader: boolean;
  includeFooter: boolean;
  Component: any;
  config?: ConfigProperties;
  type?: string;
  isPlayer?: boolean;
}

const Page = (props: PageProps) => {
  const { pathname, search } = props.location;
  useAnalyticsPageView(pathname + search);

  const pageTitle = useMemo(() => {
    return props.title ? `${props.title} - SocialCoach` : "SocialCoach";
  }, [props.title]);

  return (
    <React.Fragment>
      <MetaTags title={pageTitle} />
      {props.includeHeader && <SocialHeader title={props.title} />}
      <props.Component {...props} />
      {props.includeFooter && <Footer />}
    </React.Fragment>
  );
};

Page.defaultProps = {
  includeHeader: true,
  includeFooter: true,
  title: "",
};

export default (config: ConfigProperties) => {
  const basicUser = (type?: string): boolean => {
    if (!type) {
      return true;
    }
    return type === "BASIC";
  };

  const userDefaultRedirectUrl = (userContext?: AppUserContext): string => {
    const isEditor = userContext?.account?.scGroups?.some(g => g === UserGroups.EDITOR);

    return isEditor
      ? URLS.editor.editQueueList
      : userContext?.coach
      ? URLS.coach.dashboard
      : redirectToPlayerStep(userContext?.signUpStep!);
  };

  const validateCoachRedirectUrl = (userContext?: AppUserContext): string => {
    const isEditor = userContext?.account?.scGroups?.some(g => g === UserGroups.EDITOR);
    const isAdmin = userContext?.account.groups.some(g => g === UserGroups.ADMIN);

    return isEditor ? URLS.editor.editQueueList : isAdmin ? URLS.admin.dashboard : URLS.player.dashboard;
  };

  const validatePublicPageRedirectUrl = (userContext?: AppUserContext): string => {
    const isAdmin = userContext?.account.groups.some(g => g === UserGroups.ADMIN);
    const isCoachOrPlayer = userContext?.account.scGroups?.some(g => g === UserGroups.PLAYER || g === UserGroups.COACH);

    return isCoachOrPlayer
      ? userDefaultRedirectUrl(userContext!!)
      : isAdmin
      ? URLS.admin.dashboard
      : URLS.editor.editQueueList;
  };
  const validateEditorRedirectUrl = (userContext?: AppUserContext): string => {
    const isAdmin = userContext?.account.groups.some(g => g === UserGroups.ADMIN);

    return isAdmin
      ? URLS.admin.dashboard
      : userContext?.coach?.id !== null
      ? URLS.coach.dashboard
      : URLS.player.dashboard;
  };

  const validatePlayerRedirectUrl = (userContext?: AppUserContext): string => {
    const isEditor = userContext?.account?.scGroups?.some(g => g === UserGroups.EDITOR);
    const isAdmin = userContext?.account.groups.some(g => g === UserGroups.ADMIN);

    return isEditor ? URLS.editor.editQueueList : isAdmin ? URLS.admin.dashboard : URLS.login;
  };

  const subCoachHasPermissions = (
    isSubCoach?: boolean,
    userPermissions?: CoachPermission[],
    requestedPermission?: string
  ): boolean => {
    if (userPermissions && isSubCoach) {
      const current = userPermissions.filter(permission => {
        return permission.componentId === requestedPermission;
      });

      if (current.length === 0) {
        return false;
      }
      return availablePermission(current[0]);
    }
    return !isSubCoach!! || true;
  };

  const componentResult = (
    componentName: string,
    Component: any,
    props: any,
    isSubCoach?: boolean,
    userPermissions?: CoachPermission[]
  ) => {
    return subCoachHasPermissions(isSubCoach, userPermissions, componentName) ? (
      <Page {...props} config={config} Component={Component} />
    ) : (
      <Page {...props} config={config} Component={NotFoundPage} />
    );
  };

  const scriptAccessComponentResult = (props: any, Component: any, userContext?: AppUserContext) => {
    const coachId = userContext?.coach
      ? get(userContext, "coach.id", "")
      : get(userContext, "subscriptions[0].pricePlan.coachId", "");

    const { displayAdminSettings, adminSettingLoading } = useAdminCoachSettings(coachId);
    const isAdmin = userContext?.account.groups.some(g => g === UserGroups.ADMIN);
    const subscriptionType = get(userContext, "subscriptions[0].pricePlan.tier", undefined);
    const isSocialCoachPlus = subscriptionType === PricePlanTier.PLAYER_PLUS;
    const isPlayer = !!userContext?.player;

    const hasScriptAccess =
      isAdmin ||
      (!isPlayer && displayAdminSettings.manageScriptLibrary) ||
      (isPlayer && displayAdminSettings.accessScriptLibrary) ||
      adminSettingLoading ||
      (isPlayer && isSocialCoachPlus);

    return hasScriptAccess ? (
      <Page {...props} config={config} Component={Component} />
    ) : (
      <Page {...props} config={config} Component={NotFoundPage} />
    );
  };

  return (
    <AppContextProvider>
      <AppContext.Consumer>
        {({ userContext }) => (
          <Switch>
            {/* ALL USER PAGES */}
            <SCSessionRoute
              routeType={RouteType.PUBLIC_ROUTE}
              exact
              path={URLS.forgotPassword}
              component={(props: RouteComponentProps) => (
                <Page {...props} type="ForgotPassword" includeHeader={false} Component={ResendPage} />
              )}
            />

            <SCSessionRoute
              routeType={RouteType.PUBLIC_ROUTE}
              exact
              path={URLS.changePassword}
              component={(props: RouteComponentProps) => (
                <Page {...props} includeHeader={false} Component={ChangePasswordForm} />
              )}
            />

            {/* ALL USER PAGES END */}

            {/* COACH PAGES */}
            <SCSessionRoute
              routeType={RouteType.PUBLIC_ROUTE}
              redirection={{
                isExternal: false,
                url: validatePublicPageRedirectUrl(userContext),
              }}
              exact
              path={URLS.home}
              component={(props: RouteComponentProps) => (
                <Page {...props} config={config} Component={Home} includeHeader={false} />
              )}
            />
            <SCSessionRoute
              routeType={RouteType.PUBLIC_ROUTE}
              exact
              redirection={{
                isExternal: false,
                url: validatePublicPageRedirectUrl(userContext),
              }}
              path={URLS.login}
              component={(props: RouteComponentProps) => (
                <Page
                  {...props}
                  Component={(pageProps: any) => <LoginPage {...pageProps} group={Types.UserGroups.USER} />}
                  includeHeader={false}
                />
              )}
            />
            <SCSessionRoute
              routeType={RouteType.PUBLIC_ROUTE}
              redirection={{
                isExternal: false,
                url: validatePublicPageRedirectUrl(userContext),
              }}
              exact
              path={URLS.activatePlayerUser}
              component={(props: RouteComponentProps) => (
                <Page {...props} Component={VerificationPage} isPlayer={true} />
              )}
            />
            <SCSessionRoute
              routeType={RouteType.PUBLIC_ROUTE}
              redirection={{
                isExternal: false,
                url: validatePublicPageRedirectUrl(userContext),
              }}
              exact
              path={URLS.activateUser}
              component={(props: RouteComponentProps) => (
                <Page {...props} Component={VerificationPage} includeHeader={false} />
              )}
            />
            <SCSessionRoute
              routeType={RouteType.PUBLIC_ROUTE}
              redirection={{
                isExternal: false,
                url: validatePublicPageRedirectUrl(userContext),
              }}
              exact
              path={URLS.resendEmail}
              component={(props: RouteComponentProps) => <Page {...props} Component={ResendPage} />}
            />

            {/** PLAYER PRIVATE PAGES */}
            <SCSessionRoute
              routeType={RouteType.PRIVATE_ROUTE}
              redirection={{
                isExternal: false,
                url: validatePlayerRedirectUrl(userContext),
              }}
              exact
              path={URLS.player.dashboard}
              reservedGroups={["PLAYER"]}
              component={(props: RouteComponentProps) => <Page {...props} Component={PlayerDashboard} />}
            />

            <SCSessionRoute
              routeType={RouteType.PRIVATE_ROUTE}
              redirection={{
                isExternal: false,
                url: validatePlayerRedirectUrl(userContext),
              }}
              exact
              path={URLS.player.library}
              reservedGroups={["PLAYER"]}
              component={(props: RouteComponentProps) => <Page {...props} Component={PlayerLibrary} />}
            />

            <SCSessionRoute
              routeType={RouteType.PRIVATE_ROUTE}
              redirection={{
                isExternal: false,
                url: validatePlayerRedirectUrl(userContext),
              }}
              exact
              path={URLS.player.firstLogin}
              reservedGroups={["PLAYER"]}
              component={(props: RouteComponentProps) =>
                !!userContext?.player ? (
                  <Page {...props} config={config} Component={FirstLoginPage} />
                ) : (
                  <Page {...props} config={config} Component={NotFoundPage} />
                )
              }
            />

            <SCSessionRoute
              routeType={RouteType.PRIVATE_ROUTE}
              redirection={{
                isExternal: false,
                url: validatePlayerRedirectUrl(userContext),
              }}
              exact
              path={URLS.player.firstCongrats}
              reservedGroups={["PLAYER"]}
              component={(props: RouteComponentProps) =>
                !!userContext?.player ? (
                  <Page {...props} config={config} Component={FirstCongratsPage} />
                ) : (
                  <Page {...props} config={config} Component={NotFoundPage} />
                )
              }
            />

            <SCSessionRoute
              routeType={RouteType.PRIVATE_ROUTE}
              redirection={{
                isExternal: false,
                url: validatePlayerRedirectUrl(userContext),
              }}
              exact
              path={URLS.player.firstPicture}
              reservedGroups={["PLAYER"]}
              component={(props: RouteComponentProps) => <Page {...props} Component={SetPlayerPicture} />}
            />

            <SCSessionRoute
              routeType={RouteType.PRIVATE_ROUTE}
              redirection={{
                isExternal: false,
                url: validatePlayerRedirectUrl(userContext),
              }}
              exact
              path={URLS.player.firstWebAddress}
              reservedGroups={["PLAYER"]}
              component={(props: RouteComponentProps) => <Page {...props} Component={FirstWebAddressPage} />}
            />

            <SCSessionRoute
              routeType={RouteType.PRIVATE_ROUTE}
              redirection={{
                isExternal: false,
                url: validatePlayerRedirectUrl(userContext),
              }}
              exact
              path={URLS.player.firstQuestions}
              reservedGroups={["PLAYER"]}
              component={(props: RouteComponentProps) => <Page {...props} Component={FirstQuestionsPage} />}
            />

            <SCSessionRoute
              routeType={RouteType.PRIVATE_ROUTE}
              redirection={{
                isExternal: false,
                url: validatePlayerRedirectUrl(userContext),
              }}
              exact
              path={URLS.player.firstSocialNetworks}
              reservedGroups={["PLAYER"]}
              component={(props: RouteComponentProps) => <Page {...props} Component={FirstSocialNetworksPage} />}
            />

            <SCSessionRoute
              routeType={RouteType.PRIVATE_ROUTE}
              redirection={{
                isExternal: false,
                url: validatePlayerRedirectUrl(userContext),
              }}
              exact
              path={URLS.player.settings}
              reservedGroups={["PLAYER"]}
              component={(props: RouteComponentProps) => <Page {...props} Component={PlayerSettings} />}
            />

            <SCSessionRoute
              routeType={RouteType.PRIVATE_ROUTE}
              redirection={{
                isExternal: false,
                url: validatePlayerRedirectUrl(userContext),
              }}
              exact
              path={URLS.player.embeddedSocialAccounts}
              reservedGroups={["PLAYER"]}
              component={(props: RouteComponentProps) => (
                <Page {...props} Component={EmbeddedPlayerSocialAccounts} includeHeader={false} includeFooter={false} />
              )}
            />

            <SCSessionRoute
              routeType={RouteType.PRIVATE_ROUTE}
              redirection={{
                isExternal: false,
                url: validatePlayerRedirectUrl(userContext),
              }}
              exact
              path={URLS.player.paymentInfo}
              reservedGroups={["PLAYER"]}
              component={(props: RouteComponentProps) => <Page {...props} Component={PaymentInfo} />}
            />

            <SCSessionRoute
              routeType={RouteType.PRIVATE_ROUTE}
              redirection={{
                isExternal: false,
                url: userContext?.account.groups.includes(Types.UserGroups.USER)
                  ? userDefaultRedirectUrl(userContext)
                  : URLS.admin.login,
              }}
              exact
              path={URLS.player.videoScript}
              reservedGroups={["PLAYER"]}
              component={(props: RouteComponentProps) =>
                scriptAccessComponentResult(props, PlayerVideoScriptPage, userContext)
              }
            />
            <SCSessionRoute
              routeType={RouteType.PRIVATE_ROUTE}
              redirection={{
                isExternal: false,
                url: userContext?.account.groups.includes(Types.UserGroups.USER)
                  ? userDefaultRedirectUrl(userContext)
                  : URLS.admin.login,
              }}
              exact
              path={URLS.player.recordVideo}
              reservedGroups={["PLAYER"]}
              component={(props: RouteComponentProps) =>
                scriptAccessComponentResult({ ...props, includeHeader: false }, RecordVideoPage, userContext)
              }
            />

            <SCSessionRoute
              routeType={RouteType.PRIVATE_ROUTE}
              redirection={{
                isExternal: false,
                url: userContext?.account.groups.includes(Types.UserGroups.USER)
                  ? userDefaultRedirectUrl(userContext)
                  : URLS.admin.login,
              }}
              exact
              path={URLS.player.newVideoScript}
              reservedGroups={["PLAYER"]}
              component={(props: RouteComponentProps) =>
                scriptAccessComponentResult(props, PlayerVideoScriptPage, userContext)
              }
            />

            <SCSessionRoute
              routeType={RouteType.PRIVATE_ROUTE}
              redirection={{
                isExternal: false,
                url: validateCoachRedirectUrl(userContext),
              }}
              path={URLS.player.newVideoProject}
              reservedGroups={["PLAYER"]}
              component={(props: RouteComponentProps) => {
                const subscriptionType = get(userContext, "subscriptions[0].pricePlan.tier", undefined);
                const isSocialCoachPlus = subscriptionType === PricePlanTier.PLAYER_PLUS;

                return isSocialCoachPlus ? (
                  <Page {...props} config={config} Component={PlayerVideoProjectPage} />
                ) : (
                  <Page {...props} config={config} Component={NotFoundPage} />
                );
              }}
            />

            {/** COACH PRIVATE PAGES */}
            <SCSessionRoute
              routeType={RouteType.PRIVATE_ROUTE}
              redirection={{
                isExternal: false,
                url: validateCoachRedirectUrl(userContext),
              }}
              exact
              path={URLS.coach.post}
              reservedGroups={["COACH"]}
              component={(props: RouteComponentProps) => <Page {...props} Component={PostPage} />}
            />
            <SCSessionRoute
              routeType={RouteType.PRIVATE_ROUTE}
              redirection={{
                isExternal: false,
                url: validateCoachRedirectUrl(userContext),
              }}
              exact
              path={URLS.coach.dashboard}
              reservedGroups={["COACH"]}
              component={(props: RouteComponentProps) => <Page {...props} Component={Dashboard} />}
            />

            <SCSessionRoute
              routeType={RouteType.PRIVATE_ROUTE}
              redirection={{
                isExternal: false,
                url: validateCoachRedirectUrl(userContext),
              }}
              exact
              path={URLS.coach.profile}
              reservedGroups={["COACH"]}
              component={(props: RouteComponentProps) =>
                componentResult("PROFILE", SignUpProfile, props, userContext?.isSubCoach, userContext?.permissions)
              }
            />
            <SCSessionRoute
              routeType={RouteType.PRIVATE_ROUTE}
              redirection={{
                isExternal: false,
                url: validateCoachRedirectUrl(userContext),
              }}
              exact
              path={URLS.coach.marketingSite}
              reservedGroups={["COACH"]}
              component={(props: RouteComponentProps) =>
                basicUser(userContext?.coach?.type) ? (
                  componentResult(
                    WebComponents.MARKETING,
                    MarketingSite,
                    props,
                    userContext?.isSubCoach,
                    userContext?.permissions
                  )
                ) : (
                  <Page {...props} config={config} Component={NotFoundPage} />
                )
              }
            />
            <SCSessionRoute
              routeType={RouteType.PRIVATE_ROUTE}
              redirection={{
                isExternal: false,
                url: validateCoachRedirectUrl(userContext),
              }}
              exact
              path={URLS.coach.subCoaches}
              reservedGroups={["COACH"]}
              component={(props: RouteComponentProps) =>
                componentResult(
                  WebComponents.ADMIN,
                  AdminSubCoaches,
                  props,
                  userContext?.isSubCoach,
                  userContext?.permissions
                )
              }
            />
            <SCSessionRoute
              routeType={RouteType.PRIVATE_ROUTE}
              redirection={{
                isExternal: false,
                url: validateCoachRedirectUrl(userContext),
              }}
              exact
              path={URLS.coach.alerts}
              reservedGroups={["COACH"]}
              component={(props: RouteComponentProps) =>
                componentResult(
                  WebComponents.ALERTS,
                  AlertsSection,
                  props,
                  userContext?.isSubCoach,
                  userContext?.permissions
                )
              }
            />
            <SCSessionRoute
              routeType={RouteType.PRIVATE_ROUTE}
              redirection={{
                isExternal: false,
                url: validateCoachRedirectUrl(userContext),
              }}
              exact
              path={URLS.coach.newSubCoach}
              reservedGroups={["COACH"]}
              component={(props: RouteComponentProps) =>
                componentResult(
                  WebComponents.ADMIN,
                  CreateSubCoach,
                  props,
                  userContext?.isSubCoach,
                  userContext?.permissions
                )
              }
            />

            <SCSessionRoute
              routeType={RouteType.PRIVATE_ROUTE}
              redirection={{
                isExternal: false,
                url: validateCoachRedirectUrl(userContext),
              }}
              exact
              path={URLS.coach.newPlayer}
              reservedGroups={["COACH"]}
              component={(props: RouteComponentProps) =>
                componentResult(
                  WebComponents.PLAYERS,
                  AddPlayer,
                  props,
                  userContext?.isSubCoach,
                  userContext?.permissions
                )
              }
            />

            <SCSessionRoute
              routeType={RouteType.PRIVATE_ROUTE}
              redirection={{
                isExternal: false,
                url: validateCoachRedirectUrl(userContext),
              }}
              exact
              path={URLS.coach.editSubCoach}
              reservedGroups={["COACH"]}
              component={(props: RouteComponentProps) =>
                componentResult(
                  WebComponents.ADMIN,
                  EditSubCoach,
                  props,
                  userContext?.isSubCoach,
                  userContext?.permissions
                )
              }
            />

            <SCSessionRoute
              routeType={RouteType.PRIVATE_ROUTE}
              redirection={{
                isExternal: false,
                url: validateCoachRedirectUrl(userContext),
              }}
              exact
              path={URLS.coach.landingPreview}
              reservedGroups={["COACH"]}
              component={(props: RouteComponentProps) =>
                basicUser(userContext?.coach?.type) &&
                subCoachHasPermissions(userContext?.isSubCoach, userContext?.permissions, "MARKETING_SITE") ? (
                  <Page {...props} Component={LandingPreview} includeHeader={false} />
                ) : (
                  <Page {...props} config={config} Component={NotFoundPage} />
                )
              }
            />
            <SCSessionRoute
              routeType={RouteType.PRIVATE_ROUTE}
              redirection={{
                isExternal: false,
                url: validateCoachRedirectUrl(userContext),
              }}
              exact
              path={URLS.coach.pricePlan}
              reservedGroups={["COACH"]}
              component={(props: RouteComponentProps) =>
                basicUser(userContext?.coach?.type) &&
                subCoachHasPermissions(userContext?.isSubCoach, userContext?.permissions, "PRICE_PLAN") ? (
                  <Page {...props} config={config} Component={PricePlan} />
                ) : (
                  <Page {...props} config={config} Component={NotFoundPage} />
                )
              }
            />
            <SCSessionRoute
              routeType={RouteType.PRIVATE_ROUTE}
              redirection={{
                isExternal: false,
                url: validateCoachRedirectUrl(userContext),
              }}
              exact
              path={URLS.coach.questionnaire}
              reservedGroups={["COACH"]}
              component={(props: RouteComponentProps) => <Page {...props} config={config} Component={Questionnaire} />}
            />
            <SCSessionRoute
              routeType={RouteType.PRIVATE_ROUTE}
              redirection={{
                isExternal: false,
                url: validateCoachRedirectUrl(userContext),
              }}
              exact
              path={URLS.coach.library}
              reservedGroups={["COACH"]}
              component={(props: RouteComponentProps) =>
                componentResult("LIBRARY", Library, props, userContext?.isSubCoach, userContext?.permissions)
              }
            />
            <SCSessionRoute
              routeType={RouteType.PRIVATE_ROUTE}
              redirection={{
                isExternal: false,
                url: validateCoachRedirectUrl(userContext),
              }}
              exact
              path={URLS.coach.newCampaigns}
              reservedGroups={["COACH"]}
              component={(props: RouteComponentProps) =>
                componentResult("CAMPAIGNS", CampaignPage, props, userContext?.isSubCoach, userContext?.permissions)
              }
            />
            <SCSessionRoute
              routeType={RouteType.PRIVATE_ROUTE}
              redirection={{
                isExternal: false,
                url: validateCoachRedirectUrl(userContext),
              }}
              exact
              path={URLS.coach.campaigns}
              reservedGroups={["COACH"]}
              component={(props: RouteComponentProps) =>
                componentResult("CAMPAIGNS", Campaigns, props, userContext?.isSubCoach, userContext?.permissions)
              }
            />

            <SCSessionRoute
              routeType={RouteType.PRIVATE_ROUTE}
              redirection={{
                isExternal: false,
                url: validateCoachRedirectUrl(userContext),
              }}
              exact
              path={URLS.coach.videoCatalyst}
              reservedGroups={["COACH"]}
              component={(props: RouteComponentProps) => {
                const { displayAdminSettings, adminSettingLoading } = useAdminCoachSettings(userContext!!.coach!!.id);
                const withPermission = subCoachHasPermissions(
                  userContext?.isSubCoach,
                  userContext?.permissions,
                  "VIDEO_CATALYST"
                );

                return (displayAdminSettings.scPlusTier || adminSettingLoading) && withPermission ? (
                  <Page {...props} config={config} Component={VideoCatalyst} />
                ) : (
                  <Page {...props} config={config} Component={NotFoundPage} />
                );
              }}
            />

            <SCSessionRoute
              routeType={RouteType.PRIVATE_ROUTE}
              redirection={{
                isExternal: false,
                url: validateCoachRedirectUrl(userContext),
              }}
              path={URLS.coach.newVideoProject}
              reservedGroups={["COACH"]}
              component={(props: RouteComponentProps) => {
                const { displayAdminSettings, adminSettingLoading } = useAdminCoachSettings(userContext!!.coach!!.id);
                const withPermission = subCoachHasPermissions(
                  userContext?.isSubCoach,
                  userContext?.permissions,
                  "VIDEO_CATALYST"
                );

                return (displayAdminSettings.scPlusTier || adminSettingLoading) && withPermission ? (
                  <Page {...props} config={config} Component={VideoProjectPage} />
                ) : (
                  <Page {...props} config={config} Component={NotFoundPage} />
                );
              }}
            />

            <SCSessionRoute
              routeType={RouteType.PRIVATE_ROUTE}
              redirection={{
                isExternal: false,
                url: validatePlayerRedirectUrl(userContext),
              }}
              exact
              path={URLS.coach.editCampaigns}
              reservedGroups={["COACH"]}
              component={(props: RouteComponentProps) =>
                componentResult("CAMPAIGNS", EditCampaignPage, props, userContext?.isSubCoach, userContext?.permissions)
              }
            />
            <SCSessionRoute
              routeType={RouteType.PRIVATE_ROUTE}
              redirection={{
                isExternal: false,
                url: validatePlayerRedirectUrl(userContext),
              }}
              exact
              path={URLS.coach.players}
              reservedGroups={["COACH"]}
              component={(props: RouteComponentProps) =>
                componentResult("PLAYERS", Players, props, userContext?.isSubCoach, userContext?.permissions)
              }
            />

            <SCSessionRoute
              routeType={RouteType.PRIVATE_ROUTE}
              redirection={{
                isExternal: false,
                url: validatePlayerRedirectUrl(userContext),
              }}
              exact
              path={URLS.coach.editPlayer}
              reservedGroups={["COACH"]}
              component={(props: RouteComponentProps) =>
                componentResult("PLAYERS", EditPlayer, props, userContext?.isSubCoach, userContext?.permissions)
              }
            />

            <SCSessionRoute
              routeType={RouteType.PRIVATE_ROUTE}
              redirection={{
                isExternal: false,
                url: validatePlayerRedirectUrl(userContext),
              }}
              exact
              path={URLS.coach.prompt}
              reservedGroups={["COACH"]}
              component={(props: RouteComponentProps) =>
                componentResult("LIBRARY", PromptPage, props, userContext?.isSubCoach, userContext?.permissions)
              }
            />
            <SCSessionRoute
              routeType={RouteType.PRIVATE_ROUTE}
              redirection={{
                isExternal: false,
                url: validateCoachRedirectUrl(userContext),
              }}
              exact
              path={URLS.coach.newPrompt}
              reservedGroups={["COACH"]}
              component={(props: RouteComponentProps) =>
                componentResult("LIBRARY", PromptPage, props, userContext?.isSubCoach, userContext?.permissions)
              }
            />

            <SCSessionRoute
              routeType={RouteType.PRIVATE_ROUTE}
              redirection={{
                isExternal: false,
                url: validateCoachRedirectUrl(userContext),
              }}
              exact
              path={URLS.coach.compliance}
              reservedGroups={["COACH"]}
              component={(props: RouteComponentProps) =>
                componentResult("COMPLIANCE", CompliancePage, props, userContext?.isSubCoach, userContext?.permissions)
              }
            />

            <SCSessionRoute
              routeType={RouteType.PRIVATE_ROUTE}
              redirection={{
                isExternal: false,
                url: validateCoachRedirectUrl(userContext),
              }}
              exact
              path={URLS.coach.digestEmails}
              reservedGroups={["COACH"]}
              component={(props: RouteComponentProps) =>
                componentResult(
                  "DIGEST_EMAIL",
                  DigestEmailPage,
                  props,
                  userContext?.isSubCoach,
                  userContext?.permissions
                )
              }
            />

            <SCSessionRoute
              routeType={RouteType.PRIVATE_ROUTE}
              redirection={{
                isExternal: false,
                url: validateCoachRedirectUrl(userContext),
              }}
              exact
              path={URLS.coach.appSettings}
              reservedGroups={["COACH"]}
              component={(props: RouteComponentProps) =>
                componentResult(
                  WebComponents.APP_SETTINGS,
                  SettingsSection,
                  props,
                  userContext?.isSubCoach,
                  userContext?.permissions
                )
              }
            />

            {/* COACH PAGES END*/}

            {/* COACH/PLAYER PAGES */}
            <SCSessionRoute
              routeType={RouteType.PRIVATE_ROUTE}
              redirection={{
                isExternal: false,
                url: validateCoachRedirectUrl(userContext),
              }}
              exact
              path={URLS.videoProject}
              reservedGroups={["COACH", "PLAYER"]}
              component={(props: RouteComponentProps) => {
                const subscriptionType = get(userContext, "subscriptions[0].pricePlan.tier", undefined);
                const isSocialCoachPlus = subscriptionType === PricePlanTier.PLAYER_PLUS;
                const coachId = userContext?.coach ? userContext?.coach.id : undefined;

                const { displayAdminSettings, adminSettingLoading } = useAdminCoachSettings(coachId);

                return displayAdminSettings.scPlusTier || adminSettingLoading || isSocialCoachPlus ? (
                  <Page {...props} config={config} Component={VideoProjectDetailsPage} />
                ) : (
                  <Page {...props} config={config} Component={NotFoundPage} />
                );
              }}
            />
            {/* COACH/PLAYER PAGES END*/}

            {/* ADMIN PAGES INIT*/}
            <SCSessionRoute
              routeType={RouteType.PUBLIC_ROUTE}
              exact
              redirection={{
                isExternal: false,
                url: userContext?.account.groups.includes(Types.UserGroups.USER)
                  ? userDefaultRedirectUrl(userContext)
                  : URLS.admin.dashboard,
              }}
              path={URLS.admin.login}
              reservedGroups={[Types.UserGroups.ADMIN]}
              component={(props: RouteComponentProps) => (
                <Page
                  {...props}
                  Component={(pageProps: any) => <LoginPage {...pageProps} group={Types.UserGroups.ADMIN} />}
                />
              )}
            />
            <SCSessionRoute
              routeType={RouteType.PRIVATE_ROUTE}
              redirection={{
                isExternal: false,
                url: userContext?.account.groups.includes(Types.UserGroups.USER)
                  ? userDefaultRedirectUrl(userContext)
                  : URLS.admin.login,
              }}
              exact
              path={URLS.admin.dashboard}
              reservedGroups={[Types.UserGroups.ADMIN]}
              component={(props: RouteComponentProps) => <Page {...props} Component={AdminDashboard} />}
            />
            <SCSessionRoute
              routeType={RouteType.PRIVATE_ROUTE}
              redirection={{
                isExternal: false,
                url: userContext?.account.groups.includes(Types.UserGroups.USER)
                  ? userDefaultRedirectUrl(userContext)
                  : URLS.admin.login,
              }}
              exact
              path={URLS.admin.prospects}
              reservedGroups={[Types.UserGroups.ADMIN]}
              component={(props: RouteComponentProps) => <Page {...props} Component={AdminProspectList} />}
            />
            <SCSessionRoute
              routeType={RouteType.PRIVATE_ROUTE}
              redirection={{
                isExternal: false,
                url: userContext?.account.groups.includes(Types.UserGroups.USER)
                  ? userDefaultRedirectUrl(userContext)
                  : URLS.admin.login,
              }}
              exact
              path={URLS.admin.players}
              reservedGroups={[Types.UserGroups.ADMIN]}
              component={(props: RouteComponentProps) => <Page {...props} Component={AdminAllPlayersList} />}
            />
            {/* ADMIN PAGES END*/}

            {/* EDITOR PAGES */}
            <SCSessionRoute
              routeType={RouteType.PRIVATE_ROUTE}
              redirection={{
                isExternal: false,
                url: validateEditorRedirectUrl(userContext),
              }}
              exact
              path={URLS.editor.editQueueList}
              reservedGroups={["EDITOR", "ADMIN"]}
              component={(props: RouteComponentProps) => (
                <Page {...props} config={config} Component={EditorEditingQueueList} />
              )}
            />

            <SCSessionRoute
              routeType={RouteType.PRIVATE_ROUTE}
              redirection={{
                isExternal: false,
                url: validateEditorRedirectUrl(userContext),
              }}
              exact
              path={URLS.editor.editVideo}
              reservedGroups={["EDITOR", "ADMIN"]}
              component={(props: RouteComponentProps) => (
                <Page {...props} config={config} Component={EditorVideoProjectPage} />
              )}
            />
            {/* EDITOR PAGES END */}

            {/* BRAND PAGES  */}
            <Route
              exact
              path={URLS.brandPost}
              component={(props: RouteComponentProps) => (
                <Page includeHeader={false} {...props} Component={BrandPostPage} />
              )}
            />

            {/* BRAND PAGES END */}

            <SCSessionRoute
              routeType={RouteType.PRIVATE_ROUTE}
              redirection={{
                isExternal: false,
                url: userContext?.account.groups.includes(Types.UserGroups.USER)
                  ? userDefaultRedirectUrl(userContext)
                  : URLS.admin.login,
              }}
              exact
              path={URLS.videoScripts}
              reservedGroups={[Types.UserGroups.ADMIN, "COACH"]}
              component={(props: RouteComponentProps) =>
                scriptAccessComponentResult(props, VideoScriptsPage, userContext)
              }
            />
            <SCSessionRoute
              routeType={RouteType.PRIVATE_ROUTE}
              redirection={{
                isExternal: false,
                url: userContext?.account.groups.includes(Types.UserGroups.USER)
                  ? userDefaultRedirectUrl(userContext)
                  : URLS.admin.login,
              }}
              exact
              path={URLS.videoScript}
              reservedGroups={[Types.UserGroups.ADMIN, "COACH"]}
              component={(props: RouteComponentProps) =>
                scriptAccessComponentResult(props, VideoScriptPage, userContext)
              }
            />
            <SCSessionRoute
              routeType={RouteType.PRIVATE_ROUTE}
              redirection={{
                isExternal: false,
                url: userContext?.account.groups.includes(Types.UserGroups.USER)
                  ? userDefaultRedirectUrl(userContext)
                  : URLS.admin.login,
              }}
              exact
              path={URLS.newVideoScript}
              reservedGroups={[Types.UserGroups.ADMIN, "COACH"]}
              component={(props: RouteComponentProps) =>
                scriptAccessComponentResult(props, VideoScriptPage, userContext)
              }
            />

            <SCSessionRoute
              routeType={RouteType.PRIVATE_ROUTE}
              redirection={{
                isExternal: false,
                url: userContext?.account.groups.includes(Types.UserGroups.USER)
                  ? userDefaultRedirectUrl(userContext)
                  : URLS.admin.login,
              }}
              exact
              path={URLS.admin.newContentPage}
              reservedGroups={[Types.UserGroups.ADMIN]}
              component={(props: RouteComponentProps) => (
                <Page {...props} config={config} Component={ContentPageEditor} />
              )}
            />

            <SCSessionRoute
              routeType={RouteType.PRIVATE_ROUTE}
              redirection={{
                isExternal: false,
                url: userContext?.account.groups.includes(Types.UserGroups.USER)
                  ? userDefaultRedirectUrl(userContext)
                  : URLS.admin.login,
              }}
              exact
              path={URLS.admin.contentPageEditor}
              reservedGroups={[Types.UserGroups.ADMIN]}
              component={(props: RouteComponentProps) => (
                <Page {...props} config={config} Component={ContentPageEditor} />
              )}
            />

            {/* NO SESSION PAGES */}
            <Route
              path={URLS.player.signUp}
              component={(props: RouteComponentProps) => (
                <Page {...props} Component={PlayerSignUpPage} includeHeader={false} />
              )}
            />
            <Route
              path={URLS.player.login}
              component={(props: RouteComponentProps) => (
                <Page {...props} Component={PlayerLoginPage} includeHeader={false} />
              )}
            />

            <Route
              path={URLS.notFound}
              exact
              component={(props: RouteComponentProps) => (
                <Page {...props} Component={NotFoundPage} includeHeader={false} />
              )}
            />

            <Route
              exact
              path={URLS.register + "/:step?"}
              component={(props: RouteComponentProps) => (
                <Page {...props} Component={SignUpPage} config={config} includeHeader={false} />
              )}
            />
            <Route
              exact
              path={URLS.terms}
              component={(props: RouteComponentProps) => <Page {...props} Component={TermsPage} />}
            />

            <Route
              exact
              path={URLS.privacyPolicy}
              component={(props: RouteComponentProps) => <Page {...props} Component={PrivacyPage} />}
            />

            <Route
              path={URLS.landingPage}
              exact
              component={(props: RouteComponentProps) => (
                <Page {...props} Component={LandingPreview} includeHeader={false} />
              )}
            />
          </Switch>
        )}
      </AppContext.Consumer>
    </AppContextProvider>
  );
};
