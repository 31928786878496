import * as React from "react";
import { Modal, Button, Input, Header } from "semantic-ui-react";
import TextareaAutosize from "react-textarea-autosize";

import { modalReducer } from "./modalReducer";
import { VideoScript } from "../../../../../interfaces";

import { VideoScriptsServices } from "../../../../../services";

import "./styles.scss";

interface OwnProps {
  opened: boolean;

  script: VideoScript;
  acceptHandler: () => void;
  rejectHandler: () => void;
}

type Props = OwnProps;

const dataElmId = "scriptNotificationModal";

/**
 * Add script Category Components
 */
const NotificationModalClass: React.FC<Props> = ({ opened, script, acceptHandler, rejectHandler }) => {
  const [state, dispatch] = React.useReducer(modalReducer, { open: opened, loading: false });

  const [title, setTitle] = React.useState<string>(`New Script: ${script.title}`);
  const [description, setDescription] = React.useState<string>(
    "Need some inspiration for your next video? Check out this new script! Load it up and create something amazing."
  );

  React.useEffect(() => {
    if (opened) {
      dispatch({ type: "open" });
    } else {
      dispatch({ type: "close" });
    }
  }, [opened]);

  const sendPushNotification = (): void => {
    dispatch({ type: "loading" });
    VideoScriptsServices.notify({
      scriptId: script.videoScriptId!!,
      title,
      message: description,
    })
      .then(response => {
        alert(response.numberOfPlayers + " were notified");
        acceptHandler();
      })
      .finally(() => dispatch({ type: "loaded" }));
  };

  const handleKeyPress = (e: any) => {
    if (e.key === "Enter" && title.length >= 1) {
      sendPushNotification();
    }
  };

  return (
    <Modal
      closeIcon
      size={"small"}
      open={state.open}
      onClose={() => {
        dispatch({ type: "close" });
        rejectHandler();
      }}
    >
      <Modal.Header className="aiModalHeader">
        <Header.Content>
          <label>Send Script Notification</label>
        </Header.Content>
      </Modal.Header>

      <Modal.Content>
        <Modal.Description className="smallPaddingBottom">
          This notification will be sent to all players who have enabled push notifications and access to the
          teleprompter.
        </Modal.Description>

        <Input
          fluid
          placeholder={"Push Title"}
          key={dataElmId + "TitleInput"}
          data-elm-id={dataElmId + "TitleInput"}
          value={title}
          onKeyUp={handleKeyPress}
          className={"categoryText"}
          onChange={(e, data) => setTitle(`${data!.value}`)}
        />

        <TextareaAutosize
          placeholder={"Push Description"}
          key={dataElmId + "DescriptionInput"}
          data-elm-id={dataElmId + "DescriptionInput"}
          value={description}
          onKeyUp={handleKeyPress}
          onChange={data => setDescription(`${data.currentTarget.value}`)}
          className={"notificationDescription"}
          id="description"
          name="description"
          rows={3}
        />
      </Modal.Content>

      <Modal.Actions className="actionsSection">
        <Button
          data-elm-id={dataElmId + "saveBtn"}
          primary
          disabled={state.loading || title.length < 1}
          loading={state.loading}
          onClick={sendPushNotification}
        >
          Send
        </Button>

        <Button
          disabled={state.loading || title.length < 1}
          data-elm-id={dataElmId + "cancelBtn"}
          secondary
          onClick={rejectHandler}
        >
          Cancel
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

export const NotificationModal = NotificationModalClass;
