import * as React from "react";
import { Grid, Form, Message, Loader, Modal, Button } from "semantic-ui-react";

import { get } from "lodash";
import { Formik } from "formik";

import { DownloadAppInformation, MenuTitleSection, PlayerProfileInformation } from "../../../components";

import { AppContext } from "../../../providers";
import { ConfigContext } from "../../../contexts/appContexts";
import * as IT from "../../../interfaces";
import { PlayerSignUpStep, PlayersServices, SocialCoachSessionService, CoachService } from "../../../services";
import { redirectToPlayerStep, saveImageToFirebase } from "../../../utils";

import { WhiteContainer, InternalGrid, InternalContentGrid } from "../../../styling/baseStyle";
import { UserChangePasswordForm } from "../../UserChangePasswordForm";

import { validationProfileSchema } from "./validations";
import "./styles.scss";

const IntegratedPlayerProfile: React.FC = () => {
  const { userContext, setUserContext } = React.useContext(AppContext);
  const config: IT.ConfigProperties = React.useContext(ConfigContext);
  const [coachId, setCoachId] = React.useState<number | undefined>();

  const [playerPicture, setPlayerPicture] = React.useState<File | undefined>();
  const [saved, setSaved] = React.useState<boolean>(false);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [playerIndustryRoles, setPlayerIndustryRoles] = React.useState<IT.PlayerIndustryRoles | undefined>();

  React.useEffect(() => {
    if (userContext?.signUpStep! !== PlayerSignUpStep[PlayerSignUpStep.PLAYER_BUSINESS_DATA]) {
      const url = redirectToPlayerStep(userContext?.signUpStep!);
      window.location.assign(url);
    }
  }, [userContext]);

  React.useEffect(() => {
    setCoachId(get(userContext, "subscriptions[0].pricePlan.coachId", undefined));
  }, [userContext]);

  const savedBanner = () => {
    return (
      saved && (
        <>
          <Message className="profileMessage" info content={"Saved!"} />
          <div className="break" />
        </>
      )
    );
  };

  const errorBanner = (errors: any) => {
    return (
      errors &&
      Object.values(errors).length > 0 &&
      !loading && (
        <>
          <Message className="profileMessage" negative list={Object.values(errors) as string[]} />
          <div className="break" />
        </>
      )
    );
  };

  React.useEffect(() => {
    if (!coachId || !userContext?.player?.id) return;

    CoachService.playerIndustryRoles(coachId, userContext.player.id).then(setPlayerIndustryRoles).catch(console.log);
  }, [coachId, userContext?.player?.id]);

  return (
    <WhiteContainer>
      <InternalContentGrid>
        <Formik
          validateOnBlur={false}
          validateOnChange
          enableReinitialize
          validateOnMount
          initialValues={{
            firstName: get(userContext, "profile.firstName", ""),
            lastName: get(userContext, "profile.lastName", ""),
            title: get(userContext, "player.webAddress", ""),
            phoneNumber: get(userContext, "profile.phone", ""),
            photoUrl: get(userContext, "profile.photoUrl", undefined),
            transactionsClosedLastYear: get(userContext, "player.transactionsClosedLastYear", undefined),
            averageIncomesPerTransaction: get(userContext, "player.averageIncomesPerTransaction", undefined),
            leadsNeedToCloseTransaction: get(userContext, "player.leadsNeedToCloseTransaction", undefined),
            industryRole: playerIndustryRoles?.industryRoles?.[0]?.name,
          }}
          validationSchema={validationProfileSchema()}
          onSubmit={async (
            {
              firstName,
              lastName,
              title,
              phoneNumber,
              leadsNeedToCloseTransaction,
              averageIncomesPerTransaction,
              transactionsClosedLastYear,
              industryRole,
            },
            { setSubmitting, setErrors, validateForm }
          ) => {
            try {
              const errors = await validateForm({
                firstName,
                lastName,
                title,
                phoneNumber,
                transactionsClosedLastYear,
                leadsNeedToCloseTransaction,
                averageIncomesPerTransaction,
                industryRole,
              });
              if (errors && Object.values(errors).length > 0) {
                setErrors(errors);
                setLoading(false);
              } else {
                if (!userContext?.account || !userContext.player) return;

                const { player, account, profile } = userContext;

                setLoading(true);

                let playerPictureUrl = profile.photoUrl || "";
                if (playerPicture !== undefined) {
                  playerPictureUrl = await saveImageToFirebase(config.firebase, account.userId, playerPicture, "main");
                }

                const accountSectionRequest = {
                  ...player,
                  transactionsClosedLastYear,
                  averageIncomesPerTransaction,
                  leadsNeedToCloseTransaction,
                  hashtags: null,
                };

                const playerUpdateRequest: IT.UpdateUserPayload = {
                  ...profile,
                  phone: phoneNumber!,
                  firstName: firstName!,
                  lastName: lastName!,
                  photoUrl: playerPictureUrl,
                };

                const updates = [
                  SocialCoachSessionService.updatePlayer(playerUpdateRequest),
                  PlayersServices.updatePlayerQuestions(player.id, accountSectionRequest),
                ];
                if (coachId) updates.push(CoachService.updatePlayerIndustryRole(coachId, player.id, industryRole));

                Promise.all(updates)
                  .then(() => {
                    setSaved(true);
                    setTimeout(() => {
                      setSaved(false);
                      setUserContext({
                        ...userContext,
                        profile: {
                          ...profile,
                          ...playerUpdateRequest,
                        },
                        player: {
                          ...player,
                          transactionsClosedLastYear,
                          averageIncomesPerTransaction,
                          leadsNeedToCloseTransaction,
                        },
                      });
                    }, 1000);
                  })
                  .catch(e => {
                    const errorMessage = String(e);
                    setErrors({
                      firstName: errorMessage,
                      lastName: undefined,
                      phoneNumber: undefined,
                    });
                  })
                  .finally(() => {
                    setLoading(false);
                    setSubmitting(false);
                  });
              }
            } catch (e) {
              setErrors({
                firstName: String(e),
                lastName: undefined,
                phoneNumber: undefined,
              });
            }
          }}
        >
          {({ values, errors, handleChange, isSubmitting, handleSubmit }) => (
            <Form className={isSubmitting ? "disabledForm" : ""} error>
              <Grid columns={1} className={"socialContent"}>
                <MenuTitleSection
                  isLoading={loading}
                  disableDuringLoading
                  handleNextAction={() => handleSubmit()}
                  customLinkSection={
                    <Modal
                      trigger={
                        <Grid.Column floated="right" width={8}>
                          <Button
                            type="button"
                            data-elm-id={`profileInfoChangePwdBtn`}
                            size="large"
                            className={"secondary changePasswordButton"}
                          >
                            Change Password
                          </Button>
                        </Grid.Column>
                      }
                      closeIcon
                      size={"tiny"}
                    >
                      <Modal.Header centered>Change Password</Modal.Header>
                      <Modal.Content>
                        <Modal.Description>
                          <UserChangePasswordForm />
                        </Modal.Description>
                      </Modal.Content>
                    </Modal>
                  }
                />
                {savedBanner()}
                {errorBanner(errors)}

                <InternalGrid className={"menuBaseContainer"}>
                  <Grid.Row>
                    <PlayerProfileInformation
                      onChangeImage={(file: File) => {
                        setPlayerPicture(file);
                      }}
                      handleChange={handleChange}
                      values={values}
                      availableIndustryRoles={playerIndustryRoles?.availableIndustryRoles?.map(el => el.name)}
                    />
                  </Grid.Row>
                  <DownloadAppInformation />
                </InternalGrid>
              </Grid>
            </Form>
          )}
        </Formik>
      </InternalContentGrid>
      {loading && <Loader active size="large" />}
    </WhiteContainer>
  );
};

export const PlayerProfile = IntegratedPlayerProfile;
