import * as React from "react";
import { Formik } from "formik";
import { get } from "lodash";
import {
  PostPlayerRequest,
  SendModeType,
  SocialNetworksId,
  dateKey,
  timeKey,
  MediaGroup,
  MediaType,
  TEXT_ONLY_SOCIAL_NETWORKS,
} from "../../../interfaces";
import { PostServices } from "../../../services";
import { getFilteredSocialNetworks, getSocialNetworkMessagesWithMessage, promptSpecificDate } from "../../../utils";

import { FormStepper, ScheduledPostStep, CaptionStep } from "./components";

import { scheduledValidationSchema, validationSchema } from "./validations";
import { useBlockedWords } from "./hooks";
import { AppContext } from "../../../providers";

interface OwnProps {
  playerId: number;
  postPlayerRequest: PostPlayerRequest;
  thumbnail: string;
  showPostTypeOption?: boolean;
  onContentloading?: (value: boolean) => void;
  onSuccess: (isAutoPost: boolean, sendMode: SendModeType) => void;
}

const PostModalFormFC: React.FC<OwnProps> = ({
  playerId,
  thumbnail,
  postPlayerRequest,
  onContentloading,
  onSuccess,
  showPostTypeOption = false,
}) => {
  const { userContext } = React.useContext(AppContext);
  const formikRef = React.useRef<any>(null);
  const [attachedLoading, setAttachedLoading] = React.useState(false); // remove
  const [step, setStep] = React.useState(0);

  React.useEffect(() => {
    if (onContentloading) {
      onContentloading(attachedLoading);
    }
  }, [attachedLoading]);

  // Blocked / Flagged Words
  const { blockedNamesList, flaggedNamesList } = useBlockedWords(playerId);
  const [warnings, setWarnings] = React.useState<string[]>([]);

  React.useEffect(() => {
    setWarnings([]);
  }, [step]);

  const addFlaggedWordsWarning = (warning: string): void => {
    if (warning.length === 0) {
      setWarnings([]);
    } else {
      setWarnings(() => [`Flagged words detected: ${warning}`]);
    }
  };

  const socialNetworksMapFiltered = postPlayerRequest.socialNetworkMessages.reduce((a, v) => {
    const key = v.code || v.socialNetworkId;
    if (key && userContext?.profile.socialNetworks?.map(sn => sn.networkId).includes(key as SocialNetworksId)) {
      a[key] = true;
    }
    return a;
  }, {});

  return (
    <Formik
      innerRef={formikRef}
      initialValues={{
        messageToPost: get(postPlayerRequest, "messageToPost", ""),
        thumbnail: thumbnail,
        withMediaReady: get(postPlayerRequest, "mediaGroup.media.length", 0),
        mediaGroup: get(postPlayerRequest, "mediaGroup", undefined),
        postType: get(postPlayerRequest, "mediaGroup.type", "FEED"),
        isAutoPost: get(
          postPlayerRequest,
          "isAutoPost",
          postPlayerRequest.mediaGroup ? postPlayerRequest.mediaGroup.media.length === 1 : false
        ),
        sendMode: postPlayerRequest.scheduledDatetimeUtc ? "SCHEDULED" : "IMMEDIATE",
        deliveryDateUtc: postPlayerRequest.scheduledDatetimeUtc,
        deliveryTimeUtc: postPlayerRequest.scheduledDatetimeUtc,
        socialNetworks: socialNetworksMapFiltered,
        socialNetworksCounter: postPlayerRequest.socialNetworkMessages.length,
        playerId: playerId,
        blockedWords: "",
        flaggedWords: "",
      }}
      validationSchema={step === 0 ? validationSchema : scheduledValidationSchema}
      onSubmit={async (values, { setErrors }) => {
        const { sendMode, messageToPost, isAutoPost, mediaGroup, socialNetworks, postType } = values;

        let sheduleSection = {};
        if (sendMode === "SCHEDULED") {
          sheduleSection = {
            sendMode,
            scheduledDatetimeUtc:
              values[dateKey] && values[timeKey] ? promptSpecificDate(values[dateKey], values[timeKey])! : undefined,
          };
        }

        const snFiltered = getFilteredSocialNetworks(socialNetworks).filter(
          id => !!mediaGroup || TEXT_ONLY_SOCIAL_NETWORKS.includes(id as SocialNetworksId)
        );
        const socialNetworkMessages = isAutoPost
          ? getSocialNetworkMessagesWithMessage(snFiltered, messageToPost)
          : [
              {
                code: "FB",
                socialNetworkMessage: messageToPost,
              },
            ];

        const mediaGroupRequest: MediaGroup | undefined = mediaGroup
          ? {
              type: (showPostTypeOption && postType ? postType : mediaGroup.type) as MediaType,
              media: mediaGroup.media,
            }
          : undefined;

        const request: PostPlayerRequest = {
          ...postPlayerRequest,
          ...sheduleSection,
          messageToPost,
          socialNetworkMessages,
          isAutoPost: isAutoPost,
          mediaGroup: mediaGroupRequest,
        };

        PostServices.createAsPlayer(request)
          .then(() => {
            onSuccess(isAutoPost, sendMode as SendModeType);
          })
          .catch(error => {
            setErrors({
              messageToPost: error,
            });
          });
      }}
    >
      {({ errors, setFieldValue, values }) => {
        const captionErrors = Object.values(errors).filter(e => e.toString().includes("detected")) as string[];
        return (
          <FormStepper errors={Object.values(errors) as string[]} formikRef={formikRef} updateStep={setStep}>
            {/* FIRST STEP */}
            <CaptionStep
              errors={captionErrors}
              warnings={warnings}
              blockedNamesList={blockedNamesList}
              flaggedNamesList={flaggedNamesList}
              handleSetFieldValue={(field, value) => {
                setFieldValue(field, value);
                if (field === "flaggedWords") {
                  addFlaggedWordsWarning(value);
                }
              }}
            />
            {/* SECOND STEP */}
            <ScheduledPostStep
              handleSetFieldValue={setFieldValue}
              showPostTypeOption={showPostTypeOption && !!values.mediaGroup}
            />
          </FormStepper>
        );
      }}
    </Formik>
  );
};

export const PostModalForm = PostModalFormFC;
