import * as React from "react";

import * as DTO from "../../../interfaces";
import { VideoProjectServices } from "../../../services";

import { PlayerVideoCardSection } from "../../PlayerVideoCardSection";
import { EmptyView } from "../../EmptyView";
import { Loader } from "semantic-ui-react";

interface VideoProjectElement {
  result: DTO.VideoProject[];
  total: number;
}

interface VideoProjectResult {
  elements: VideoProjectElement;
  name: string;
}

interface OwnProps {
  coachId: string;
  playerId: number;

  showError?: (ErrorMessage: string) => void;
}

type Props = OwnProps;

const VideoCatalystListComponentFC: React.FC<Props> = ({ playerId }) => {
  const [videoProjects, setVideoProjects] = React.useState<DTO.PlayerVideoProjectResponse[]>([]);
  const [loading, setLoading] = React.useState<boolean>(false);
  React.useEffect(() => {
    async function loadAll() {
      setLoading(true);
      const response = await VideoProjectServices.getPlayerVideoProjects(playerId);
      setVideoProjects(response.content);
      setLoading(false);
    }

    if (playerId) {
      loadAll();
    }
  }, [playerId]);

  const projectsByStatus = (allProjects, projectStatus): VideoProjectElement => {
    const result = (allProjects as DTO.VideoProject[])?.filter(project => project.status === projectStatus);
    return { result, total: result.length };
  };

  const videoProjectSections: VideoProjectResult[] = [
    {
      name: "Submitted for editing",
      elements: projectsByStatus(videoProjects, "SUBMITTED_FOR_EDITING"),
    },
    {
      name: "Ready to post",
      elements: projectsByStatus(videoProjects, "READY_TO_POST"),
    },
    {
      name: "Scheduled",
      elements: projectsByStatus(videoProjects, "SCHEDULED"),
    },
    {
      name: "Posted",
      elements: projectsByStatus(videoProjects, "POSTED"),
    },
    {
      name: "Post failed",
      elements: projectsByStatus(videoProjects, "POST_FAILED"),
    },
  ];

  return loading ? (
    <Loader active size="large" />
  ) : videoProjects && videoProjects.length > 0 ? (
    <div className="promptListContainer">
      {videoProjectSections.map((section, index) => {
        return (
          <PlayerVideoCardSection
            key={"videoProjectSection-" + index}
            sectionName={section.name}
            videoProjects={section.elements.result}
            totalElements={section.elements.total}
          />
        );
      })}
    </div>
  ) : (
    <EmptyView text={"No Video Projects"} />
  );
};

export const PlayerVideoCatalystListComponent = VideoCatalystListComponentFC;
