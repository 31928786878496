import * as React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Form, Message, Loader, Grid, Radio, DropdownItemProps } from "semantic-ui-react";
import TextareaAutosize from "react-textarea-autosize";

import { ConfigContextProvider } from "../../../contexts/appContexts";
import { BasicFormProps, Media, PlayerAccount, SocialNetworkPosted, VideoProjectStatus } from "../../../interfaces";
import { useCoachSettings } from "../../../reducers";
import { CoachService, PlayersServices, VideoProjectServices } from "../../../services";
import { appUserContextToPlayerAccount, SOCIAL_TITLES, URLS } from "../../../utils";

import { VideoProjectUploader } from "../../VideoProjectUploader";
import defaultConfigProperties from "../../../defaultConfigProperties";
import { SocialNetworkIconButton } from "../../SocialNetworkIconButton";
import { PlayerInfoSection } from "../../PlayerInfoSection";
import { VideosSection } from "../VideoProjectDetail/components/VideosSection";

import { HashTagSet } from "./components/HashTagSet";

import { VideoProjectComponentType, descriptors } from "./descriptors";

import "./styles.scss";
import "../../../styling/datepicker.scss";
import { RowBasicPadding, SocialGrid, SocialRowGrid, TitleLabel } from "../../../styling/baseStyle";
import { CaptionSection } from "../VideoProjectDetail/styled";
import { map } from "lodash";
import * as DTO from "../../../interfaces";
import { Stickers } from "./components/Stickers";
import { ProjectScheduler } from "../VideoProject/components/ProjectScheduler";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { PredictionsComponent } from "../../PredictionsComponent";

const baseDataElm = "editorVideoProjectDetail";

interface OwnProps extends BasicFormProps {
  /**
   * Handle attached file is uploaded
   */
  handleMediaUpdated: (newMedia: Media[]) => void;

  /**
   * Handle datePickers changes
   */
  handleSetFieldValue?: (field: string, value: any) => void;

  /**
   * To Indicate if the form is disabled
   */
  disabled: boolean;
  /**
   * To show or hide the Player Custom Hashtags Toggle
   */
  showHashtagToggle: boolean;
  transcript?: string;
}

type Props = OwnProps & RouteComponentProps;

/**
 * Represents a Prompt content.
 */
const VideoProjectClass: React.FC<Props> = ({
  history,
  handleSetFieldValue,
  handleMediaUpdated,
  messages,
  isSubmitting = false,
  values,
  disabled,
  transcript,
  showHashtagToggle,
}) => {
  const { formatMessage } = useIntl();
  const { socialNetworks, coachId, preferredPostDate, playerId } = values;
  const { sns } = useCoachSettings(coachId);

  const [hashtags, setHashtags] = React.useState<string>(values.hashtags);
  const [showAIModal, setShowAIModal] = React.useState<boolean>(false);
  const [hashtagsListError, setHashtagsListError] = React.useState<string[] | undefined>();

  const errors = messages && messages.error && messages.error.length > 0;

  // Player section
  const [playerInfo, setPlayerInfo] = React.useState<PlayerAccount>();

  const socialNetworksPosted: SocialNetworkPosted[] = values.socialNetworksPosted;
  const [selectedSocialNetwork, setSelectedSocialNetwork] = React.useState<any | undefined>(
    socialNetworksPosted[0]?.socialNetworkId
  );
  const [postedSocialNetworkOptions, setPostedSocialNetworkOptions] = React.useState<DropdownItemProps[]>([]);
  const [stickers, setStickers] = React.useState<DTO.ComposedStickerResponse | undefined>(undefined);
  const [videoCatalystStatus, setVideoCatalystStatus] = React.useState<DTO.VideoCatalystStatus | undefined>(undefined);
  const [industryRole, setIndustryRole] = React.useState<string | null>(null);

  React.useEffect(() => {
    PlayersServices.get(playerId)
      .then(response => {
        setPlayerInfo(appUserContextToPlayerAccount(response));
      })
      .catch(console.log);
    PlayersServices.getAllPlayerStickers(playerId, coachId).then(setStickers).catch(console.log);
    VideoProjectServices.getVideoCatalystStatus(playerId).then(setVideoCatalystStatus).catch(console.log);
    CoachService.playerIndustryRoles(coachId, playerId)
      .then(response => {
        // Setting industryRole to undefined tells the PredictionsComponent that it should attempt to fetch the player's industry role, which we don't want in this case. For that reason, we default it to null instead.
        setIndustryRole(response.industryRoles[0]?.name || null);
      })
      .catch(console.log);
  }, []);

  React.useEffect(() => {
    return () => {
      window.removeEventListener("loadedmetadata", () => {
        // Nothing
      });
    };
  }, []);

  React.useEffect(() => {
    const filters: DropdownItemProps[] = map(socialNetworksPosted, sn => ({
      key: sn.socialNetworkId,
      text: SOCIAL_TITLES[sn.socialNetworkId],
      value: sn.socialNetworkId,
    }));
    setPostedSocialNetworkOptions(filters);
    setSelectedSocialNetwork(filters[0]?.value);
  }, [socialNetworksPosted]);

  const withCaption = React.useMemo(() => {
    return socialNetworksPosted.some(sn => sn.caption && sn.caption.length > 0);
  }, [socialNetworksPosted]);

  const finalMessage = React.useMemo(() => {
    if (withCaption) {
      const socialPost = socialNetworksPosted.find(sn => sn.socialNetworkId === selectedSocialNetwork);
      return socialPost?.caption;
    } else {
      return values.message;
    }
  }, [selectedSocialNetwork, socialNetworksPosted, values.message, withCaption]);

  const settingsSection = (): JSX.Element => {
    const { allowShortLink, allowHashtags, allowComplianceDisclaimer } = values;

    const arraySettings = [];

    if (showHashtagToggle) {
      arraySettings.push({
        label: formatMessage({ ...descriptors[VideoProjectComponentType.allowHashtags] }),
        checked: allowHashtags,
        id: "allowHashtags",
      });
    }

    arraySettings.push({
      label: formatMessage({ ...descriptors[VideoProjectComponentType.allowShortLink] }),
      checked: allowShortLink,
      id: "allowShortLink",
    });

    arraySettings.push({
      label: formatMessage({ ...descriptors[VideoProjectComponentType.allowComplianceDisclaimer] }),
      checked: allowComplianceDisclaimer,
      id: "allowComplianceDisclaimer",
    });

    return (
      <React.Fragment>
        <Grid padded>
          <Grid.Column floated="right" width={10}>
            <Form.Group grouped key={"videoProjectPermissions"} className={"floatedRight"}>
              {arraySettings.map(appSetting => {
                return (
                  <div key={"videoProjectPermissions" + appSetting.id}>
                    <Form.Group inline key={appSetting.id} className={"floatedRight"}>
                      <label className="small">
                        <span className="smallDetail">{appSetting.label}</span>
                      </label>
                      <Radio
                        toggle
                        className={"settingsLevelLabel"}
                        onChange={(e: any, data: any) => {
                          handleSetFieldValue!(data.name, data.checked);
                        }}
                        name={appSetting.id}
                        checked={appSetting.checked}
                      />
                    </Form.Group>
                  </div>
                );
              })}
            </Form.Group>
          </Grid.Column>
        </Grid>
      </React.Fragment>
    );
  };

  const autoPostRadio = (): JSX.Element => {
    return (
      <Radio
        data-elm-id={`${baseDataElm}isAutoPostRadioBtn`}
        className={"blackRadio secondary rounded"}
        id={"isAutoPost"}
        name={"isAutoPost"}
        onChange={(e: any, data: any) => {
          handleSetFieldValue!(`${data.id}`, data.checked);
        }}
        checked={values.isAutoPost}
        disabled={false}
        label={formatMessage({ ...descriptors[VideoProjectComponentType.autoPost] })}
      />
    );
  };

  /*
   * Auto Post or Push Notification
   */
  const postingReferences = (): JSX.Element => {
    return (
      <SocialGrid.Row className={"xbigPaddingTop"}>
        <SocialGrid.Column width={10}>
          <Grid.Row>
            <TitleLabel>{formatMessage({ ...descriptors[VideoProjectComponentType.postSectionTitle] })}</TitleLabel>
            <br />
          </Grid.Row>

          <SocialRowGrid className={"bigPaddingTop"} leftpadding={15} key={"post_row_auto_post"}>
            {autoPostRadio()}
            {values.isAutoPost && (
              <SocialGrid className={"autoPostSection"}>
                <ProjectScheduler
                  deliveryDate={preferredPostDate}
                  editable={values.status !== VideoProjectStatus.POSTED}
                  minDate={new Date()}
                  onChange={(date: Date) => {
                    handleSetFieldValue!("preferredPostDate", date.toISOString());
                  }}
                />
                <RowBasicPadding className={"socialIconsSection"}>
                  <SocialGrid className={"socialSection"} divided={"vertically"} columns={6}>
                    {sns.map((sn, index) => {
                      return (
                        <SocialNetworkIconButton
                          disabled={disabled}
                          withPadding={false}
                          size={"noMargin"}
                          key={"video_project_post_sn_icon_" + index}
                          active={socialNetworks ? socialNetworks.includes(sn) : false}
                          networkId={sn}
                          customIconClassname={"socialNetworkIconCentered"}
                          onClickHandler={checked => {
                            let snUpdated: string[] = socialNetworks;
                            if (checked) {
                              snUpdated = snUpdated.concat([sn]);
                            } else {
                              snUpdated = snUpdated.filter((p: string) => p !== sn);
                            }
                            handleSetFieldValue!("socialNetworksCounter", snUpdated.length);
                            handleSetFieldValue!("socialNetworks", snUpdated);
                          }}
                        />
                      );
                    })}
                  </SocialGrid>
                </RowBasicPadding>
              </SocialGrid>
            )}
          </SocialRowGrid>

          <SocialRowGrid key={"post_row_push_notification"}>
            <Radio
              data-elm-id={`${baseDataElm}isPushPostRadioBtn`}
              className={"blackRadio secondary rounded"}
              id={"isPushPost"}
              name={"isPushPost"}
              onChange={(e: any, data: any) => {
                handleSetFieldValue!("isAutoPost", false);
              }}
              checked={!values.isAutoPost}
              disabled={false}
              label={formatMessage({ ...descriptors[VideoProjectComponentType.pushNotification] })}
            />
          </SocialRowGrid>
        </SocialGrid.Column>
      </SocialGrid.Row>
    );
  };

  const captionSection = (
    <Form.Field>
      <div>
        <label className={"small"}>{formatMessage({ ...descriptors[VideoProjectComponentType.message] })}</label>
        {!disabled && (
          <a className="aiButton" onClick={() => setShowAIModal(true)}>
            Generate Caption
          </a>
        )}
      </div>
      {disabled ? (
        <CaptionSection data-elm-id="videoProjectDetailCaption">{finalMessage}</CaptionSection>
      ) : (
        <TextareaAutosize
          data-elm-id={`${baseDataElm}InputCaption`}
          className={"videoProjectCaption"}
          id="message"
          name="message"
          rows={3}
          disabled={disabled}
          value={values.message}
          onChange={data => {
            handleSetFieldValue!("message", data.currentTarget.value);
          }}
        />
      )}
    </Form.Field>
  );

  const hashtagTextField = (
    <>
      <label className={"small"}>{formatMessage({ ...descriptors[VideoProjectComponentType.hashtags] })}</label>
      {hashtagsListError && hashtagsListError.length > 0 && (
        <Grid className={"paddingTop leftPadding"}>
          {hashtagsListError.map(error => (
            <Grid.Row className={"noPadding"} columns={1}>
              <div className="labelWithError">{error}</div>
            </Grid.Row>
          ))}
        </Grid>
      )}
      <Grid className={"hashtagsContainer"}>
        <HashTagSet
          title={"Hashtags..."}
          coachId={coachId}
          onError={setHashtagsListError}
          customTagStyle={`background: rgba(61, 174, 245, 0.2);`}
          defaultHashtagList={hashtags}
          onSuccess={newTags => {
            const hashtagsString = newTags.reduce(
              (accumulator, hashtag) => accumulator + "#" + hashtag.displayValue + " ",
              ""
            );
            if (newTags.length <= 30) {
              handleSetFieldValue!("hashtags", hashtagsString);
            }
          }}
        />
      </Grid>
    </>
  );

  const goToAllProjects = () => {
    var url = URLS.editor.editQueueList;
    const params = new URLSearchParams();
    if (playerInfo?.username) {
      params.set("search", playerInfo.username);
    } else if (playerInfo?.firstName) {
      params.set("search", [playerInfo.firstName, playerInfo.lastName].filter(Boolean).join(" "));
    }
    url = [url, params.toString()].filter(Boolean).join("?");
    history.push(url);
  };

  return (
    <>
      <Form size="large" error={errors} className={"editorVideoProjectComponentContainer"}>
        {isSubmitting && <Loader active size="large" />}

        <div key={"videoProjectFormDiv"} className={"whiteFormDiv"}>
          {messages && messages.error && messages.error.length > 0 && (
            <Message error list={messages && messages.error} />
          )}

          {/* PLAYER INFO */}
          <label>
            <FormattedMessage {...descriptors[VideoProjectComponentType.playerSectionTitle]} />
          </label>
          {(playerInfo?.username || playerInfo?.firstName) && (
            <a className="viewAllProjectsLink" onClick={goToAllProjects}>
              View all projects
            </a>
          )}
          <PlayerInfoSection
            playerInfo={playerInfo}
            showPhone={true}
            videoCatalystStatus={videoCatalystStatus}
            industryRole={industryRole}
          />
          <Stickers videoProjectId={values.videoProjectId!!} stickers={stickers} />

          {/* Project Files section */}
          <Grid.Row>
            <label className={"sectionHeader"}>
              <FormattedMessage {...descriptors[VideoProjectComponentType.projectFilesTitle]} />
            </label>
          </Grid.Row>

          {values.originalMedia && (
            <VideosSection
              title={formatMessage({ ...descriptors[VideoProjectComponentType.originalFilesLabel] })}
              type="ORIGINAL"
              videos={values.originalMedia?.media!!}
              videoProjectId={values.videoProjectId!!}
            />
          )}

          {/* Edited Files section */}
          {values.status === VideoProjectStatus.POSTED ? (
            <VideosSection
              title={formatMessage({ ...descriptors[VideoProjectComponentType.editedFilesLabel] })}
              type="EDITED"
              videos={values.editedMedia?.media!!}
              videoProjectId={values.videoProjectId!!}
            />
          ) : (
            <div className={"attachVideosSection"}>
              <label className={"small"}>
                {formatMessage({ ...descriptors[VideoProjectComponentType.editedFilesLabel] })}
              </label>
              <ConfigContextProvider value={defaultConfigProperties}>
                <VideoProjectUploader
                  editedFile={true}
                  handleMediaUpdated={handleMediaUpdated}
                  originalMedia={values.editedMedia?.media || []}
                />
              </ConfigContextProvider>
            </div>
          )}

          {/* Post Info */}
          <label className={"sectionHeader"}>
            {formatMessage({ ...descriptors[VideoProjectComponentType.postInfoSectionTitle] })}
          </label>
          {captionSection}

          {/* Hashtags */}
          {!disabled && hashtagTextField}

          {/* Settings */}
          {!disabled && settingsSection()}

          {/* Post Preferences */}
          {!disabled && postingReferences()}
        </div>
      </Form>

      {showAIModal && (
        <PredictionsComponent
          opened={true}
          industryRole={industryRole}
          transcript={transcript}
          rejectHandler={() => {
            setShowAIModal(false);
          }}
          acceptHandler={(prediction, addedHashtags) => {
            setShowAIModal(false);
            handleSetFieldValue!("message", prediction);

            if (addedHashtags && addedHashtags.length > 0) {
              const newTags: string[] = [];
              if (hashtags.length > 0) {
                hashtags.split(" ").forEach(tag => newTags.push(tag));
              }

              addedHashtags.forEach(tag => {
                if (newTags.indexOf(tag) === -1) {
                  newTags.push(tag);
                }
              });
              setHashtags(newTags.join(" "));
            }
          }}
        />
      )}
    </>
  );
};

export const EditorVideoProjectComponent = withRouter(VideoProjectClass);
