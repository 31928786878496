import * as React from "react";
import { Modal, Button, Radio } from "semantic-ui-react";

import "./styles.scss";
import { VideoProjectServices } from "../../../../../services";
import { VideoProject } from "../../../../../interfaces/dtos/VideoProject";

interface OwnProps {
  open: boolean;
  videoProjectId: number;
  isVideoProjectArchived: boolean;
  onClose: () => void;
  onDuplicate: (videoProject: VideoProject) => void;
}
type Props = OwnProps;

const DuplicateVideoProjectModalFC: React.FC<Props> = ({
  open,
  videoProjectId,
  isVideoProjectArchived,
  onClose,
  onDuplicate,
}) => {
  const [archiveAfterDuplicating, setArchiveAfterDuplicating] = React.useState<boolean>(true);
  const [duplicating, setDuplicating] = React.useState<boolean>(false);
  const [newVideoProject, setNewVideoProject] = React.useState<VideoProject | null>(null);

  const duplicateVideoProject = (): void => {
    setDuplicating(true);
    VideoProjectServices.duplicate(videoProjectId, !isVideoProjectArchived && archiveAfterDuplicating)
      .then(setNewVideoProject)
      .catch((e: any) => alert(e))
      .finally(() => setDuplicating(false));
  };

  const getDescription = (): JSX.Element => {
    if (newVideoProject) {
      return <p>Video project has been duplicated.</p>;
    } else if (duplicating) {
      return <p>Duplicating video project...</p>;
    } else if (isVideoProjectArchived) {
      return <p>Are you sure you want to duplicate this video project?</p>;
    } else {
      return (
        <>
          <p>Would you like to archive the current project after duplicating?</p>
          <Radio
            label="Yes"
            name="archiveAfterDuplicating"
            className="archiveAfterDuplicatingRadioBtn"
            checked={archiveAfterDuplicating}
            onChange={() => setArchiveAfterDuplicating(true)}
            value="Yes"
          />
          <Radio
            label="No"
            name="archiveAfterDuplicating"
            className="archiveAfterDuplicatingRadioBtn"
            checked={!archiveAfterDuplicating}
            onChange={() => setArchiveAfterDuplicating(false)}
            value="No"
          />
        </>
      );
    }
  };

  const showCloseButton = (): boolean => {
    return !duplicating && (!newVideoProject || !archiveAfterDuplicating);
  };

  const closeButton = (): JSX.Element | undefined => {
    if (!showCloseButton()) return undefined;

    let buttonText = newVideoProject ? "Close" : "Cancel";

    return (
      <Button
        data-elm-id={`duplicateVideoProjectModalCloseBtn`}
        className={"secondary rounded padded"}
        onClick={() => onClose()}
      >
        {buttonText}
      </Button>
    );
  };

  const primaryButton = (): JSX.Element => {
    if (newVideoProject) {
      return (
        <Button
          data-elm-id={`duplicateVideoProjectModalGoToBtn`}
          className={"primary rounded"}
          onClick={() => onDuplicate(newVideoProject)}
        >
          Go to new project
        </Button>
      );
    } else {
      return (
        <Button
          data-elm-id={`duplicateVideoProjectModalDuplicateBtn`}
          className={"primary rounded"}
          loading={duplicating}
          onClick={duplicateVideoProject}
        >
          Duplicate
        </Button>
      );
    }
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      className={"duplicateVideoProjectModal"}
      closeOnDocumentClick={showCloseButton()}
      closeOnDimmerClick={showCloseButton()}
      closeOnEscape={showCloseButton()}
      size="tiny"
    >
      <Modal.Header>Duplicate Video Project</Modal.Header>

      <Modal.Description>{getDescription()}</Modal.Description>

      <Modal.Actions>
        {closeButton()}
        {primaryButton()}
      </Modal.Actions>
    </Modal>
  );
};

export const DuplicateVideoProjectModal = DuplicateVideoProjectModalFC;
