import { useCallback, useEffect, useState } from "react";
import { SettingsServices } from "../services";
import { AdminWebSetting, WebSettings } from "../interfaces";

const useAccountSettings = (coachId: string | undefined) => {
  const [coachAccountSettings, setCoachAccountSettings] = useState<AdminWebSetting | undefined>(undefined);

  const hasAccountSetting = useCallback(
    (setting: WebSettings): boolean | undefined => {
      return coachAccountSettings?.settings?.find(s => s.webSettingId == setting)?.status == "ACTIVE";
    },
    [coachAccountSettings]
  );

  useEffect(() => {
    if (coachId) {
      SettingsServices.getAdminSettingsByCoachId(coachId).then(setCoachAccountSettings);
    }
  }, [coachId]);

  return { hasAccountSetting };
};

export default useAccountSettings;
