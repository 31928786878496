import * as React from "react";
import { Modal, Header } from "semantic-ui-react";

import { Media, MediaType, PostPlayerRequest, SendModeType } from "../../../../../interfaces";
import { AppContext } from "../../../../../providers";
import { PostModalForm, PlayerPostSuccess } from "../../../../../components";

import { modalReducer } from "./modalReducer";

interface OwnProps {
  opened: boolean;
  media?: Media;
  secondsRecorded?: number;
  defaultPostType?: MediaType;

  closeHandler: () => void;
}

type Props = OwnProps;

/**
 * Post Flow Modal Components
 */
const PostModalFC: React.FC<Props> = ({ opened, media, secondsRecorded, defaultPostType, closeHandler }) => {
  const { userContext } = React.useContext(AppContext);

  const [state, dispatch] = React.useReducer(modalReducer, { open: opened, loading: false, success: false });
  const [postPlayerRequest, setPostPlayerRequest] = React.useState<PostPlayerRequest | undefined>(undefined);
  const [postType, setPostType] = React.useState<MediaType>(defaultPostType || "REELS");
  const [showPostTypeOption, setShowPostTypeOption] = React.useState(true);

  React.useEffect(() => {
    if (secondsRecorded && secondsRecorded > 89) {
      setShowPostTypeOption(false);
      setPostType("VIDEO");
    } else if (secondsRecorded) {
      setPostType("REELS");
    }
  }, [secondsRecorded]);

  React.useEffect(() => {
    if (defaultPostType) setPostType(defaultPostType);
  }, [defaultPostType]);

  React.useEffect(() => {
    if (media !== undefined) {
      const request: PostPlayerRequest = {
        messageToPost: ``,
        playerId: userContext?.player?.id,
        mediaGroup: {
          type: postType,
          media: [media],
        },
        socialNetworkMessages: [],
      };
      setPostPlayerRequest(request);
    }
  }, [media, postType]);

  React.useEffect(() => {
    if (opened) {
      dispatch({ type: "open" });
    }
  }, [opened]);

  const close = () => {
    dispatch({ type: "close" });
    closeHandler();
  };

  const onSuccess = (isAutoPost: boolean, sendMode: SendModeType): void => {
    dispatch({ type: "success" });
    setPostPlayerRequest({ ...postPlayerRequest!!, sendMode, isAutoPost });
  };

  return (
    <Modal closeIcon size={"small"} open={state.open} onClose={close}>
      <Modal.Header>
        <Header.Content>
          <label className="postFailedTitle">New Post</label>
        </Header.Content>
      </Modal.Header>

      <Modal.Content className="PostFormModalContent">
        {!state.success && !state.loading && media && postPlayerRequest && (
          <PostModalForm
            playerId={userContext!!.player!.id}
            thumbnail={media?.thumbnailUri || media?.uri}
            postPlayerRequest={postPlayerRequest}
            showPostTypeOption={showPostTypeOption}
            onSuccess={onSuccess}
          />
        )}

        {state.success && (
          <PlayerPostSuccess sendMode={postPlayerRequest?.sendMode!!} isAutoPost={postPlayerRequest?.isAutoPost} />
        )}
      </Modal.Content>
    </Modal>
  );
};

export const PostModal = PostModalFC;
