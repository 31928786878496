import * as React from "react";
import { useIntl } from "react-intl";

import * as DTO from "../../../interfaces";
import { printOnlyDate, createVideoThumbnailBlob } from "../../../utils";

import { descriptors, CampaignCardType } from "./descriptors";
import { ThumbnailPreview, PromptCardStyle, PromptHeader, EnrroledLabel, CampaignCardContent } from "./styled";

import "./styles.scss";

interface OwnProps {
  campaign: DTO.CampaignResume;
}

type Props = OwnProps;

const CampaignCardFC: React.FC<Props> = ({
  campaign: { campaignId, createdDate, firstPromptMedia, campaignName, subscribedPlayerCount },
}: Props) => {
  const [thumbnail, setThumbnail] = React.useState<string | undefined>();
  const { formatMessage } = useIntl();
  React.useEffect(() => {
    if (firstPromptMedia && firstPromptMedia.uri) {
      const urlPromise =
        firstPromptMedia.category === "IMAGE"
          ? Promise.resolve(firstPromptMedia.uri)
          : createVideoThumbnailBlob(firstPromptMedia.downloadLink!);
      urlPromise.then(url => {
        setThumbnail(url);
      });
    }
  }, [firstPromptMedia]);

  return (
    <PromptCardStyle className={"campaignCard"} href={`/campaigns/${campaignId}`}>
      <ThumbnailPreview width={3} thumbnailurl={thumbnail} />
      <CampaignCardContent>
        <PromptHeader>{campaignName}</PromptHeader>
        <PromptCardStyle.Description>
          {formatMessage({ ...descriptors[CampaignCardType.createdTitle] }) + printOnlyDate(createdDate!!)}
          <EnrroledLabel>{`Players Enrolled ${subscribedPlayerCount}`}</EnrroledLabel>
        </PromptCardStyle.Description>
      </CampaignCardContent>
    </PromptCardStyle>
  );
};

export const CampaignCard = CampaignCardFC;
