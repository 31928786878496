import * as React from "react";
import { RouteComponentProps, withRouter } from "react-router";
import { Grid, Loader } from "semantic-ui-react";
import { get, isNil, isEmpty } from "lodash";
import classnames from "classnames";

import { LandingResponse, Plan } from "../../../interfaces/dtos";
import { PlanSummary } from "../../../components";
import { CoachingProfileService } from "../../../services/CoachingProfileService";
import { AppContext } from "../../../providers";
import "./styles.scss";
import VimeoPlayer from "../../../components/VimeoPlayer";
import { UserResume } from "../../UserResume";
import { URLS } from "../../../utils";
import MetaTags from "../../../components/MetaTags";
// import { Testimonials } from "../../Testimonials";
import { LandingPageContentExamples } from "../../LandingPageContentExamples";
import { SemanticWIDTHSNUMBER } from "semantic-ui-react/dist/commonjs/generic";

interface OwnProps {
  userId: string;
}

type Props = OwnProps & RouteComponentProps;

const LandingPreviewFC: React.FC<Props> = ({ staticContext, match: { params } }) => {
  const { userContext } = React.useContext(AppContext);

  const [loading, setLoading] = React.useState<boolean>(true);
  const [sucess, setSucess] = React.useState<boolean>(true);
  const [preview, setPreview] = React.useState<boolean>(true);
  const [coachInfo, setCoachInfo] = React.useState<LandingResponse>();
  const [plans, setPlans] = React.useState<Plan[]>();

  const bannerURL = get(coachInfo, "bannerUrl", undefined);
  const bannerStyle = bannerURL
    ? {
        backgroundImage: "url(" + bannerURL + ")",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "top",
        backgroundSize: "cover",
      }
    : {};
  React.useEffect(() => {
    setPreview(!isNil(params["userId"]));
    if (!isNil(params["userId"])) {
      getInfo();
    } else if (params["customLink"]) {
      getPublicInfo(params["customLink"]);
    }
  }, []);

  const planColumns = () => {
    return (plans?.length || 0) + 1;
  };

  const getInfo = async () => {
    await CoachingProfileService.getPreview(get(userContext, "coach.id", ""))
      .then(response => {
        setLoading(false);
        const final: LandingResponse = {
          ...response,
          coachId: userContext!.coach!.id,
          coach: {
            firstName: userContext!.profile.firstName,
            lastName: userContext!.profile.lastName,
            title: userContext!.coach!.title || "",
            companyName: userContext!.coach!.companyName || "",
            photoUrl: userContext?.profile?.photoUrl,
            socialNetworks: userContext!.profile!.socialNetworks! || [],
          },
        };
        setCoachInfo(final);
        if (!isEmpty(response.pricePlans)) {
          // setPlan(response.pricePlans![0]);
          setPlans(response.pricePlans);
        }
      })
      .catch(e => {
        setLoading(false);
        setSucess(false);
      });
  };

  const getPublicInfo = async (identifier: string) => {
    await CoachingProfileService.landingPagePublic(identifier)
      .then(response => {
        setLoading(false);
        setCoachInfo(response);
        // setPlan(response.pricePlans && response.pricePlans![0]);
        setPlans(response.pricePlans && response.pricePlans);
      })
      .catch(e => {
        setLoading(false);
        setSucess(false);
        window.location.assign(URLS.socialHome);
      });
  };

  const videoSection = (): JSX.Element => {
    const videoUrl = coachInfo?.videoUrl;

    return (
      <Grid className={classnames("videoSection")}>
        <Grid.Column>{videoUrl && <VimeoPlayer videoUrl={videoUrl} preview={false} />}</Grid.Column>
      </Grid>
    );
  };

  const metaTags = (): JSX.Element => {
    // Read from staticContext during SSR, coachInfo in browser
    const landing: LandingResponse = staticContext?.["coachInfo"] ?? coachInfo;
    const planInfo = landing?.pricePlans?.[0];
    const fullName = [landing?.coach?.firstName, landing?.coach?.lastName].filter(Boolean).join(" ");
    const title = [fullName, planInfo?.planName, "SocialCoach"].filter(Boolean).join(" - ");

    return <MetaTags title={title} description={landing?.salesMessage} imageUrl={landing?.coach?.photoUrl} />;
  };

  const content: JSX.Element = (
    <div className={"previewWhiteContainer"}>
      <Grid className={"previewContainer"} columns={1}>
        <Grid columns={1} className={"noPaddingMobile"}>
          <div className={"userBanner"} style={bannerStyle}>
            {bannerURL && (
              <img
                src={bannerURL!!}
                style={{
                  opacity: 0,
                  width: "100%",
                  visibility: "hidden",
                }}
              />
            )}
          </div>

          <Grid columns={planColumns() as SemanticWIDTHSNUMBER} stackable className={"landingContent"}>
            <Grid.Column className={"pricePlanCard"}>
              <Grid>{coachInfo && <UserResume preview={preview} phoneMode={false} coachInfo={coachInfo!} />}</Grid>
            </Grid.Column>

            {plans?.map(p => {
              return (
                <Grid.Column key={p.id} className={"pricePlanCard"}>
                  <Grid className={"rightSection"}>
                    <PlanSummary
                      title={get(p, "planName", "")}
                      offers={get(p, "planStatements", [])}
                      preview={preview}
                      trialAvailable={get(p, "hasFreeTrial", false)}
                      baseActive={get(p, "baseActive", true)}
                      amount={get(p, "price", 0)}
                      intervalPrices={get(p, "intervalPrices", undefined)}
                      coachCode={params["customLink"]}
                      planId={get(p, "id", undefined)}
                    />
                  </Grid>
                </Grid.Column>
              );
            })}
          </Grid>
        </Grid>
        {coachInfo?.videoUrl && <Grid.Column width={16}> {videoSection()}</Grid.Column>}
        {/* <Grid>
          <Testimonials title="Testimonials" />
        </Grid> */}
        {/* <Grid>
          <LandingPageContentExamples title="Content Examples" />
        </Grid> */}
      </Grid>
    </div>
  );

  return (
    <div>
      {metaTags()}
      {loading ? <Loader active size="large" /> : sucess && content}
    </div>
  );
};

export const LandingPreview = withRouter(LandingPreviewFC);
