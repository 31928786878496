import * as React from "react";
import { Modal, Header } from "semantic-ui-react";

import { PlayerPost, PostPlayerRequest, Prompt, SendModeType } from "../../../../../../../interfaces";
import { AppContext } from "../../../../../../../providers";
import { PromptServices } from "../../../../../../../services";
import { PlayerPostSuccess, PostModalForm } from "../../../../../../../components";

import { modalReducer } from "./modalReducer";
import { useMessageToRepost } from "./hooks";

interface OwnProps {
  opened: boolean;
  selectedPost: PlayerPost;
  thumbnail: string;

  closeHandler: () => void;
}

type Props = OwnProps;

/**
 * Repost Flow Modal Components
 */
const RepostModalFC: React.FC<Props> = ({ opened, selectedPost, thumbnail, closeHandler }) => {
  const { userContext } = React.useContext(AppContext);

  const [state, dispatch] = React.useReducer(modalReducer, { open: opened, loading: false });
  const [showSuccessPage, setShowSuccessPage] = React.useState<boolean>(false);
  const [prompt, setPrompt] = React.useState<Prompt | undefined>(undefined);
  const [postPlayerRequest, setPostPlayerRequest] = React.useState<PostPlayerRequest | undefined>(undefined);

  const { messageToPost } = useMessageToRepost(selectedPost, userContext!!, prompt);

  React.useEffect(() => {
    if (selectedPost.promptId) {
      dispatch({ type: "loading" });
      PromptServices.get(selectedPost.promptId)
        .then(setPrompt)
        .finally(() => {
          dispatch({ type: "loaded" });
        });
    }
  }, [selectedPost.promptId]);

  React.useEffect(() => {
    if (messageToPost !== undefined) {
      const request = {
        promptId: selectedPost.promptId,
        postId: selectedPost.postId,
        messageToPost,
        playerId: userContext?.player!!.id,
        mediaGroup: selectedPost.mediaGroup,
        socialNetworkMessages: selectedPost.socialNetworks.filter(sn => !sn.posted),
      } as PostPlayerRequest;

      setPostPlayerRequest(request);
    }
  }, [messageToPost]);

  const close = () => {
    dispatch({ type: "close" });
    closeHandler();
  };

  const onSuccess = (isAutoPost: boolean, sendMode: SendModeType): void => {
    setShowSuccessPage(true);
    setPostPlayerRequest({ ...postPlayerRequest!!, sendMode, isAutoPost });
  };

  return (
    <Modal closeIcon size={"small"} open={state.open} onClose={close}>
      <Modal.Header>
        <Header.Content>
          <label className="postFailedTitle">New Post</label>
        </Header.Content>
      </Modal.Header>

      <Modal.Content className="repostFormModalContent">
        {!showSuccessPage && !state.loading && messageToPost && postPlayerRequest && (
          <PostModalForm
            playerId={selectedPost.playerId}
            thumbnail={thumbnail}
            postPlayerRequest={postPlayerRequest}
            onSuccess={onSuccess}
          />
        )}

        {showSuccessPage && (
          <PlayerPostSuccess sendMode={postPlayerRequest?.sendMode!!} isAutoPost={postPlayerRequest?.isAutoPost} />
        )}
      </Modal.Content>
    </Modal>
  );
};

export const RepostModal = RepostModalFC;
