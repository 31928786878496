import { ContentPageInfo, PublicContentPageCTA } from "../../../../interfaces";
import * as React from "react";
import { Button, ButtonGroup, Card, CardContent, CardHeader, Dropdown, Form, Input } from "semantic-ui-react";
import { useEffect, useMemo, useState } from "react";
import { ContentPageServices } from "../../../../services/ContentPageServices";
import VideoPageCTA from "../VideoPageCTA";

interface OwnProps {
  pageInfo: ContentPageInfo;
  onUpdate: (page: ContentPageInfo) => void;
  onDeleted: (page: ContentPageInfo) => void;
}

type Props = OwnProps;

const EditVideoPageCard: React.FC<Props> = ({ pageInfo, onUpdate, onDeleted }) => {
  const [saving, setSaving] = useState(false);
  const [saved, setSaved] = useState(false);
  const [currentCTA, setCurrentCTA] = useState<PublicContentPageCTA>();
  const [pageTitle, setPageTitle] = useState<string>("");

  useEffect(() => {
    setPageTitle(pageInfo?.title);
    setCurrentCTA(pageInfo?.cta);
  }, [pageInfo]);

  const isDirty = useMemo(() => {
    return (
      pageInfo?.title !== pageTitle ||
      pageInfo?.cta?.label !== currentCTA?.label ||
      pageInfo?.cta?.link !== currentCTA?.link
    );
  }, [pageTitle, pageInfo?.title, pageInfo?.cta?.label, currentCTA?.label, pageInfo?.cta?.link, currentCTA?.link]);

  const savePage = async () => {
    if (pageInfo) {
      setSaving(true);
      const newPageInfo = await ContentPageServices.update(pageInfo.contentPageId, {
        title: pageTitle,
        caption: "",
        playerId: pageInfo.playerId,
        coachId: pageInfo.coachId,
        media: pageInfo.media,
        cta: currentCTA,
      });
      onUpdate(newPageInfo);
      setSaving(false);
      setSaved(true);
    }
  };

  useEffect(() => {
    if (isDirty && saved) {
      setSaved(false);
    }
  }, [isDirty, saved]);

  const archivePage = async () => {
    if (window.confirm("Are you sure you want to delete this Video Page? You cannot undo this action.")) {
      await ContentPageServices.archive(pageInfo!.contentPageId!!);
      alert("Video Page has been deleted.");
      onDeleted(pageInfo!);
    }
  };

  const downloadVideo = () => {
    const media = pageInfo!.media[0];
    const filename = `video-${pageInfo!.slug}.${media.extension}`;
    fetch(media.uri)
      .then(response => response.blob())
      .then(blob => {
        const urlObject = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = urlObject;
        link.setAttribute("download", filename);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(urlObject);
      });
  };

  const menuOptions = [
    { key: "download", icon: "download", text: "Download Video", value: "download", onClick: downloadVideo },
    { key: "delete", icon: "delete", text: "Delete Page", value: "delete", onClick: archivePage },
  ];

  return (
    <Card className={"videoPageDetailsCard"}>
      <CardContent className={"headerContent"}>
        <CardHeader>Page Details</CardHeader>
        <ButtonGroup className={"rounded"} floated={"right"} basic>
          <Button className={"rounded"} onClick={savePage} disabled={!isDirty} loading={saving} basic>
            {saved ? "Changes Saved!" : saving ? "Saving..." : "Save Changes"}
          </Button>
          <Dropdown className="button icon" floating options={menuOptions} trigger={<></>} />
        </ButtonGroup>
      </CardContent>
      <CardContent>
        <Form size="large" className={"videoPageModalForm"}>
          <Form.Field>
            <label>Page Title (optional)</label>

            <Input
              id={"title"}
              placeholder="Page Title"
              value={pageTitle}
              onChange={e => {
                setPageTitle(e.target.value);
              }}
            />
          </Form.Field>
          <VideoPageCTA
            playerId={pageInfo?.playerId}
            savedLabel={currentCTA?.label}
            savedLink={currentCTA?.link}
            onUpdate={cta => {
              setCurrentCTA(cta);
            }}
          />
        </Form>
      </CardContent>
    </Card>
  );
};

export default EditVideoPageCard;
