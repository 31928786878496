import * as React from "react";
import { PageTitle, PageTitleProps } from "../PageTitle";
import "./styles.scss";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { WhiteContainer } from "../../styling/baseStyle";
import { AdminSideBar } from "../SideBarAdmin";
import { SideBar } from "../SideBar";
import { AppContext } from "../../providers";
import { UserGroups } from "../../interfaces";
import { Loader } from "semantic-ui-react";

interface PageProps extends RouteComponentProps, PageTitleProps {
  loading?: boolean;
}

const PageFC: React.FC<React.PropsWithChildren<PageProps>> = ({
  // PropsWithChildren
  children,

  // PageProps
  loading = false,

  // RouteComponentProps
  history,

  // PageTitleProps
  title,
  actions,
  hideSeparator = false,
}) => {
  const { userContext } = React.useContext(AppContext);
  const isAdmin = userContext!.account.groups.some(g => g === UserGroups.ADMIN);

  return (
    <WhiteContainer>
      {isAdmin ? <AdminSideBar history={history} /> : <SideBar history={history} />}

      {loading ? (
        <Loader active size="large" />
      ) : (
        <div className="pageContainer">
          <PageTitle title={title} actions={actions} hideSeparator={hideSeparator} />

          {children}
        </div>
      )}
    </WhiteContainer>
  );
};

export const Page = withRouter(PageFC);
