import * as React from "react";
import { CoachSettings } from "../../../../../interfaces";
import { Button, Form, Modal, Radio } from "semantic-ui-react";
import { useEffect } from "react";
import { SettingsServices } from "../../../../../services";
import { AppContext } from "../../../../../providers";
import { get } from "lodash";
import usePlayerSettings from "../../../../../utils/usePlayerSettings";

interface OwnProps {
  playerId: number | undefined;
  open: boolean;
  onClose: () => void;
}

type Props = OwnProps;

const PlayerSettingsModalFC: React.FC<Props> = ({ playerId, open, onClose }) => {
  const { userContext } = React.useContext(AppContext);
  const coachId: string = get(userContext, "coach.id", "");

  const { hasSetting } = usePlayerSettings(playerId);

  const [loading, setLoading] = React.useState(false);

  const [settingValues, setSettingValues] = React.useState<{ [key: string]: boolean }>({});

  const settingOptions = [
    {
      id: "playerAccessMVP",
      name: CoachSettings.PLAYER_MVP_ACCESS,
      label: "Access to create video pages",
    },
  ];

  useEffect(() => {
    const initialValues = {};
    settingOptions.forEach(setting => {
      initialValues[setting.name] = !!hasSetting(setting.name);
    });
    setSettingValues(initialValues);
  }, [hasSetting]);

  const saveSettings = async () => {
    setLoading(true);

    await SettingsServices.updatePlayerSettings({
      playerId: playerId!.toString(),
      coachId: coachId,
      settings: settingOptions.map(setting => {
        return {
          appSettingId: setting.name,
          status: settingValues[setting.name] ? "ACTIVE" : "INACTIVE",
        };
      }),
    });
    setLoading(false);
    onClose();
  };

  return (
    <Modal closeIcon open={open} size={"tiny"} onClose={onClose}>
      <Modal.Header>Edit Feature Settings</Modal.Header>

      <Modal.Content>
        <Form>
          {settingOptions.map(setting => {
            return (
              <Form.Field key={setting.id}>
                <Radio
                  toggle
                  key={setting.id}
                  label={setting.label}
                  className={"settingsLevelLabel"}
                  onChange={(_, d) => {
                    setSettingValues(v => {
                      v[setting.name] = !!d.checked;
                      return { ...v };
                    });
                  }}
                  name={setting.id}
                  checked={settingValues[setting.name]}
                />
              </Form.Field>
            );
          })}
        </Form>
      </Modal.Content>

      <Modal.Actions>
        <Button
          primary
          data-elm-id={`basicModalYesBtn`}
          loading={loading}
          onClick={() => {
            saveSettings();
          }}
        >
          Save
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

export const PlayerSettingsModal = PlayerSettingsModalFC;
