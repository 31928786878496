import * as React from "react";
import { Grid } from "semantic-ui-react";
import { PlayerVideoCatalystListComponent } from "../../../../../components";
import "./styles.scss";

interface Props {
  myCoachId: string;
  playerId: number;
}

const VideoCatalystFC: React.FC<Props> = ({ playerId, myCoachId }) => {
  return (
    <Grid className={"videoCatalystListComponentContainer"} data-elm-id="playerVideoCatalystListComponent">
      <Grid.Row>
        <Grid.Column>
          <PlayerVideoCatalystListComponent
            coachId={myCoachId}
            playerId={playerId}
            key={"player_video_catalyst_list_c_" + playerId}
            showError={error => {
              alert(error);
            }}
          />
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

export const PlayerVideoCatalyst = VideoCatalystFC;
