import * as React from "react";
import { Button, Dropdown, DropdownItemProps, Form, Grid, Loader, Message } from "semantic-ui-react";
import TextareaAutosize from "react-textarea-autosize";

import { BasicFormProps } from "../../../interfaces";
import { CategoriesServices, CoachService } from "../../../services";
import { Rules } from "../../../utils";

import { AddScriptCategoryComponent } from "./components/AddScriptCategoryComponent";

import "../../../styling/datepicker.scss";
import "./styles.scss";

interface OwnProps extends BasicFormProps {
  /**
   * Handle datePickers changes
   */
  handleSetFieldValue?: (field: string, value: any) => void;
  disabled?: boolean;
  isPlayerForm?: boolean;
}

type Props = OwnProps;
const dataElmId = "videoScriptDetail";
/**
 * Represents Video Script content.
 */
const VideoScriptDetailFC: React.FC<Props> = ({
  handleSetFieldValue,
  isSubmitting = false,
  messages,
  values,
  disabled = false,
  isPlayerForm = false,
}) => {
  const errors = React.useMemo(() => {
    return messages && messages.error && messages.error.length > 0;
  }, [messages]);
  const [categoryOptions, setCategoryOptions] = React.useState<DropdownItemProps[]>();
  const [coachCategories, setCoachCategories] = React.useState<string[]>([]);
  const [industryRoleOptions, setIndustryRoleOptions] = React.useState<DropdownItemProps[]>();
  const [displayAddCategoryModal, setDisplayAddCategoryModal] = React.useState<boolean>(false);

  const getCategories = async () => {
    const result = await CategoriesServices.get(values.coachId, "VIDEO_SCRIPT");
    setCoachCategories(result.map(c => c.name));
    setCategoryOptions(
      result.map(c => ({
        text: c.name,
        value: c.name,
      }))
    );
  };

  const getIndustryRoles = async () => {
    const result = await CoachService.industryRoles(values.coachId);
    setIndustryRoleOptions(
      result.map(ir => ({
        key: ir.name,
        text: ir.name,
        value: ir.name,
      }))
    );
  };

  React.useEffect(() => {
    if (isPlayerForm) return;

    getCategories().catch(console.error);
    getIndustryRoles().catch(console.error);
  }, [values.coachId, isPlayerForm]);

  const titleField = () => {
    return (
      <Form.Input
        key={"groupTitleInput"}
        label={"Title"}
        fluid
        id={"title"}
        type={"text"}
        name={"title"}
        placeholder={"Title"}
        onChange={(e, data) => {
          if (e.target.value.length < Rules.MAX_TITLE) {
            handleSetFieldValue!("title", e.target.value);
          }
        }}
        value={values.title}
      />
    );
  };

  const categoriesField = () => {
    return (
      <Form.Field>
        <label>Categories</label>
        <div className="categoriesDropdownAndButton">
          <Dropdown
            multiple={true}
            selection={true}
            value={values.categories}
            options={categoryOptions}
            onChange={(_, data) => {
              handleSetFieldValue!("categories", data.value);
            }}
          />
          <Button
            type="button"
            data-elm-id={dataElmId + "addCategoryBtn"}
            fluid
            compact
            className={"secondary bordered blue buttonRight"}
            onClick={() => setDisplayAddCategoryModal(true)}
          >
            Add New Category
          </Button>
        </div>
      </Form.Field>
    );
  };

  const industryRolesField = () => {
    return (
      <Form.Field>
        <label>Industry Roles</label>
        <Dropdown
          multiple={true}
          fluid
          selection={true}
          value={values.industryRoles}
          options={industryRoleOptions}
          onChange={(_, data) => {
            handleSetFieldValue!("industryRoles", data.value);
          }}
        />
      </Form.Field>
    );
  };

  const scriptField = () => {
    return (
      <Form.Field>
        <label>Script</label>
        <TextareaAutosize
          className={"scriptContent"}
          id="content"
          name="content"
          rows={3}
          value={values.content}
          onChange={data => {
            handleSetFieldValue!("content", data.currentTarget.value);
          }}
        />
      </Form.Field>
    );
  };

  return (
    <Form
      size="large"
      error={errors}
      className={disabled ? "videoScriptDetailsContainer disabledForm" : "videoScriptDetailsContainer"}
    >
      {isSubmitting && <Loader active size="large" />}

      <div key={"videoScriptFormDiv"} className={"whiteFormDiv"}>
        {messages && messages.error && messages.error.length > 0 && <Message error list={messages && messages.error} />}

        {titleField()}
        {isPlayerForm || categoriesField()}
        {isPlayerForm || industryRolesField()}
        {scriptField()}

        {displayAddCategoryModal && (
          <AddScriptCategoryComponent
            coachCategories={coachCategories}
            scriptCategories={values.categories}
            opened={displayAddCategoryModal}
            acceptHandler={category => {
              handleSetFieldValue!("categories", values.categories.concat(category));
              getCategories().catch(console.error);
              setDisplayAddCategoryModal(false);
            }}
            rejectHandler={() => setDisplayAddCategoryModal(false)}
          />
        )}
      </div>
    </Form>
  );
};

export const VideoScriptDetail = VideoScriptDetailFC;
