import {
  CoachBasic,
  CoachPermission,
  Fee,
  Media,
  PermissionLevel,
  PlayerAccount,
  PlayerSearchResponse,
  Profile,
  WebComponents,
  PlayerWithStats,
  SocialNetworksId,
  ConfigProperties,
  PlayerResumeStats,
  AdminWebSetting,
  WebSettings,
  MediaCategory,
} from "../interfaces";
import { isNil, find, sumBy } from "lodash";
import { some } from "lodash";
import { AppUserContext } from "../providers";

import { CoachSignUpStep, PlayerSignUpStep } from "../services";
import * as IT from "../interfaces";
import { Constants, URLS, cleanBrandInfoInLocalStorage, getBrandRedirectUrl } from "../utils";

export const phoneRegExp =
  /^((\\+[1]{1}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
export const phoneNumberMask = [/[1-9]/, /\d/, /\d/, "-", /\d/, /\d/, /\d/, "-", /\d/, /\d/, /\d/, /\d/];
export const webUrlRegExp = /^(https?:\/\/).*/; // /^(http\:\/\/|https\:\/\/)?(([a-z0-9][a-z0-9\-@]*\.)+[a-z0-9][a-z0-9\-]*((([/][a-z0-9][a-z0-9\-]*)+)*)+)+([/]?)$/;
export const emailRegExp =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export function fullNameFrom({ firstName, lastName }: PlayerSearchResponse | PlayerAccount): string {
  return (firstName + " " || "") + lastName || "";
}

export function userFullName({ firstName, lastName }: CoachBasic | Profile): string {
  return (firstName ? firstName + " " : "") + (lastName ? lastName : "");
}

export function getFullName(firstName?: string, lastName?: string): string {
  return (firstName ? firstName + " " : "") + (lastName ? lastName : "");
}
export function validEmail(email: string): boolean {
  return !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email);
}

export function validPhone(phone: string): boolean {
  return phoneRegExp.test(phone);
}

export const textWithLimit = (text: string | undefined, limit: number = 0): string => {
  const dots = "...";
  if (!text) {
    return "";
  }
  if (text && text.length > limit) {
    text = text.substring(0, limit) + dots;
  }

  return text;
};

export const getImageAsBase64 = (img: any, callback: any) => {
  const reader = new FileReader();
  reader.addEventListener("load", () => callback(reader.result));
  reader.readAsDataURL(img);
};

export const getFileData = (file: File) => {
  return new Promise(resolve => {
    getImageAsBase64(file, resolve);
  });
};

export const getIncome = (coachIncome: number, scFee: Fee, tdcFee: Fee): number => {
  if (isNil(scFee) || isNil(tdcFee)) {
    return 0;
  }
  return coachIncome - scFee.amount - (coachIncome * tdcFee.amount) / 100;
};

export const validIncome = (coachIncome: number, scFee: Fee, tdcFee: Fee): boolean => {
  return getIncome(coachIncome, scFee, tdcFee) > 0.01;
};

export const validRoute = (routes: string[], route: string): boolean => {
  return some(routes, appRoute => {
    const basePath = appRoute.split("/")[1];
    return basePath === route;
  });
};

export function fullName(user?: AppUserContext): string {
  return user?.profile.firstName + " " + user?.profile.lastName;
}

export function getExtension(fileName: string): string {
  return fileName.substr(fileName.lastIndexOf(".") + 1);
}

export function mediaVideoElement(uri: string, extension: string, id: number, sortOrder: number = 0): Media {
  return {
    category: "VIDEO",
    extension,
    id,
    sortOrder,
    type: extension,
    uri,
  };
}

export function mediaElement(
  uri: string,
  extension: string,
  category: MediaCategory,
  id: number = -1,
  sortOrder: number = 0
): Media {
  return {
    category,
    extension,
    id,
    sortOrder,
    type: extension,
    uri,
  };
}

export function mediaElementMerged(
  media: Media,
  uri: string,
  extension: string,
  category: MediaCategory,
  id: number = -1,
  sortOrder: number = 0
): Media {
  return {
    ...media,
    category,
    extension,
    id,
    sortOrder,
    type: extension,
    uri,
  };
}

export function mediaVideWithThumbnailElement(
  uri: string,
  extension: string,
  category: MediaCategory,
  thumbnailUri?: string,
  id: number = -1,
  sortOrder: number = 0
): Media {
  return {
    category,
    extension,
    id,
    sortOrder,
    type: extension,
    uri,
    thumbnailUri,
  };
}

export function mediaElementSticket(
  uri: string,
  extension: string,
  category: MediaCategory,
  sortOrder: number = 0
): Media {
  return {
    id: sortOrder,
    category,
    extension,
    sortOrder,
    type: extension,
    uri,
  };
}

export function finishStep(step?: string): boolean {
  return step ? step === CoachSignUpStep[CoachSignUpStep.COACH_PROMPTS] : false;
}

export function getPlayerValue(
  type: IT.PlayerStatisticType,
  playerReports: IT.PlayerReportStats[] | IT.PlayerCoachReportSummary[]
): number | undefined {
  if ((playerReports as IT.PlayerCoachReportSummary[])[0].statisticType) {
    return (playerReports as IT.PlayerCoachReportSummary[]).filter(s => s.statisticType === type)[0].percentageChange;
  } else if ((playerReports as IT.PlayerReportStats[])[0].statType) {
    return (playerReports as IT.PlayerReportStats[]).filter(s => s.statType === type)[0].statValue;
  }

  return undefined;
}

export function getValueWithSymbol(value?: number): string {
  const withDecimals = parseFloat(parseFloat(`${value}`).toFixed(2));
  return value && value > 0 ? `+${withDecimals}` : `${withDecimals}`;
}

export function getReducedConfig(config: ConfigProperties): ConfigProperties {
  return {
    iOSUrl: config.iOSUrl,
    androidUrl: config.androidUrl,
    post: config.post,
    socialNetworks: {
      twitter: {
        api_key: config.socialNetworks.twitter.api_key,
        callback: config.socialNetworks.twitter.callback,
        tokenUrl: config.socialNetworks.twitter.tokenUrl,
      },
      facebook: {
        api_key: config.socialNetworks.facebook.api_key,
      },
      linkedin: {
        api_key: config.socialNetworks.linkedin.api_key,
        callback: config.socialNetworks.linkedin.callback,
        permissions: config.socialNetworks.linkedin.permissions,
      },
      instagram: {
        api_key: config.socialNetworks.instagram.api_key,
        callback: config.socialNetworks.instagram.callback,
        permissions: config.socialNetworks.instagram.permissions,
      },
      tiktok: {
        api_key: config.socialNetworks.tiktok.api_key,
        app_id: config.socialNetworks.tiktok.app_id,
        callback: config.socialNetworks.tiktok.callback,
        permissions: config.socialNetworks.tiktok.permissions,
      },
    },
    dashboardYoutubeId: config.dashboardYoutubeId,
    firebase: {
      ...config.firebase,
    },
    questions: config.questions,
    questionsLimits: config.questionsLimits,
    stripe: {
      public_key: config.stripe.public_key,
    },
    vimeo: {
      access_token: config.vimeo.access_token,
      folder_id: config.vimeo.folder_id,
    },
    deployBaseUrl: config.deployBaseUrl,
    defaultComplianceDisclaimer: config.defaultComplianceDisclaimer,
    slugBaseUrl: config.slugBaseUrl,
    randomPrompts: {
      ...config.randomPrompts,
    },
    coachFacebookScopes: config.coachFacebookScopes,
    playerFacebookScopes: config.playerFacebookScopes,
    promptMaxLength: config.promptMaxLength,
    keywordMaxLength: config.keywordMaxLength,
    trialDays: config.trialDays,
    googleAnalyticsId: config.googleAnalyticsId,
    captchaSiteKey: config.captchaSiteKey,
  };
}

export function emptyField(value?: string): boolean {
  if (value === undefined) {
    return true;
  }
  return value.replace(/\s+/g, "").length === 0;
}

export function validateLong(minSize: number, maxSize: number, value?: string): boolean {
  if (value === undefined) {
    return false;
  }
  const valueWithOutSpaces = value.replace(/\s+/g, "");
  return valueWithOutSpaces.length >= minSize && valueWithOutSpaces.length <= maxSize;
}

export function removeAllHashtags(value: string): string {
  return value.replace(/#+/g, "");
}
export function removeMultipleWhiteSpace(value: string): string {
  return value.replace(/ +(?= )/g, "");
}
export function removeWhiteSpace(value: string): string {
  return value.replace(/\s+/g, "");
}

export function getStatusText(status: IT.SubscriptionStatus | IT.CoachStatuses | IT.VideoProjectStatus | string) {
  return status.valueOf().replace(/_/g, " ");
}

export function getSubscriptionType(subscriptionType?: IT.PricePlanTier) {
  if (subscriptionType === undefined) {
    return "";
  }

  switch (subscriptionType) {
    case IT.PricePlanTier.PLAYER_BASIC:
      return "Basic";
    case IT.PricePlanTier.PLAYER_PLUS:
      return "+";
  }
}

export function getLargeSubscriptionType(subscriptionType?: IT.PricePlanTier) {
  if (subscriptionType === undefined) {
    return "";
  }

  switch (subscriptionType) {
    case IT.PricePlanTier.PLAYER_BASIC:
      return "Basic";
    case IT.PricePlanTier.PLAYER_PLUS:
      return "SocialCoach+";
  }
}

/**
 * Returns MIME types that aren't "image/" or "video/"
 */
export function getValidFiles(files: File[]): File[] {
  return files.filter(file => {
    return file.type.startsWith("image/") || file.type.startsWith("video/");
  });
}

/**
 * Funtion to know if an image has the valid reels dimensions (Feed returns true always)
 * @param height
 * @param width
 * @param asReel
 * @returns true if the file has valid dimensions
 */
export function validDimensions(height: number, width: number, asReel: boolean = false): boolean {
  // Valid dimensions
  if (asReel) {
    return validReelsDimensions(height, width);
  } else {
    return true;
  }
}

export function validateMessageLenghtInSocialNetworks(message: string, postRestrictions: any): string[] {
  let socialNetworks: string[] = [];
  const socialNetworksNames = ["Facebook", "Instagram", "LinkedIn", "Twitter", "Tiktok"];
  socialNetworksNames.forEach(sn => {
    if (message.length > postRestrictions[`${sn.toLocaleLowerCase()}_max_length`]) {
      socialNetworks.push(sn);
    }
  });
  return socialNetworks;
}

export function validAspectRatio(height: number, width: number, asReel: boolean = false): boolean {
  // Valid Aspect Ratios: 16:9 - 4:5, 1:1 and 1.91:1
  if (asReel) {
    return validReelsAspectRatio(height, width);
  } else {
    const validHorizontalWidth = Math.round((16 * height) / 9);
    const validVerticalWidth = Math.round((4 * height) / 5);
    const validHorizontalSquare = Math.round((1.91 * height) / width);

    if (
      validHorizontalWidth === width ||
      validVerticalWidth === width ||
      height === width ||
      validHorizontalSquare === 1
    ) {
      return true;
    }
    return false;
  }
}

export function validReelsAspectRatio(height: number, width: number): boolean {
  // Valid Aspect Ratios: 9:16
  const validVerticalWidth = Math.round((9 * height) / 16);
  if (validVerticalWidth === width) {
    return true;
  }
  return false;
}

export function validReelsDimensions(height: number, width: number): boolean {
  if (height < 960 || height > 1920 || width < 540 || width > 1080) {
    return false;
  }
  return true;
}

export function availablePermission(permission: CoachPermission): boolean {
  return permission.level === "WRITE" || permission.level === "READ";
}

export function coachWithWritePermission(componentId: WebComponents, user?: AppUserContext): boolean {
  const coachPermissions = find(user?.permissions, { componentId });
  const isSuperCoach = !user?.isSubCoach!!;
  return coachPermissions?.level === "WRITE" || isSuperCoach;
}

export function coachWithReadPermission(componentId: WebComponents, user?: AppUserContext): boolean {
  const coachPermissions = find(user?.permissions, { componentId });
  const isSuperCoach = !user?.isSubCoach!!;
  const withPermission = coachPermissions ? PermissionLevel[coachPermissions!.level] >= PermissionLevel.READ : false;
  return withPermission || isSuperCoach;
}

export function redirectToPlayerStepWithRedirect(step: string): string {
  const redirectTo = getBrandRedirectUrl();

  if (redirectTo !== undefined) {
    cleanBrandInfoInLocalStorage();
    return redirectTo!!;
  } else {
    return redirectToPlayerStep(step);
  }
}

export function redirectToPlayerStep(step: string): string {
  let finalUrl = URLS.player.dashboard;

  switch (step) {
    case PlayerSignUpStep[PlayerSignUpStep.PLAYER_FINISH_SIGN_UP]:
      finalUrl = URLS.player.firstLogin;
      break;
    case PlayerSignUpStep[PlayerSignUpStep.PLAYER_PROFILE_DATA]:
      finalUrl = URLS.player.firstWebAddress;
      break;
    default:
      finalUrl = URLS.player.dashboard;
      break;
  }

  return finalUrl;
}

export function validateEmail(email: string): any {
  return email.match(emailRegExp);
}

export function getSocialNetworkTotal(
  playerResult: PlayerWithStats[],
  socialNetworkId: SocialNetworksId
): PlayerResumeStats {
  return {
    countPromptPosted: sumBy(playerResult, "statsBySocialNetwork." + socialNetworkId + ".countPromptPosted"),
    likes: sumBy(playerResult, "statsBySocialNetwork." + socialNetworkId + ".likes"),
    followers: sumBy(playerResult, "statsBySocialNetwork." + socialNetworkId + ".followers"),
    comments: sumBy(playerResult, "statsBySocialNetwork." + socialNetworkId + ".comments") + 0,
    shares: sumBy(playerResult, "statsBySocialNetwork." + socialNetworkId + ".shares") + 0,
    views: sumBy(playerResult, "statsBySocialNetwork." + socialNetworkId + ".views") + 0,
    click: sumBy(playerResult, "statsBySocialNetwork." + socialNetworkId + ".click"),
  };
}

export function getDefaultTotal(playerResult: PlayerWithStats[]): PlayerResumeStats {
  return {
    countPromptPosted: sumBy(playerResult, "countPromptPosted"),
    likes: sumBy(playerResult, "likes"),
    followers: sumBy(playerResult, "followers"),
    comments: sumBy(playerResult, "comments") + 0,
    shares: sumBy(playerResult, "shares") + 0,
    views: sumBy(playerResult, "views") + 0,
    click: sumBy(playerResult, "click"),
    statsBySocialNetwork: {
      FB: getSocialNetworkTotal(playerResult, "FB"),
      IG: getSocialNetworkTotal(playerResult, "IG"),
      LI: getSocialNetworkTotal(playerResult, "LI"),
      TW: getSocialNetworkTotal(playerResult, "TW"),
    },
  };
}

export function availableFunctionality(adminSettings: AdminWebSetting, functionality: WebSettings): boolean {
  const option = adminSettings.settings.find(s => s.webSettingId === functionality)?.status || "INACTIVE";
  return option === "ACTIVE";
}

export function removePrefix(word: string, prefix: string): string {
  if (word != null && prefix != null && word.startsWith(prefix)) {
    return word.substring(prefix.length);
  }
  return word;
}

export function capitalizeFirstLetter(str: string): string {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

export function removeElement(array: any[], element: any): any[] {
  const index = array.indexOf(element, 0);
  if (index > -1) {
    array.splice(index, 1);
  }

  return array;
}

export function validatePendingAck(vp: IT.AkcElement[] | undefined, role: string): boolean {
  if (vp === undefined) {
    return false;
  }
  return vp.some(akc => akc.ackFlag === false && akc.userRole === role);
}
