import * as React from "react";
import { Formik } from "formik";
import { useIntl } from "react-intl";
import { RouteComponentProps, withRouter } from "react-router";
import { get } from "lodash";

import { SocialCoachSessionService, SubCoachesServices } from "../../../services";
import { validationSchema } from "./validations";
import { UserRegistered, WebComponents, SubCoachSegmentationRequest, SegmentationDetail } from "../../../interfaces";
import { AppContext } from "../../../providers";
import { SubCoachSignUp } from "../../../components/SubCoachSignUp";
import { permissionsByCoachType } from "./permissions";
import { toCoachPermission, coachWithWritePermission } from "../../../utils";

import { InternalGrid } from "../../../styling/baseStyle";

export interface OwnProps {
  formSubmitRef?: any;

  successHandler: (user: UserRegistered) => void;
  errorHandler: () => void;
  startLoading: () => void;
}

type Props = OwnProps & RouteComponentProps;

const SubCoachRegisterFC: React.FC<Props> = ({ formSubmitRef, startLoading, successHandler, errorHandler }) => {
  const { userContext } = React.useContext(AppContext);
  const coachId = get(userContext, "coach.id", "");

  const basicCoach = userContext?.coach?.type && userContext?.coach?.type === "BASIC";
  const withWritePermission = coachWithWritePermission(WebComponents.ADMIN, userContext);
  const permissions = permissionsByCoachType(basicCoach);

  return (
    <InternalGrid>
      <Formik
        validateOnBlur={false}
        validateOnChange={true}
        initialValues={{
          firstName: "",
          lastName: "",
          email: "",
          password: "",
          branches: [],
          states: [],
          regions: [],
          permissions: { ...permissions },
        }}
        validationSchema={validationSchema}
        onSubmit={async (values, { setSubmitting, setErrors, validateForm }) => {
          const { firstName, lastName, email, password, branches, regions, states } = values;
          try {
            const errors = await validateForm({
              firstName,
              lastName,
              email,
              password,
            });

            if (errors && Object.values(errors).length > 0) {
              setErrors(errors);
            } else {
              startLoading();
              const permissionsArray = toCoachPermission(values.permissions);
              const response = await SocialCoachSessionService.createSubCoach(
                {
                  password: password!,
                  firstName: firstName!,
                  lastName: lastName!,
                  email: email!,
                  phone: userContext?.profile.phone,
                  permissions: permissionsArray,
                },
                userContext!.coach!.id!
              );
              alert("User created successfully");
              if (response.user) {
                const branchesToSave = branches.map(branchId => {
                  return {
                    segmentationDetailId: Number(branchId),
                    coachId,
                    type: "BRANCH",
                  } as SegmentationDetail;
                });

                const regionsToSave = regions.map(branchId => {
                  return {
                    segmentationDetailId: Number(branchId),
                    coachId,
                    type: "REGION",
                  } as SegmentationDetail;
                });

                const segmentation: SubCoachSegmentationRequest = {
                  userId: response!!.user!!.userId!!,
                  segmentation: branchesToSave.concat(regionsToSave),
                };

                await SubCoachesServices.setSegmentation(coachId, segmentation);
              }

              successHandler(response);
            }
          } catch (e) {
            setErrors({
              email: String(e) || "",
              password: undefined,
              firstName: undefined,
              lastName: undefined,
            });
            errorHandler();
          }
          setSubmitting(false);
        }}
      >
        {({ values, errors, handleChange, handleSubmit, setFieldValue, isSubmitting }) => (
          <SubCoachSignUp
            messages={{ error: Object.values(errors) as string[] }}
            coachId={coachId}
            userId={undefined}
            handleChange={handleChange}
            handleSubmit={handleSubmit}
            formSubmitRef={formSubmitRef}
            handleSetFieldValue={setFieldValue}
            basicCoach={basicCoach}
            isSubmitting={isSubmitting}
            withWritePermission={withWritePermission}
            values={values}
          />
        )}
      </Formik>
    </InternalGrid>
  );
};

export const SubCoachRegisterForm = withRouter(SubCoachRegisterFC);
