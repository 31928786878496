import { MediaGroup } from "./Prompt";
import { PostKeyphrase, PostReviewEvent, SocialNetworksId, TiktokPostSetup } from "..";

export type SendModeType = "IMMEDIATE" | "SCHEDULED";

export enum SocialNetworkPostStatus {
  PENDING = "PENDING",
  POSTED = "POSTED",
  FAILED = "FAILED",
  REQUEST_CHANGES = "REQUEST_CHANGES",
}

export enum ReviewStatus {
  REVIEWED = "REVIEWED",
  COMPLETED = "COMPLETED",
  REQUEST_CHANGES = "REQUEST_CHANGES",
  NO_REVIEWED = "NULL",
  CHANGES_SUBMITTED = "CHANGES_SUBMITTED",
}

export const POST_REVIEW_STATUS_NAMES = {
  REVIEWED: "Review Completed",
  REQUEST_CHANGES: "Changes Requested",
  COMPLETED: "Review Completed",
  NULL: "In Review",
  CHANGES_SUBMITTED: "In Review",
};

export interface Post {
  postId: number;
  playerId: number;
  promptId?: number;
  videoProjectId?: number;
  message: string;
  mediaGroup?: MediaGroup;
  socialNetworks: SocialNetworkPosted[];
  keyphrases: PostKeyphrase[];
  coachPostReviewStatus?: ReviewStatus;
  coachPostReviewStatusUpdatedDate: string;
  postReviewRequestedByFullName?: string;
  playerBranch?: string;
  playerRegion?: string;
  postReviewRequestedBy?: number;
  shortLink?: string;
  hashtags?: string;
  complianceDisclaimer?: string;
  postReviewEvents?: PostReviewEvent[];
}

export interface SocialNetworkMessage {
  code: string;
  socialNetworkMessage?: string;
  postedCaption?: string;
  socialNetworkId?: SocialNetworksId;
  failedReason?: string;
  status?: string;
  postedTimestamp?: string;
  createdDate?: string;
  posted?: boolean;
  externalPostUrl?: string;
  caption?: string;
}
export interface PostRequest {
  coachId: string;
  playerIds: number[];
  promptId: number;
  messageToPost: string;
  sendMode: SendModeType;
  scheduledDatetimeUtc?: string;
  socialNetworkMessages: SocialNetworkMessage[];
  isAutoPost: boolean;
  brandId?: string;
}

export interface PostWithBrandRequest {
  coachId?: string;
  playerIds?: number[];
  brandId?: string;
  socialNetworkMessages: SocialNetworkMessage[];
  mediaGroup: MediaGroup;
  messageToPost?: string;
  sendMode?: string; // SendModeType;
  scheduledDatetimeUtc?: string;
  isAutoPost?: boolean;
  allowHashtags?: boolean;
  allowComplianceDisclaimer?: boolean;
  allowShortLink?: boolean;
}

export interface PostPlayerRequest {
  coachId?: string;
  playerId?: number;
  promptId?: number;
  postId?: number;
  mediaGroup?: MediaGroup;
  messageToPost: string;
  isAutoPost?: boolean;
  sendMode?: SendModeType;
  scheduledDatetimeUtc?: string;
  socialNetworkMessages: SocialNetworkMessage[];
}

export interface SocialNetworkPosted {
  socialNetworkId: SocialNetworksId;
  status: string;
  caption?: string;
  postedTimestamp: string;
  externalPostUrl?: string;
  secondaryExternalPostUrl?: string;
}

export interface MessageChat {
  type: "LEFT" | "RIGHT";
  owner?: string;
  date?: string;
  message: string;
}
interface ChatBoxProps {
  title?: string;
  messages: MessageChat[];
  style?: any;

  onPressLink?: (href: string) => void;
}
