import * as React from "react";
import { useIntl } from "react-intl";
import { useFormikContext } from "formik";
import { Form, Grid, Radio } from "semantic-ui-react";

import { ConfigContext, ConfigContextProvider } from "../../../../contexts/appContexts";
import { CaptionField } from "../../../../components/Inputs/CaptionField";
import { BrandMediaUploaderComponent } from "../../../../components/BrandMediaUploader";
import { BasicFormProps, ConfigProperties, Media } from "../../../../interfaces";
import defaultConfigProperties from "../../../../defaultConfigProperties";

import { PredictionsComponent } from "../../../../components/PredictionsComponent";

import { BrandPostComponentType, descriptors } from "./descriptors";
import "./styles.scss";

interface OwnProps extends BasicFormProps {
  showHashtagToggle: boolean;
  showComplianceToggle: boolean;
  isCoach: boolean;
  brandId: string;
  blockedNamesList: string[];
  flaggedNamesList: string[];
  /**
   * Handle attached file is uploaded
   */
  handleMediaUpdated: (newMedia: Media[]) => void;
  handleAttachedFileUploadStarted: () => void;
  handleAttachedFileUploadFinished: () => void;

  /**
   * Handle datePickers changes
   */
  handleSetFieldValue?: (field: string, value: any) => void;
}

type Props = OwnProps;

/**
 * Represents a PostInfoStep
 */
const PostInfoStepFC: React.FC<Props> = ({
  handleSetFieldValue,
  handleAttachedFileUploadStarted,
  handleAttachedFileUploadFinished,
  handleMediaUpdated,
  blockedNamesList = [],
  flaggedNamesList = [],
  brandId,
  showHashtagToggle,
  showComplianceToggle,
  isCoach,
}) => {
  const config: ConfigProperties = React.useContext(ConfigContext);
  const { formatMessage } = useIntl();
  const { firebase } = React.useContext(ConfigContext);
  const formik = useFormikContext();
  const values: any = formik.values;

  // const [hashtags, setHashtags] = React.useState<string>(values.hashtags);
  const [showAIModal, setShowAIModal] = React.useState<boolean>(false);

  React.useEffect(() => {
    return () => {
      window.removeEventListener("loadedmetadata", () => {
        // Nothing
      });
    };
  }, []);

  const blockedWords = (messageToPost: string): string[] => {
    const content: string[] = [];

    if (messageToPost && messageToPost.length > 0) {
      content.push(messageToPost);
    }
    return blockedNamesList.filter(blockedPhrase => {
      const blockedRegex = new RegExp("\\b" + blockedPhrase + "\\b", "i");
      return content.some(stringToCheck => {
        return blockedRegex.test(stringToCheck);
      });
    });
  };

  const flaggedWords = (messageToPost: string): string[] => {
    const content: string[] = [];

    if (messageToPost && messageToPost.length > 0) {
      content.push(messageToPost);
    }
    return flaggedNamesList.filter(flaggedPhrase => {
      const blockedRegex = new RegExp("\\b" + flaggedPhrase + "\\b", "i");
      return content.some(stringToCheck => {
        return blockedRegex.test(stringToCheck);
      });
    });
  };

  const settingsSection = (): JSX.Element => {
    const { allowShortLink, allowHashtags, allowComplianceDisclaimer } = values;

    const settings: any[] = [];

    if (showHashtagToggle) {
      settings.push({
        label: formatMessage(
          { ...descriptors[BrandPostComponentType.allowHashtags] },
          {
            owner: isCoach ? "player's" : "my",
          }
        ),
        checked: allowHashtags,
        id: "allowHashtags",
      });
    }

    settings.push({
      label: formatMessage(
        { ...descriptors[BrandPostComponentType.allowShortLink] },
        {
          owner: isCoach ? "player's" : "my",
        }
      ),
      checked: allowShortLink,
      id: "allowShortLink",
    });

    if (showComplianceToggle) {
      settings.push({
        label: formatMessage({ ...descriptors[BrandPostComponentType.allowComplianceDisclaimer] }),
        checked: allowComplianceDisclaimer,
        id: "allowComplianceDisclaimer",
      });
    }

    return (
      <React.Fragment>
        <Grid padded>
          <Grid.Column floated="right" width={10} className={"noPadding"}>
            <Form.Group grouped key={"postBrandPermissionsGroup"} className={"floatedRight postSettings"}>
              {settings.map(appSetting => {
                return (
                  <div key={"postBrandPermissions" + appSetting.id}>
                    <Form.Group inline key={appSetting.id} className={"floatedRight"}>
                      <label className="small">{appSetting.label}</label>
                      <Radio
                        toggle
                        className={"settingsLevelLabel"}
                        onChange={(e: any, data: any) => {
                          handleSetFieldValue!(data.name, data.checked);
                        }}
                        name={appSetting.id}
                        checked={appSetting.checked}
                      />
                    </Form.Group>
                  </div>
                );
              })}
            </Form.Group>
          </Grid.Column>
        </Grid>
      </React.Fragment>
    );
  };

  // const hashtagTextField = (
  //   <>
  //     <label>{formatMessage({ ...descriptors[BrandPostComponentType.hashtags] })}</label>
  //     {hashtagsListError && hashtagsListError.length > 0 && (
  //       <Grid className={"paddingTop leftPadding"}>
  //         {hashtagsListError.map(error => (
  //           <Grid.Row className={"noPadding"} columns={1}>
  //             <div className="labelWithError">{error}</div>
  //           </Grid.Row>
  //         ))}
  //       </Grid>
  //     )}
  //     <Grid className={"marginBottom " + (hashtagsListError && hashtagsListError?.length > 0 ? "" : "paddingTop")}>
  //       <HashTagSet
  //         title={"Hashtags..."}
  //         coachId={coachId}
  //         onError={setHashtagsListError}
  //         customTagStyle={`background: rgba(61, 174, 245, 0.2);`}
  //         defaultHashtagList={hashtags}
  //         onSuccess={newTags => {
  //           const hashtagsString = newTags.reduce(
  //             (accumulator, hashtag) => accumulator + "#" + hashtag.displayValue + " ",
  //             ""
  //           );
  //           if (newTags.length <= 30) {
  //             handleSetFieldValue!("hashtags", hashtagsString);
  //             handleSetFieldValue!("withMessage", values.messageToPost + "\n" + hashtagsString);
  //           }
  //         }}
  //       />
  //     </Grid>
  //   </>
  // );

  return (
    <>
      <div key={"brandPostFormDiv"} className={"whiteFormDiv brandedPostInfo"}>
        <div className={"attachVideosSection"}>
          <ConfigContextProvider value={defaultConfigProperties}>
            <BrandMediaUploaderComponent
              brandId={brandId}
              firebase={firebase}
              handleMediaUpdated={handleMediaUpdated}
              handleAttachedFileUploadFinished={handleAttachedFileUploadFinished}
              handleAttachedFileUploadStarted={handleAttachedFileUploadStarted}
              originalMedia={values.mediaGroup?.media || []}
            />
          </ConfigContextProvider>
        </div>

        <CaptionField
          title={formatMessage({ ...descriptors[BrandPostComponentType.message] })}
          messagePlaceholder={formatMessage({ ...descriptors[BrandPostComponentType.messagePlaceholder] })}
          aiButtonText="Generate Caption"
          currentValue={values.messageToPost}
          dataElmId="brandPostCaption"
          disabled={false}
          handleSetFieldValue={(field, value) => {
            const blocked = blockedWords(value).join(", ");
            const flagged = flaggedWords(value).join(", ");
            handleSetFieldValue!!("blockedWords", blocked);
            handleSetFieldValue!!("flaggedWords", flagged);

            handleSetFieldValue!!(field, value);
          }}
          setShowAIModal={setShowAIModal}
          hashtags={""}
          id="messageToPost"
        />
        <br />

        {/* Hashtags */}
        {/* {hashtagTextField} */}

        {/* Settings */}
        {settingsSection()}
      </div>

      {showAIModal && (
        <PredictionsComponent
          opened={true}
          rejectHandler={() => {
            setShowAIModal(false);
          }}
          acceptHandler={(prediction, addedHashtags) => {
            setShowAIModal(false);
            handleSetFieldValue!("messageToPost", prediction);

            // if (addedHashtags && addedHashtags.length > 0) {
            //   const newTags: string[] = [];
            //   if (hashtags.length > 0) {
            //     hashtags.split(" ").forEach(tag => newTags.push(tag));
            //   }

            //   addedHashtags.forEach(tag => {
            //     if (newTags.indexOf(tag) === -1) {
            //       newTags.push(tag);
            //     }
            //   });
            //   setHashtags(newTags.join(" "));
            // }
          }}
        />
      )}
    </>
  );
};

export const PostInfoStep = PostInfoStepFC;
