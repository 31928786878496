import * as React from "react";
import { Grid, Icon } from "semantic-ui-react";
import Linkify from "react-linkify";
import { CustomModal } from "../../../../CustomModal";
import { VideoProjectServices } from "../../../../../services";
import { ClientNotes } from "../../../../../interfaces";
import TextareaAutosize from "react-textarea-autosize";

import "./styles.scss";
import { useEffect, useMemo, useState } from "react";

interface Props {
  playerId?: number;
  coachId?: string;
  playerNotes?: ClientNotes;
  coachNotes?: ClientNotes;
  onUpdate: (pNotes?: ClientNotes, cNotes?: ClientNotes) => void;
}

const ClientNotesFC: React.FC<Props> = ({ playerId, coachId, playerNotes, coachNotes, onUpdate }) => {
  const [showNotesModal, setShowNotesModal] = useState<boolean>(false);
  const [playerNotesText, setPlayerNotesText] = useState<string>("");
  const [coachNotesText, setCoachNotesText] = useState<string>("");

  const combinedNotes = useMemo(() => {
    if (coachNotes?.notes && playerNotes?.notes) {
      return `${coachNotes.notes}\n\n${playerNotes.notes}`;
    } else if (coachNotes?.notes) {
      return coachNotes.notes;
    } else if (playerNotes?.notes) {
      return playerNotes.notes;
    } else {
      return "";
    }
  }, [playerNotes?.notes, coachNotes?.notes]);

  useEffect(() => {
    setPlayerNotesText(playerNotes?.notes || "");
  }, [playerNotes?.notes]);

  useEffect(() => {
    setCoachNotesText(coachNotes?.notes || "");
  }, [coachNotes?.notes]);

  const updateClientNotes = async () => {
    const savedPlayerNotes =
      playerId && (playerNotesText || playerNotes)
        ? await VideoProjectServices.addClientNotes(playerId, playerNotesText)
        : undefined;
    const savedCoachNotes =
      coachId && (coachNotesText || coachNotes)
        ? await VideoProjectServices.addCoachClientNotes(coachId, coachNotesText)
        : undefined;

    onUpdate(savedPlayerNotes, savedCoachNotes);
    setShowNotesModal(false);
  };

  return (
    <>
      <Grid className={"notesSection"}>
        <Grid.Row>
          <label className={"sectionHeader"}>
            Client Notes
            <span
              className={"addClientNotesBtn"}
              data-elm-id={"addClientNotesBtn"}
              onClick={() => setShowNotesModal(true)}
            >
              {!combinedNotes ? "Add" : "Edit"}
            </span>
          </label>
        </Grid.Row>

        {!!combinedNotes && (
          <Grid.Row className="clientNotesClickable">
            <Linkify
              componentDecorator={(decoratedHref, decoratedText, key) => (
                <a target="blank" className={"clientNotesLink"} href={decoratedHref} key={key}>
                  {decoratedText}
                </a>
              )}
            >
              {combinedNotes}
            </Linkify>
          </Grid.Row>
        )}
      </Grid>

      {showNotesModal && (
        <CustomModal
          title={`Edit Client Notes`}
          acceptLabel="Save"
          acceptHandler={updateClientNotes}
          rejectHandler={() => setShowNotesModal(false)}
        >
          <label>
            <b>Coach Notes (for all players in this account)</b>
          </label>
          <TextareaAutosize
            data-elm-id={"notesInput"}
            className="clientNotesField"
            placeholder={"Add client notes..."}
            rows={3}
            value={coachNotesText}
            onChange={data => {
              setCoachNotesText(data.currentTarget.value);
            }}
          />

          <label>
            <b>Player Notes</b>
          </label>
          <TextareaAutosize
            data-elm-id={"notesInput"}
            className="clientNotesField"
            placeholder={"Add client notes..."}
            rows={3}
            value={playerNotesText}
            onChange={data => {
              setPlayerNotesText(data.currentTarget.value);
            }}
          />
        </CustomModal>
      )}
    </>
  );
};

export const ClientNotesComponent = ClientNotesFC;
