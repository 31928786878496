import * as React from "react";
import { RouteComponentProps, withRouter } from "react-router";
import { Formik } from "formik";
import qs from "qs";

import { CoachingProfileService, SocialCoachSessionService } from "../../../services";
import { ChangePassword } from "../../../components";
import { validationSchema } from "./validations";
import { URLS } from "../../../utils";

interface OwnProps {
  password?: string;
  confirmPassword?: string;
  isPlayer: boolean; // TODO: REMOVE
}

interface PathProps {
  userId: string;
  actionToken: string;
}

type Props = OwnProps & RouteComponentProps<PathProps>;

const IntegratedChangePassword: React.FC<Props> = ({
  history,
  isPlayer,
  location,
  password = "",
  confirmPassword = "",
  match: {
    params: { userId, actionToken },
  },
}) => {
  const [company, setCompany] = React.useState<string>("");

  const [likePlayer, setLikePlayer] = React.useState<boolean>(isPlayer);
  const [successMessage, setSuccessMessage] = React.useState<string>();
  const [isSuccess, setIsSuccess] = React.useState(false);

  React.useEffect(() => {
    const coachId = Number(qs.parse(location.search, { ignoreQueryPrefix: true }).coachId);
    if (coachId) {
      getPublicInfo(coachId.toString());
      setLikePlayer(true);
    } else {
      setLikePlayer(false);
    }
  }, []);

  const getPublicInfo = async (coachId: string) => {
    await CoachingProfileService.coachPublicInfoByCoachId(coachId)
      .then(response => {
        setCompany(response.companyName || "");
      })
      .catch(e => {
        setCompany("");
      });
  };

  const changeForm = (): JSX.Element => {
    return (
      <Formik
        validateOnBlur={true}
        validateOnChange={true}
        initialValues={{ password, confirmPassword }}
        validationSchema={validationSchema}
        onSubmit={async (
          { password: pwd, confirmPassword: confirmpwd },
          { setSubmitting, setErrors, validateForm }
        ) => {
          try {
            setSubmitting(true);
            const errors = await validateForm({ password: pwd, confirmPassword: confirmpwd });
            if (errors && Object.values(errors).length > 0 && actionToken && userId) {
              setErrors(errors);
            } else {
              setSubmitting(true);
              await SocialCoachSessionService.changePassword(userId, actionToken, pwd!);
              setSubmitting(false);
              setSuccessMessage("Password changed successfully!");
              setIsSuccess(true);
              setTimeout(() => {
                history.push(URLS.login);
              }, 3000);
            }
          } catch (e) {
            const errorString = String(e);
            if (errorString.includes("expired")) {
              if (confirm("Your link has expired. Please request a new link")) {
                history.push(URLS.forgotPassword);
              }
            } else {
              setErrors({
                password: errorString,
                confirmPassword: undefined,
              });
            }
          }
        }}
      >
        {({ errors, handleChange, handleSubmit, isSubmitting }) => (
          <ChangePassword
            subtitle={successMessage}
            messages={{
              error: Object.values(errors) as string[],
            }}
            isPlayer={likePlayer}
            companyName={company}
            handleChange={handleChange}
            handleSubmit={handleSubmit}
            isSubmitting={isSubmitting}
            isSuccess={isSuccess}
          />
        )}
      </Formik>
    );
  };
  return changeForm();
};

export const ChangePasswordForm = withRouter(IntegratedChangePassword);
