import * as React from "react";
import { useIntl } from "react-intl";
import { DropdownItemProps, Grid, Select } from "semantic-ui-react";

import { ControlOption, SliderComponent } from "./components";
import { descriptors, RecordPageType } from "../../descriptors";
import "./styles.scss";

interface Props {
  disableCameraChanges: boolean;
  onChangeSpeed(speed: number): void;
  onChangeTextSize(textSize: number): void;

  onChangeCamera(deviceId: string): void;
  onChangeMicrophone(deviceId: string): void;

  cameraOptions: DropdownItemProps[];
  microphoneOptions: DropdownItemProps[];
  selectedCamera: string | undefined;
  selectedMicrophone: string | undefined;

  aspectRatio: number;
  onAspectRatioChange: (ar: number) => void;
  canChangeAspectRatio?: boolean;
}

const ControlSectionFC: React.FC<Props> = ({
  disableCameraChanges,
  onChangeSpeed,
  onChangeTextSize,
  onChangeCamera,
  onChangeMicrophone,
  cameraOptions,
  microphoneOptions,
  selectedCamera,
  selectedMicrophone,
  aspectRatio,
  onAspectRatioChange,
  canChangeAspectRatio,
}) => {
  const { formatMessage } = useIntl();

  const [speed, setSpeed] = React.useState<number>(15);
  const [textSize, setTextSize] = React.useState<number>(23);

  const BASE_MS = 65;
  const MAX_SPEED = 55;
  const MIN_SPEED = 0;

  const aspectRatioOptions: DropdownItemProps[] = [
    { text: "Vertical", value: 9 / 16 },
    { text: "Horizontal", value: 16 / 9 },
  ];

  React.useEffect(() => {
    onChangeSpeed(BASE_MS - speed);
  }, [speed]);

  React.useEffect(() => {
    onChangeTextSize(textSize);
  }, [textSize]);

  return (
    <Grid.Row>
      <ControlOption title={formatMessage({ ...descriptors[RecordPageType.textSubtitle] })}>
        <SliderComponent min={15} max={36} name="textSize" onChange={setTextSize} step={1} value={textSize} />
      </ControlOption>

      <ControlOption title={formatMessage({ ...descriptors[RecordPageType.autoScrollSubtitle] })}>
        <SliderComponent min={MIN_SPEED} max={MAX_SPEED} name="speed" onChange={setSpeed} step={5} value={speed} />
      </ControlOption>

      {cameraOptions && cameraOptions.length > 0 && (
        <ControlOption title={formatMessage({ ...descriptors[RecordPageType.cameraSubtitle] })}>
          <Select
            placeholder={formatMessage({ ...descriptors[RecordPageType.cameraSubtitle] })}
            onChange={(_, data) => onChangeCamera(`${data.value}`)}
            options={cameraOptions}
            value={selectedCamera}
            disabled={disableCameraChanges}
          />
        </ControlOption>
      )}

      <ControlOption title={"Orientation"}>
        <Select
          placeholder={"Choose an aspect ratio"}
          onChange={(_, data) => onAspectRatioChange(Number(data.value))}
          options={aspectRatioOptions}
          value={aspectRatio}
          disabled={canChangeAspectRatio}
        />
      </ControlOption>

      {microphoneOptions && microphoneOptions.length > 0 && (
        <ControlOption title={formatMessage({ ...descriptors[RecordPageType.microphoneSubtitle] })}>
          <Select
            placeholder={formatMessage({ ...descriptors[RecordPageType.microphoneSubtitle] })}
            onChange={(_, data) => onChangeMicrophone(`${data.value}`)}
            options={microphoneOptions}
            value={selectedMicrophone}
            disabled={disableCameraChanges}
          />
        </ControlOption>
      )}
    </Grid.Row>
  );
};

export const ControlSection = ControlSectionFC;
