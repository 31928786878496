import * as React from "react";
import { Field, FieldProps } from "formik";
import MaskedInput from "react-text-mask";
import { FormattedMessage, useIntl } from "react-intl";
import { Form, Grid, Image, Loader, Header, Dropdown, DropdownItemProps } from "semantic-ui-react";
import ImgCrop from "antd-img-crop";
import { Upload } from "antd";

import { Svg } from "../../../../assets";
import { BasicFormProps } from "../../../../interfaces";
import { phoneNumberMask, getImageAsBase64 } from "../../../../utils";

import { descriptors, ProfileInformationType } from "./descriptors";
import { fields } from "./formFields";
import "../../../../styling/antd.scss";
import "./styles.scss";

interface OwnProps extends BasicFormProps {
  messages?: { info?: string[]; error?: string[]; warn?: string[] };
  isSubmitting?: boolean;
  onChangeImage: (img: File) => void;
  availableIndustryRoles?: string[];
}

type Props = OwnProps;

const ProfileInformationClass: React.FC<Props> = ({
  values,
  isSubmitting,
  handleChange,
  onChangeImage,
  availableIndustryRoles,
}) => {
  const [playerPictureFile, setPlayerPictureFile] = React.useState<string | undefined>();
  const [userWithPicture] = React.useState<boolean>(!!values.photoUrl);
  const [industryRoleOptions, setIndustryRoleOptions] = React.useState<DropdownItemProps[]>([]);

  React.useEffect(() => {
    if (!availableIndustryRoles) return;

    setIndustryRoleOptions(
      availableIndustryRoles.map(el => ({
        key: el,
        text: el,
        value: el,
      }))
    );
  }, [availableIndustryRoles]);

  const { formatMessage } = useIntl();
  return (
    <Grid.Column floated="left" width={8} className="playerProfileInformation">
      <Header as="h4" textAlign={"center"}>
        <FormattedMessage {...descriptors[ProfileInformationType.titleSection]} />
      </Header>
      {isSubmitting && <Loader active size="large" />}

      <ImgCrop
        showGrid
        onModalOk={value => {
          getImageAsBase64(value, (base64Image: any) => {
            setPlayerPictureFile(base64Image);
            onChangeImage(value as File);
          });
        }}
      >
        <Upload
          action={(): string => {
            return Svg.UserBorder;
          }}
          showUploadList={false}
          listType="picture"
          className="avatar-uploader"
        >
          <>
            <div className={!userWithPicture ? "uploadPictureContainer" : "uploadPictureContainer noBackground"}>
              {playerPictureFile ? (
                <Image src={playerPictureFile} className="profilePicture" />
              ) : (
                <>
                  {" "}
                  <Image
                    src={values.photoUrl || Svg.UserBorder}
                    className={!userWithPicture ? "defaultPicture" : "profilePicture"}
                  />
                  {!userWithPicture && (
                    <div className="profileCameraIconContainer">
                      <Image src={Svg.Camera} className="cameraIcon" />
                    </div>
                  )}
                </>
              )}
            </div>
            <div className="titleDIVSection">
              <FormattedMessage
                {...descriptors[
                  ProfileInformationType[playerPictureFile || userWithPicture ? "changePicture" : "uploadPicture"]
                ]}
              />
            </div>
          </>
        </Upload>
      </ImgCrop>

      {fields.map((field, index) => {
        return (
          <Form.Input
            key={field.id + index}
            data-elm-id={"profileInformationInput" + field.id}
            id={field.id}
            label={field.label}
            type={field.type}
            name={field.name}
            placeholder={formatMessage({ ...descriptors[field.placeholder] })}
            onChange={handleChange}
            value={values[field.id]}
            disabled={isSubmitting}
          />
        );
      })}

      <div className="field">
        <label htmlFor="phoneNumber">Phone Number</label>
        <Field name="phoneNumber" data-elm-id={"profileInformationPhoneNumber"}>
          {({ field }: FieldProps<any>) => (
            <MaskedInput
              {...field}
              mask={phoneNumberMask}
              id="phoneNumber"
              placeholder={formatMessage({ ...descriptors[ProfileInformationType.phoneNumber] })}
              type="text"
              onChange={handleChange}
              disabled={isSubmitting}
            />
          )}
        </Field>
      </div>

      <div className="field">
        <label htmlFor="industryRole">Industry Role</label>
        <Field name="industryRole">
          {({ field }: FieldProps<any>) => (
            <Dropdown
              options={industryRoleOptions}
              {...field}
              onChange={(_e, data) => field.onChange({ target: { id: "industryRole", value: data.value } })}
              clearable
              icon={values.industryRole ? undefined : ""}
              placeholder={formatMessage({ ...descriptors[ProfileInformationType.industryRole] })}
              fluid
              selection
              disabled={isSubmitting || availableIndustryRoles === undefined}
            />
          )}
        </Field>
      </div>
    </Grid.Column>
  );
};

export const PlayerProfileInformation = ProfileInformationClass;
