import * as React from "react";
import { Modal, Button, Loader, Progress } from "semantic-ui-react";

import "./styles.scss";
import { ReactNode } from "react";
import { RadioCardGroup, RadioCardOption } from "../RadioCardGroup";

interface OwnProps {
  open: boolean;
  title: string;
  options: RadioCardOption[];
  warningComponent?: ReactNode;
  disabled?: boolean;
  loadingComponent?: ReactNode;

  onClose?: () => void;
  onCancel?: () => void;
  cancelLabel?: string;
  selectedOption: string | undefined;
  onChange: (key: string) => void;
  onSelect: (key: string) => void;
}

type Props = OwnProps;

const ChoicesModalFC: React.FC<Props> = ({
  open,
  title,
  options,
  selectedOption,
  loadingComponent,
  warningComponent,
  disabled,
  onClose,
  onCancel,
  cancelLabel,
  onChange,
  onSelect,
}) => {
  return (
    <Modal open={open} onClose={onClose} className={"choicesModal"}>
      <Modal.Header>{title}</Modal.Header>

      <Modal.Description className={"modalDescription"}>
        {loadingComponent}
        {!loadingComponent && (
          <RadioCardGroup
            options={options}
            selectedOption={selectedOption}
            onChange={onChange}
            warningComponent={warningComponent}
          />
        )}
      </Modal.Description>

      <Modal.Actions>
        <Button
          data-elm-id={`confirmationModalNoBtn`}
          className={"secondary rounded padded"}
          onClick={() => {
            if (onCancel) onCancel();
            else if (onClose) onClose();
          }}
        >
          {cancelLabel || "Cancel"}
        </Button>
        <Button
          data-elm-id={`confirmationModalYesBtn`}
          className={"primary rounded"}
          onClick={() => selectedOption && onSelect(selectedOption)}
          disabled={disabled || !selectedOption}
        >
          Next
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

export const ChoicesModal = ChoicesModalFC;
