import { defineMessages } from "react-intl";

export enum VideoProjectDetailsPageType {
  title = "VideoProjectDetailsPageType.title",
  cancelBtn = "VideoProjectDetailsPageType.cancel",

  ownerBoxTitle = "VideoProjectDetailsPageType.ownerBoxTitle",
  inputPlaceHolder = "VideoProjectDetailsPageType.inputPlaceHolder",
  disableNote = "VideoProjectDetailsPageType.disableNote",
  projectInfoLabel = "VideoProjectDetailsPageType.projectInfoLabel",
  preferencesTitle = "VideoProjectDetailsPageType.preferencesTitle",
  postNowBtn = "VideoProjectDetailsPageType.postNowBtn",
}

export const en = {
  [VideoProjectDetailsPageType.title]: "Video Catalyst Project",
  [VideoProjectDetailsPageType.cancelBtn]: "Cancel",

  [VideoProjectDetailsPageType.inputPlaceHolder]: "Add a message to request a change....",
  [VideoProjectDetailsPageType.ownerBoxTitle]: "Request Changes",
  [VideoProjectDetailsPageType.disableNote]:
    "Note: Because this project has already been posted, this request will create a new project",
  [VideoProjectDetailsPageType.projectInfoLabel]: "Project Info",
  [VideoProjectDetailsPageType.preferencesTitle]: "Style Preferences",

  [VideoProjectDetailsPageType.postNowBtn]: "Post Now",
};

export const descriptors = defineMessages({
  [VideoProjectDetailsPageType.title]: {
    id: VideoProjectDetailsPageType.title,
    defaultMessage: en[VideoProjectDetailsPageType.title],
    description: "-",
  },
  [VideoProjectDetailsPageType.cancelBtn]: {
    id: VideoProjectDetailsPageType.cancelBtn,
    defaultMessage: en[VideoProjectDetailsPageType.cancelBtn],
    description: "-",
  },
  [VideoProjectDetailsPageType.ownerBoxTitle]: {
    id: VideoProjectDetailsPageType.ownerBoxTitle,
    defaultMessage: en[VideoProjectDetailsPageType.ownerBoxTitle],
    description: "-",
  },
  [VideoProjectDetailsPageType.inputPlaceHolder]: {
    id: VideoProjectDetailsPageType.inputPlaceHolder,
    defaultMessage: en[VideoProjectDetailsPageType.inputPlaceHolder],
    description: "-",
  },
  [VideoProjectDetailsPageType.disableNote]: {
    id: VideoProjectDetailsPageType.disableNote,
    defaultMessage: en[VideoProjectDetailsPageType.disableNote],
    description: "-",
  },
  [VideoProjectDetailsPageType.projectInfoLabel]: {
    id: VideoProjectDetailsPageType.projectInfoLabel,
    defaultMessage: en[VideoProjectDetailsPageType.projectInfoLabel],
    description: "-",
  },
  [VideoProjectDetailsPageType.preferencesTitle]: {
    id: VideoProjectDetailsPageType.preferencesTitle,
    defaultMessage: en[VideoProjectDetailsPageType.preferencesTitle],
    description: "-",
  },
  [VideoProjectDetailsPageType.postNowBtn]: {
    id: VideoProjectDetailsPageType.postNowBtn,
    defaultMessage: en[VideoProjectDetailsPageType.postNowBtn],
    description: "-",
  },
});
