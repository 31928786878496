import * as React from "react";
import { CardGroup, Card, CardContent, CardHeader, CardMeta, CardDescription, Grid } from "semantic-ui-react";

import "./styles.scss";
import { ReactNode } from "react";
import classnames from "classnames";

export interface RadioCardOption {
  key: string;
  title: string;
  subTitle?: string;
  description?: string;
  disabled?: boolean;
}

interface OwnProps {
  options: RadioCardOption[];
  warningComponent?: ReactNode;
  selectedOption: string | undefined;
  onChange: (key: string) => void;
}

type Props = OwnProps;

const RadioCardGroupFC: React.FC<Props> = ({ options, selectedOption, warningComponent, onChange }) => {
  return (
    <>
      <CardGroup centered className={"radioCardGroup"}>
        {options.map(option => {
          return (
            <Card
              key={`option-modal-${option.key}`}
              onClick={() => onChange(option.key)}
              className={classnames("choiceCard", {
                selected: selectedOption === option.key,
                disabled: !!option.disabled,
              })}
            >
              <CardContent>
                <CardHeader content={option.title} />
                {option.subTitle && <CardMeta content={option.subTitle} />}
                {option.description && <CardDescription>{option.description}</CardDescription>}
              </CardContent>
            </Card>
          );
        })}
      </CardGroup>
      {warningComponent && (
        <Grid className={"radioCardGroupWarningMessage"}>
          <Grid.Row>{warningComponent}</Grid.Row>
        </Grid>
      )}
    </>
  );
};

export const RadioCardGroup = RadioCardGroupFC;
