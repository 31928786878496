import { capitalize } from "lodash";
import { VideoPreferences } from "../interfaces";

interface StylePreferencesOptions {
  key: string;
  colors?: string[];
}

const buildPreferenceOptions = (videoPreferences?: VideoPreferences): StylePreferencesOptions[] => {
  const finalOptions: StylePreferencesOptions[] = [];
  if (!videoPreferences) return [];

  if (videoPreferences.emojis) {
    finalOptions.push({ key: "Include emojis" });
  } else {
    finalOptions.push({ key: "No emojis" });
  }

  if (videoPreferences.captions) {
    finalOptions.push({ key: "Include captions" });
  } else {
    finalOptions.push({ key: "No captions" });
  }

  if (videoPreferences.broll) {
    finalOptions.push({ key: "Include video clips" });
  } else {
    finalOptions.push({ key: "No video clips" });
  }

  if (videoPreferences.music) {
    var musicStyle = capitalize(videoPreferences.musicGenre?.replace(/_/g, " ") || "Unspecified");
    finalOptions.push({
      key: `Music style: ${musicStyle}`,
    });
  } else {
    finalOptions.push({ key: "No music" });
  }

  if (videoPreferences.tone) {
    finalOptions.push({
      key: "Tone: " + capitalize(videoPreferences.tone.replace(/_/g, " ")),
    });
  }

  if (videoPreferences.captionColors) {
    finalOptions.push({ key: "Custom caption colors:", colors: videoPreferences.captionColors.split(",") });
  }

  return finalOptions;
};

export { buildPreferenceOptions, StylePreferencesOptions };
