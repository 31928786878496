import { PublicContentPageCTA } from "../../../../interfaces";
import * as React from "react";
import { Checkbox, Dropdown, Form, Input } from "semantic-ui-react";
import { useEffect, useMemo, useState } from "react";
import { uniq } from "lodash";
import { PlayersServices, ShortLinksServices } from "../../../../services";
import { ContentPageServices } from "../../../../services/ContentPageServices";

interface OwnProps {
  savedLabel?: string;
  savedLink?: string;
  playerId: number | undefined;
  onUpdate: (cta: PublicContentPageCTA | undefined) => void;
}

type Props = OwnProps;

const VideoPageCTA: React.FC<Props> = ({ playerId, savedLabel, savedLink, onUpdate }) => {
  const [hasChanges, setHasChanges] = useState(false);
  const [includeCTA, setIncludeCTA] = useState<boolean>(false);
  const [ctaLabel, setCtaLabel] = useState<string>();
  const [ctaLink, setCtaLink] = useState<string>();
  const [ctaType, setCtaType] = useState<"Phone" | "Link">("Link");

  const [playerShortLink, setPlayerShortLink] = useState<string>();
  const [playerPhone, setPlayerPhone] = useState<string>();
  const [recentCTA, setRecentCTA] = useState<PublicContentPageCTA[]>([]);

  useEffect(() => {
    if (hasChanges) {
      if (includeCTA && ctaLabel && ctaLink) {
        const prefix = ctaType === "Phone" ? "tel:" : "https://";
        const link = ctaLink && !ctaLink.startsWith(prefix) ? prefix + ctaLink : ctaLink;
        if (savedLabel !== ctaLabel || savedLink !== link) onUpdate({ label: ctaLabel, link });
      } else if (!includeCTA) {
        onUpdate(undefined);
      }
    }
  }, [includeCTA, ctaLabel, ctaLink, hasChanges]);

  useEffect(() => {
    setHasChanges(false);
    if (savedLink?.startsWith("tel:")) {
      setCtaType("Phone");
    } else if (savedLink?.startsWith("https://")) {
      setCtaType("Link");
    }

    setCtaLink(savedLink?.replace(/^(tel:|https:\/\/)/, ""));
    setCtaLabel(savedLabel);
    setIncludeCTA(!!savedLabel && !!savedLink);
  }, [savedLink, savedLabel]);

  const ctaLabelOptions = useMemo(() => {
    const recentOptions = recentCTA.map(c => c.label);
    return uniq(recentOptions);
  }, [recentCTA]);

  const phoneOptions = useMemo(() => {
    let options = recentCTA.map(c => c.link).filter(l => l.startsWith("tel:"));
    if (playerPhone) options.push(playerPhone);
    options = options.map(o => o.replace(/\D/g, ""));
    return uniq(options);
  }, [recentCTA, playerPhone]);

  const linkOptions = useMemo(() => {
    let options = recentCTA.map(c => c.link).filter(l => l.startsWith("https://"));
    if (playerShortLink) options.push(playerShortLink);
    options = options.map(o => o.replace(/^(tel:|https:\/\/)/, ""));
    return uniq(options);
  }, [recentCTA, playerShortLink]);

  const ctaLinkOptions = useMemo(() => {
    return ctaType === "Phone" ? phoneOptions : linkOptions;
  }, [ctaType, linkOptions, phoneOptions]);

  const loadPlayerLink = async () => {
    if (playerId) {
      const response = await ShortLinksServices.getByPlayerId(playerId);
      setPlayerShortLink(response.shortLink);
    }
  };

  const loadPlayerPhone = async () => {
    if (playerId) {
      const response = await PlayersServices.get(playerId);
      setPlayerPhone(response.profile?.phone);
    }
  };

  const loadPlayerCTAS = async () => {
    if (playerId) {
      const res = await ContentPageServices.getPlayerCTAS(playerId);
      setRecentCTA(res);
    }
  };

  useEffect(() => {
    loadPlayerCTAS().catch(console.error);
    loadPlayerPhone().catch(console.error);
    loadPlayerLink().catch(console.error);
  }, [playerId]);

  return (
    <>
      <Form.Field>
        <label>Call to Action</label>
        <Checkbox
          id={"includeCTA"}
          label={`Include a "Call to Action" button on your page?`}
          checked={includeCTA}
          onChange={(_, d) => {
            if (d.checked) {
              if (!ctaLink && ctaLinkOptions.length > 0) setCtaLink(ctaLinkOptions[0]);
              if (!ctaLabel) setCtaLabel(ctaLabelOptions[0] || "Click here to learn more");
              setIncludeCTA(true);
            } else {
              setIncludeCTA(false);
            }
            setHasChanges(true);
          }}
        />
      </Form.Field>
      {includeCTA && (
        <div className={"subFields"}>
          <Form.Field>
            <label className={"subFieldLabel"}>Button Label</label>
            <Input
              size="small"
              list={"ctaLabels"}
              value={ctaLabel}
              maxLength={50}
              fluid
              onChange={(_, d) => {
                setCtaLabel(d.value);
                setHasChanges(true);
              }}
            />
          </Form.Field>
          <Form.Field>
            <Dropdown
              text={ctaType}
              options={[
                { text: "Link", value: "Link" },
                { text: "Phone", value: "Phone" },
              ]}
              value={ctaType}
              className={"subFieldLabel"}
              onChange={(_, d) => {
                if (d.value !== ctaType) {
                  if (d.value == "Phone") {
                    setCtaLink(phoneOptions[0]);
                    setCtaType("Phone");
                  } else if (d.value == "Link") {
                    setCtaLink(linkOptions[0]);
                    setCtaType("Link");
                  }
                }
                setHasChanges(true);
              }}
            />
            <Input
              size="small"
              list={ctaType === "Phone" ? "ctaLinksPhone" : "ctaLinksWeb"}
              label={ctaType === "Phone" ? "tel:" : "https://"}
              value={ctaLink}
              maxLength={255}
              onChange={(_, d) => {
                setCtaLink(d.value);
                setHasChanges(true);
              }}
            />

            <datalist id="ctaLabels">
              {ctaLabelOptions.map(o => (
                <option key={o} value={o} />
              ))}
            </datalist>

            <datalist id="ctaLinksWeb">
              {linkOptions.map(o => (
                <option key={o} value={o} />
              ))}
            </datalist>

            <datalist id="ctaLinksPhone">
              {phoneOptions.map(o => (
                <option key={o} value={o} />
              ))}
            </datalist>
          </Form.Field>
        </div>
      )}
    </>
  );
};

export default VideoPageCTA;
