import * as React from "react";
import { Field, FieldProps } from "formik";
import MaskedInput from "react-text-mask";
import { useIntl, FormattedMessage } from "react-intl";
import { RouteComponentProps, withRouter } from "react-router";
import { Dropdown, DropdownItemProps, Form } from "semantic-ui-react";

import { FirstLoginType, descriptors } from "./descriptors";
import { fields } from "./formFields";
import { phoneNumberMask } from "../../../../utils";
import { FloatedCard } from "../../../../containers";
import { BasicFormProps } from "../../../../interfaces/customTypes";
import { Chat, CoachBasic } from "../../../../interfaces";
import { BubbleMobileDetails } from "../BubbleMobileDetails";
import "./styles.scss";

interface OwnProps extends BasicFormProps {
  handleSetFieldValue?: (field: string, value: any) => void;

  chatMessages: Chat[];
  coachInfo?: CoachBasic;
  availableIndustryRoles?: string[];
}

type Props = OwnProps & RouteComponentProps;

/**
 * Represents a Player First login screen - Step 1.
 */
const FirstLoginPersonalInfoClass: React.FC<Props> = ({
  messages,
  handleChange,
  handleSubmit,
  isSubmitting,
  values,
  chatMessages,
  coachInfo,
  availableIndustryRoles,
}) => {
  const { formatMessage } = useIntl();
  const [industryRoleOptions, setIndustryRoleOptions] = React.useState<DropdownItemProps[]>([]);

  React.useEffect(() => {
    if (!availableIndustryRoles) return;

    setIndustryRoleOptions(
      availableIndustryRoles.map(el => ({
        key: el,
        text: el,
        value: el,
      }))
    );
  }, [availableIndustryRoles]);

  const content = (
    <div className="firstLoginPersonalInfo">
      {coachInfo && <BubbleMobileDetails messages={chatMessages} coachInfo={coachInfo} />}
      {fields.map((field, index) => {
        return (
          <Form.Group widths="equal" key={"personal_info_group_" + field[0].id + index}>
            <Form.Input
              key={field[0].id + index + "_l"}
              data-elm-id={"firstLoginInput" + field[0].id}
              fluid
              id={field[0].id}
              type={field[0].type}
              name={field[0].name}
              label={field[0].label}
              placeholder={formatMessage({ ...descriptors[field[0].placeholder] })}
              onChange={handleChange}
              value={values[field[0].id]}
              disabled={isSubmitting}
            />
            {field.length > 1 && (
              <Form.Input
                key={field[1].id + index + "_r"}
                data-elm-id={"firstLoginInput" + field[1].id}
                fluid
                id={field[1].id}
                type={field[1].type}
                name={field[1].name}
                placeholder={formatMessage({ ...descriptors[field[1].placeholder] })}
                value={values[field[1].id]}
                onChange={handleChange}
                disabled={isSubmitting}
              />
            )}
          </Form.Group>
        );
      })}

      <Form.Group widths="equal" key={"personal_info_group_phone_number"}>
        <div className="field">
          <label htmlFor="phoneNumber">Phone Number</label>
          <Field
            name="phoneNumber"
            data-elm-id={"firstLoginInputPhoneNumber"}
            render={({ phoneField }: { phoneField: any }) => (
              <MaskedInput
                {...phoneField}
                value={values.phoneNumber}
                mask={phoneNumberMask}
                id="phoneNumber"
                placeholder={formatMessage({ ...descriptors[FirstLoginType.phoneNumber] })}
                type="text"
                onChange={handleChange}
                disabled={isSubmitting}
              />
            )}
          />
        </div>
      </Form.Group>
      <Form.Group widths="equal">
        <div className="field">
          <label htmlFor="industryRole">Industry Role</label>
          <Field name="industryRole">
            {({ field }: FieldProps<any>) => (
              <Dropdown
                options={industryRoleOptions}
                {...field}
                onChange={(_e, data) => field.onChange({ target: { id: "industryRole", value: data.value } })}
                clearable
                icon={values.industryRole ? undefined : ""}
                placeholder={formatMessage({ ...descriptors[FirstLoginType.industryRole] })}
                fluid
                selection
                disabled={isSubmitting || availableIndustryRoles === undefined}
              />
            )}
          </Field>
        </div>
      </Form.Group>
      <Form.Group widths="equal" key={"personal_info_group_buttom"} className={"buttonContainer"}>
        <Form.Button size="large" type="submit" disabled={isSubmitting} onClick={handleSubmit} className={"signUp"}>
          <FormattedMessage {...descriptors[FirstLoginType.submit]} />
        </Form.Button>
      </Form.Group>
    </div>
  );

  return <FloatedCard content={content} messages={messages} isSubmitting={isSubmitting} size={"bigCentered"} />;
};

export const FirstLoginPersonalInfo = withRouter(FirstLoginPersonalInfoClass);
