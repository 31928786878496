import * as React from "react";
import copy from "copy-to-clipboard";
import { Modal, Button, Dropdown } from "semantic-ui-react";
import { Media } from "../../../../../../../interfaces";
import "./styles.scss";

interface Props {
  open: boolean;
  videos: Media[];
  onClose?: () => void;
}

const VideoTranscriptsModalComponent: React.FC<Props> = ({ open, videos, onClose }) => {
  const [copiedTranscript, setCopiedTranscript] = React.useState<boolean>(false);
  const [selectedMediaIndex, setSelectedMediaIndex] = React.useState<number>(0);

  const selectedTranscript = () => {
    if (videos[selectedMediaIndex]?.transcripts?.length) {
      return videos[selectedMediaIndex].transcripts[0].content;
    } else {
      return null;
    }
  };

  const copyTranscript = () => {
    const transcript = selectedTranscript();
    if (!transcript) return;

    copy(transcript);
    if (copiedTranscript) return;

    setCopiedTranscript(true);
    setTimeout(() => {
      setCopiedTranscript(false);
    }, 2000);
  };

  const selectedMediaContent = () => {
    const transcript = selectedTranscript();
    if (transcript) {
      return transcript.split("\n").map((line, index) => (
        <React.Fragment key={index}>
          {line}
          <br />
        </React.Fragment>
      ));
    } else if (selectedMediaIndex !== null) {
      return <div className="noTranscriptLabel">No transcript available for this media.</div>;
    } else {
      return null;
    }
  };

  const mediaDropdown = () => {
    if (videos.length > 1) {
      const mediaOptions = videos.map((media, index) => {
        const text = `File ${index + 1}`;
        return {
          key: index,
          text: text,
          value: index,
          icon: media.category == "VIDEO" ? "video" : "image",
          className: "mediaDropDownItem",
        };
      });

      return (
        <div>
          <span style={{ fontWeight: 500 }}>Transcript for </span>
          <Dropdown
            value={selectedMediaIndex}
            onChange={(_event, data) => setSelectedMediaIndex(data.value as number)}
            inline
            options={mediaOptions}
          />
        </div>
      );
    } else {
      return "Transcript";
    }
  };

  const copyButton = () => {
    const transcript = selectedTranscript();
    if (!transcript) return null;

    return (
      <Button
        data-elm-id={"videoTranscriptsModalCopyBtn"}
        className={"secondary rounded padded"}
        content={copiedTranscript ? "Copied!" : "Copy"}
        icon={copiedTranscript ? "checkmark" : "copy outline"}
        onClick={() => copyTranscript()}
      />
    );
  };

  const closeButton = () => {
    return (
      <Button
        data-elm-id={"videoTranscriptsModalCloseBtn"}
        className={"primary rounded"}
        content="Close"
        onClick={onClose}
      />
    );
  };

  return (
    <Modal open={open} onClose={onClose} size={"tiny"} className={"videoTranscriptsModal"}>
      <Modal.Header>{mediaDropdown()}</Modal.Header>

      <Modal.Description>
        {/* {mediasContent()} */}
        {selectedMediaContent()}
      </Modal.Description>

      <Modal.Actions>
        {copyButton()}
        {closeButton()}
      </Modal.Actions>
    </Modal>
  );
};

export const VideoTranscriptsModal = VideoTranscriptsModalComponent;
