import { defineMessages } from "react-intl";

export enum SignUpType {
  title = "SignUpType.title",
  subtitle = "SignUpType.subtitle",

  email = "SignUpType.email",
  password = "SignUpType.password",
  confirmPassword = "SignUpType.confirmpassword",
  firstName = "SignUpType.firstName",
  lastName = "SignUpType.lastName",
  phoneNumber = "SignUpType.phoneNumber",

  submit = "SignUpType.submit",
  signIn = "SignUpType.signIn",

  warningTitle = "SignUpType.warningTitle",
  errorTitle = "SignUpType.errorTitle",
  infoTitle = "SignUpType.infoTitle",

  passwordMin = "SignUpType.passwordMin",
  passwordWithNumber = "SignUpType.passwordNumber",
  passwordWithSpecialCharacter = "SignUpType.passwordSpecialCharacter",
  passwordWithCapital = "SignUpType.passwordCapital",
}

export const en = {
  [SignUpType.title]: "Let's get started",
  [SignUpType.subtitle]:
    "The fastest, easiest way to grow, manage and multiply your social coaching business all from one streamlined platform.\n",
  [SignUpType.email]: "Email Address",
  [SignUpType.password]: "Password",
  [SignUpType.confirmPassword]: "Re-enter Password",
  [SignUpType.firstName]: "First Name",
  [SignUpType.lastName]: "Last Name",
  [SignUpType.phoneNumber]: "Phone Number",

  [SignUpType.submit]: "Next",
  [SignUpType.signIn]: "Sign In",

  [SignUpType.warningTitle]: "Warning",
  [SignUpType.errorTitle]: "Sign Up error:",
  [SignUpType.infoTitle]: "Hey",

  [SignUpType.passwordMin]: "Password must be at least 8 characters.",
  [SignUpType.passwordWithCapital]: "Password must be at least 1 capital letter.",
  [SignUpType.passwordWithNumber]: "Password must be at least 1 numeric value.",
  [SignUpType.passwordWithSpecialCharacter]: "Password must be at least 1 special character.",
};

export const descriptors = defineMessages({
  [SignUpType.title]: {
    id: SignUpType.title,
    defaultMessage: en[SignUpType.title],
    description: "Log In title",
  },
  [SignUpType.subtitle]: {
    id: SignUpType.subtitle,
    defaultMessage: en[SignUpType.subtitle],
    description: "Log In subtitle",
  },

  [SignUpType.email]: {
    id: SignUpType.email,
    defaultMessage: en[SignUpType.email],
    description: "Email address",
  },
  [SignUpType.password]: {
    id: SignUpType.password,
    defaultMessage: en[SignUpType.password],
    description: "Password text",
  },
  [SignUpType.confirmPassword]: {
    id: SignUpType.confirmPassword,
    defaultMessage: en[SignUpType.confirmPassword],
    description: "Confirm Password text",
  },
  [SignUpType.firstName]: {
    id: SignUpType.firstName,
    defaultMessage: en[SignUpType.firstName],
    description: "firstName text",
  },
  [SignUpType.lastName]: {
    id: SignUpType.lastName,
    defaultMessage: en[SignUpType.lastName],
    description: "lastName text",
  },
  [SignUpType.phoneNumber]: {
    id: SignUpType.phoneNumber,
    defaultMessage: en[SignUpType.phoneNumber],
    description: "phoneNumber text",
  },
  [SignUpType.submit]: {
    id: SignUpType.submit,
    defaultMessage: en[SignUpType.submit],
    description: "Sign In text",
  },
  [SignUpType.signIn]: {
    id: SignUpType.signIn,
    defaultMessage: en[SignUpType.signIn],
    description: "Link to sign in page",
  },
  [SignUpType.warningTitle]: {
    id: SignUpType.warningTitle,
    defaultMessage: en[SignUpType.warningTitle],
    description: "Warning messages title",
  },
  [SignUpType.errorTitle]: {
    id: SignUpType.errorTitle,
    defaultMessage: en[SignUpType.errorTitle],
    description: "Error Messages title",
  },
  [SignUpType.infoTitle]: {
    id: SignUpType.infoTitle,
    defaultMessage: en[SignUpType.infoTitle],
    description: "Info messages title",
  },
  [SignUpType.passwordMin]: {
    id: SignUpType.passwordMin,
    defaultMessage: en[SignUpType.passwordMin],
    description: "passwordMin",
  },
  [SignUpType.passwordWithCapital]: {
    id: SignUpType.passwordWithCapital,
    defaultMessage: en[SignUpType.passwordWithCapital],
    description: "passwordWithCapital text",
  },
  [SignUpType.passwordWithNumber]: {
    id: SignUpType.passwordWithNumber,
    defaultMessage: en[SignUpType.passwordWithNumber],
    description: "passwordWithNumber",
  },
  [SignUpType.passwordWithSpecialCharacter]: {
    id: SignUpType.passwordWithSpecialCharacter,
    defaultMessage: en[SignUpType.passwordWithSpecialCharacter],
    description: "passwordWithSpecialCharacter",
  },
});
