import { useContext, useMemo } from "react";
import { AppContext } from "../providers";

const useSocialCoachSession = () => {
  const { userContext } = useContext(AppContext);

  const playerId = useMemo(() => {
    return userContext?.player?.id;
  }, [userContext?.player?.id]);

  const coachId = useMemo(() => {
    if (userContext) {
      let cid: string | undefined = undefined;
      if (userContext.coach) cid = userContext.coach.id;
      else if (userContext.subscriptions) cid = userContext.subscriptions[0]?.pricePlan?.coachId;

      if (cid) return Number(cid);
    }

    return undefined;
  }, [userContext?.subscriptions]);

  return { playerId, coachId };
};

export default useSocialCoachSession;
