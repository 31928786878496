import { Button, Dimmer, Grid, Image, Label, Loader, Modal } from "semantic-ui-react";
import * as React from "react";
import copy from "copy-to-clipboard";
import { ContentPageInfo, ContentPageRequest, Media } from "../../../interfaces";
import { useEffect, useMemo, useRef, useState } from "react";
import { ContentPageServices } from "../../../services/ContentPageServices";
import { useInterval } from "../RecordVideo/hooks";
import useSocialCoachSession from "../../../utils/useSocialCoachSession";
import "./styles.scss";
import { VideoPageStatsPopup } from "../VideoPageStatsPopup";
import EditVideoPageCard from "./EditVideoPageCard";

interface OwnProps {
  opened: boolean;
  media?: Media;
  page?: ContentPageInfo;
  showStats?: boolean;
  title?: string;

  closeHandler: () => void;
  pageSaveHandler?: (page: ContentPageInfo) => void;
  deleteHandler?: (page: ContentPageInfo) => void;
}

type Props = OwnProps;

const VideoPageModalFC: React.FC<Props> = ({
  opened,
  media,
  page,
  showStats,
  closeHandler,
  pageSaveHandler,
  deleteHandler,
}) => {
  const { playerId, coachId } = useSocialCoachSession();
  const [pageState, setPageState] = useState<"init" | "creating" | "processing" | "readyToShare">("init");
  const [pageInfo, setPageInfo] = useState<ContentPageInfo | undefined>();
  const [copiedLink, setCopiedLink] = useState<boolean>(false);
  const [copiedForEmail, setCopiedForEmail] = useState<boolean>(false);

  const thumbnailRef = useRef<HTMLImageElement>();
  const [thumbnailBase64, setThumbnailBase64] = useState<string | ArrayBuffer | null>(null);

  const effectivePlayerId = useMemo(() => {
    return pageInfo?.playerId || playerId;
  }, [playerId, pageInfo?.playerId]);

  const effectiveCoachId = useMemo(() => {
    return pageInfo?.coachId || coachId;
  }, [coachId, pageInfo?.coachId]);

  const onClose = () => {
    setPageInfo(undefined);
    setPageState("init");
    closeHandler();
  };

  const thumbnailUri = useMemo(() => {
    if (pageInfo?.previewMedia && pageInfo?.previewMedia[0]) {
      return pageInfo.previewMedia[0].uri;
    }
    return undefined;
  }, [pageInfo?.previewMedia]);

  const generateThumbnailBase64 = async () => {
    if (thumbnailUri) {
      const data = await fetch(thumbnailUri);
      const blob = await data.blob();
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onloadend = () => {
        setThumbnailBase64(reader.result);
      };
      reader.onerror = e => {
        console.error("Error loading page thumbnail", e);
      };
    }
  };

  useEffect(() => {
    generateThumbnailBase64().catch(console.error);
  }, [thumbnailUri]);

  const createPage = async () => {
    if (media && opened) {
      const pageRequest: ContentPageRequest = {
        title: "",
        caption: "",
        playerId: effectivePlayerId,
        coachId: effectiveCoachId,
        media: [
          {
            uri: media.uri,
            category: media.category,
            sortOrder: 0,
            type: media.type,
            extension: media.extension,
          },
        ],
      };
      setPageState("creating");
      const newPage = await ContentPageServices.save(pageRequest);
      setPageInfo(newPage);
    }
  };

  const refreshPage = async () => {
    if (pageInfo) {
      const newPageInfo = await ContentPageServices.getPage(pageInfo.contentPageId);
      setPageInfo(newPageInfo);
    }
  };

  useEffect(() => {
    if (opened && media && pageState === "init") createPage().catch(console.error);
    else if (opened && page && pageState === "init") setPageState("readyToShare");
  }, [opened, media, pageState]);

  useEffect(() => {
    if (page) {
      setPageInfo(page);
    }
  }, [page]);

  useInterval(
    () => {
      refreshPage().catch(console.error);
    },
    pageState == "processing" ? 1000 : undefined
  );

  useEffect(() => {
    switch (pageState) {
      case "creating":
        if (pageInfo) setPageState("processing");
        break;
      case "processing":
        const ready = pageInfo?.processingSteps?.filter(s => s.type == "THUMBNAIL")?.every(s => s.status == "COMPLETE");
        if (ready) setPageState("readyToShare");
        break;
    }
  }, [pageInfo, pageState]);

  const markAsShared = async () => {
    try {
      await ContentPageServices.markAsShared(pageInfo?.contentPageId!);
    } catch (e) {
      console.error("Error marking as shared", e);
    }
  };

  const copyForEmail = () => {
    markAsShared().catch(console.error);
    copy(
      `
              <br>
              <div>
              <a href="${pageInfo?.url}" target="_blank">
                <img src="${thumbnailBase64}" width="300px" alt="${pageInfo?.title || "SocialCoach Video"}" />
              </a>
              <br>
              <a href="${pageInfo?.url}" target="_blank">Click here to watch my video</a>
              <br>
            `,
      { format: "text/html" }
    );
    setCopiedForEmail(true);
    setTimeout(() => {
      setCopiedForEmail(false);
    }, 2000);
  };

  const copyPageLink = () => {
    markAsShared().catch(console.error);
    copy(pageInfo!.url);
    setCopiedLink(true);
    setTimeout(() => {
      setCopiedLink(false);
    }, 2000);
  };

  return (
    <Modal className={"VideoPageModal"} closeIcon size={"small"} open={opened} onClose={onClose}>
      <Modal.Content className={"modalContent"}>
        {pageState == "creating" && (
          <Dimmer active inverted>
            <Loader inverted size="large" content={"Creating your page..."} />
          </Dimmer>
        )}
        {pageState == "processing" && (
          <Dimmer active inverted>
            <Loader inverted size="large" content={"Finishing things up..."} />
          </Dimmer>
        )}
        {pageState == "readyToShare" && (
          <Grid>
            <Grid.Column width={5}>
              <Image
                ref={thumbnailRef}
                src={thumbnailUri}
                className={"pagePreview"}
                onClick={() => {
                  markAsShared().catch(console.error);
                  window.open(pageInfo?.url, "_blank");
                }}
              />
              {showStats && (
                <>
                  <VideoPageStatsPopup
                    page={pageInfo}
                    trigger={
                      <Label
                        icon={"eye"}
                        content={`${pageInfo?.contentPageViewSummary?.viewCount || 0}`}
                        className={"viewStat"}
                      />
                    }
                  />

                  {!!pageInfo?.cta && (
                    <Label
                      icon={"mouse pointer"}
                      content={`${
                        pageInfo?.contentPageViewSummary?.clicks.find(s => s.label == "Clicked")?.value || 0
                      }`}
                      className={"viewStat"}
                    />
                  )}
                </>
              )}
            </Grid.Column>
            <Grid.Column width={11}>
              {pageInfo && (
                <EditVideoPageCard
                  pageInfo={pageInfo}
                  onDeleted={deletedPage => {
                    if (deleteHandler) deleteHandler(deletedPage);
                    onClose();
                  }}
                  onUpdate={newPage => {
                    setPageInfo(newPage);
                    if (pageSaveHandler) pageSaveHandler(newPage);
                  }}
                />
              )}
            </Grid.Column>
          </Grid>
        )}
      </Modal.Content>
      <Modal.Actions>
        <Button className={"rounded"} primary onClick={copyForEmail}>
          {copiedForEmail ? "Copied!" : "Copy for email"}
        </Button>
        <Button className={"rounded"} primary onClick={copyPageLink}>
          {copiedLink ? "Copied!" : "Copy page link"}
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

export const VideoPageModal = VideoPageModalFC;
