import * as React from "react";
import { Image } from "semantic-ui-react";

import { Svg } from "../../../assets";
import * as DTO from "../../../interfaces";
import { URLS } from "../../../utils";

import "./styles.scss";
import { VideoProjectEditorCardContentStyle, VideoProjectEditorCardStyle, VideoProjectHeader } from "./styled";
import moment from "moment";

export interface VideoProjectEditorCardRecord extends DTO.PlayerVideoProjectResponse {
  assigneeName: string | null;
}

interface Props {
  videoProject: VideoProjectEditorCardRecord;
  disabled: Boolean;
}

const VideoProjectEditorCardFC: React.FC<Props> = ({
  videoProject: { videoProjectId, statusLabel, thumbnail, companyName, playerName, statusDate, needsAck, assigneeName },
  disabled,
}: Props) => {
  return (
    <VideoProjectEditorCardStyle
      className={needsAck ? "videoProjectEditorCard editorNeedsAck" : "videoProjectEditorCard"}
      href={URLS.editor.editVideo.replace(":id", `${videoProjectId}`)}
      disabled={disabled}
    >
      <Image className={"reelsIcon"} size="small" src={Svg.Reels} />
      <Image
        src={thumbnail?.thumbnailUri || Svg.PromptPlaceholder}
        className={`videoProjectEditorCardImage ${thumbnail?.thumbnailUri ? "" : "placeholderImage"}`}
      />
      <VideoProjectEditorCardContentStyle>
        <VideoProjectHeader>
          {videoProjectId} - {playerName}
        </VideoProjectHeader>
        <VideoProjectHeader>{companyName || "-"}</VideoProjectHeader>
        <VideoProjectEditorCardStyle.Description>{statusLabel}</VideoProjectEditorCardStyle.Description>
        <VideoProjectEditorCardStyle.Description>
          {moment(statusDate!!).format("MMM DD, YY [at] hh:mm a")}
        </VideoProjectEditorCardStyle.Description>
        <VideoProjectEditorCardStyle.Description className="ellipsis">
          {assigneeName ? `Assignee: ${assigneeName}` : "Unassigned"}
        </VideoProjectEditorCardStyle.Description>
      </VideoProjectEditorCardContentStyle>
    </VideoProjectEditorCardStyle>
  );
};

export const VideoProjectEditorCard = VideoProjectEditorCardFC;
