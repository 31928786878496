import { AddPlayerType } from "./descriptors";
import * as MODEL from "../../../interfaces/models";

export const fields: MODEL.FormField[] = [
  {
    id: "firstName",
    name: "firstName",
    type: "text",
    label: "firstName",
    placeholder: AddPlayerType.firstName,
    disabled: false,
  },
  {
    id: "lastName",
    name: "lastName",
    type: "text",
    label: "lastName",
    placeholder: AddPlayerType.lastName,
    disabled: false,
  },
  {
    id: "email",
    name: "email",
    type: "email",
    label: "Email",
    placeholder: AddPlayerType.email,
    disabled: false,
  },
  {
    id: "complianceDisclaimer",
    name: "complianceDisclaimer",
    type: "text",
    label: "complianceDisclaimer",
    placeholder: AddPlayerType.complianceDisclaimer,
    disabled: false,
  },
];

export const segmentationFields: MODEL.FormField[] = [
  {
    id: "branch",
    name: "branch",
    type: "text",
    label: "Branch",
    placeholder: AddPlayerType.branch,
    disabled: false,
  },
  {
    id: "state",
    name: "state",
    type: "text",
    label: "State",
    placeholder: AddPlayerType.state,
    disabled: false,
  },
  {
    id: "region",
    name: "region",
    type: "text",
    label: "Region",
    placeholder: AddPlayerType.region,
    disabled: false,
  },
  {
    id: "costCenter",
    name: "costCenter",
    type: "text",
    label: "Cost Center",
    placeholder: AddPlayerType.costCenter,
    disabled: false,
  },
  {
    id: "industryRole",
    name: "industryRole",
    type: "text",
    label: "Industry Role",
    placeholder: AddPlayerType.industryRole,
    disabled: false,
  },
];
