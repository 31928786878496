import { client as axios } from "./axiosClient";
import { Constants } from "@socialcoach/services";
import { catchServiceException } from "@socialcoach/services";
import * as DTO from "../interfaces";

const COACH_SERVICE = `${Constants.API_URL}/coaches`;
const ACCOUNT_SERVICE = `${Constants.API_URL}/accounts`;

export class CoachService {
  @catchServiceException()
  static async updateCoachStatus(coachId: string, status: DTO.CoachStatuses): Promise<void> {
    const { data } = await axios.put<void>(
      `${ACCOUNT_SERVICE}/coaches/${coachId}/status/${status}`,
      {},
      {
        withCredentials: true,
      }
    );
    return data;
  }

  @catchServiceException()
  static async updateQuestionSetBySetId(setId: number, questionSet: DTO.QuestionSet): Promise<DTO.QuestionSet> {
    const { data } = await axios.put<DTO.QuestionSet>(
      `${COACH_SERVICE}/question-sets/${setId}`,
      {
        ...questionSet,
      },
      {
        withCredentials: true,
      }
    );
    return data;
  }

  @catchServiceException()
  static async saveQuestionSetByCoachId(coachId: string, questionSet: DTO.QuestionSet): Promise<DTO.QuestionSet> {
    const { data } = await axios.post<DTO.QuestionSet>(
      `${COACH_SERVICE}/question-sets`,
      {
        ...questionSet,
        coachId,
      },
      {
        withCredentials: true,
      }
    );
    return data;
  }

  @catchServiceException()
  static async getQuestionSetByCoachId(
    coachId: string,
    questionSetType: DTO.QuestionSetType
  ): Promise<DTO.QuestionSet> {
    const { data } = await axios.get<DTO.QuestionSet>(
      `${COACH_SERVICE}/question-sets/coaches/${coachId}/${questionSetType}`,
      {
        withCredentials: true,
      }
    );
    return data;
  }

  @catchServiceException()
  static async createPlan(plan: DTO.Plan, coachId: string): Promise<DTO.Plan[]> {
    const { data } = await axios.post<DTO.Plan[]>(
      `${COACH_SERVICE}/${coachId}/price-plans`,
      {
        planName: plan.planName,
        planStatements: plan.planStatements,
        price: plan.price,
        hasFreeTrial: plan.hasFreeTrial,
      },
      {
        withCredentials: true,
      }
    );
    return data;
  }

  @catchServiceException()
  static async getPlans(coachId: string): Promise<DTO.Plan[]> {
    const { data } = await axios.get<DTO.Plan[]>(`${COACH_SERVICE}/${coachId}/price-plans`, {
      withCredentials: true,
    });
    return data;
  }

  static async updatePlan(plan: DTO.Plan, coachId: string): Promise<DTO.Plan[]> {
    const { data } = await axios.put<DTO.Plan[]>(
      `${COACH_SERVICE}/${coachId}/price-plans/${plan.id}`,
      {
        planName: plan.planName,
        planStatements: plan.planStatements,
        price: plan.price,
        hasFreeTrial: plan.hasFreeTrial,
        baseActive: plan.baseActive,
        intervalPrices: plan.intervalPrices,
      },
      {
        withCredentials: true,
      }
    );
    return data;
  }

  @catchServiceException()
  static async getBaseFee(): Promise<DTO.Fee[]> {
    const { data } = await axios.get<DTO.Fee[]>(`${COACH_SERVICE}/fees`, {
      withCredentials: true,
    });
    return data;
  }

  @catchServiceException()
  static async deleteBankInfo(coachId: string, bankId: number): Promise<any> {
    const { data } = await axios.delete<DTO.LandingResponse>(
      `${ACCOUNT_SERVICE}/coaches/${coachId}/bank-accounts/${bankId}`,
      {
        withCredentials: true,
      }
    );
    return data;
  }

  @catchServiceException()
  static async statistics(coachId: string): Promise<DTO.StatisticResponse> {
    const { data } = await axios.get<DTO.StatisticResponse>(`${COACH_SERVICE}/statistics/search/${coachId}`, {
      withCredentials: true,
    });
    return data;
  }

  static async createPaymentIntent(playerId: number): Promise<string> {
    const response = await axios.post<{ clientSecret: string }>(`${COACH_SERVICE}/payments`, {
      playerId,
    });
    return response.data.clientSecret;
  }

  static async createSubscription(request: DTO.CreateSubscriptionRequest): Promise<void> {
    await axios.post(`${COACH_SERVICE}/payments/subscriptions`, request);
  }

  @catchServiceException()
  static async updatePaymentMethod(request: DTO.UpdatePaymentMethodRequest): Promise<void> {
    await axios.put(`${COACH_SERVICE}/payments/customers`, request);
  }

  @catchServiceException()
  static async branches(coachId: string): Promise<DTO.Branch[]> {
    const { data } = await axios.get<DTO.Branch[]>(`${COACH_SERVICE}/coaches/${coachId}/branches`, {
      withCredentials: true,
    });
    return data;
  }

  @catchServiceException()
  static async states(coachId: string): Promise<DTO.State[]> {
    const { data } = await axios.get<DTO.State[]>(`${COACH_SERVICE}/coaches/${coachId}/states`, {
      withCredentials: true,
    });
    return data;
  }

  @catchServiceException()
  static async costCenters(coachId: string): Promise<DTO.CostCenter[]> {
    const { data } = await axios.get<DTO.CostCenter[]>(`${COACH_SERVICE}/coaches/${coachId}/costCenters`, {
      withCredentials: true,
    });
    return data;
  }

  @catchServiceException()
  static async industryRoles(coachId: string): Promise<DTO.IndustryRole[]> {
    const { data } = await axios.get<DTO.IndustryRole[]>(`${COACH_SERVICE}/coaches/${coachId}/industryRoles`, {
      withCredentials: true,
    });
    return data;
  }

  @catchServiceException()
  static async playerIndustryRoles(coachId: number, playerId: number): Promise<DTO.PlayerIndustryRoles> {
    const { data } = await axios.get<DTO.PlayerIndustryRoles>(
      `${COACH_SERVICE}/coaches/${coachId}/players/${playerId}/industryRoles`,
      {
        withCredentials: true,
      }
    );
    return data;
  }

  @catchServiceException()
  static async updatePlayerIndustryRole(
    coachId: number,
    playerId: number,
    industryRole: string | undefined
  ): Promise<void> {
    const industryRoles = industryRole ? [industryRole] : [];
    await axios.put<void>(
      `${COACH_SERVICE}/coaches/${coachId}/players/${playerId}/industryRoles`,
      {
        industryRoles,
      },
      {
        withCredentials: true,
      }
    );
  }

  @catchServiceException()
  static async regions(coachId: string): Promise<DTO.Region[]> {
    const { data } = await axios.get<DTO.Region[]>(`${COACH_SERVICE}/coaches/${coachId}/regions`, {
      withCredentials: true,
    });
    return data;
  }

  @catchServiceException()
  static async getById(coachId: string): Promise<DTO.CompleteAccount> {
    const { data } = await axios.get<DTO.CompleteAccount>(`${ACCOUNT_SERVICE}/coaches/${coachId}`, {
      withCredentials: true,
    });
    return data;
  }

  @catchServiceException()
  static async delete(coachId: string): Promise<any> {
    const response = await axios.delete<any>(`${COACH_SERVICE}/${coachId}`, {
      withCredentials: true,
    });

    return response;
  }
}
